<template>
    <div class="general-balance">
        <v-skeleton-loader
            v-if="waiting"
            style="position: relative; top: -82px"
            :height="8"
            type="text"
            class="progress"
        />
        <div v-else class="progress-bar-border gray" style="position: relative; top: -82px">
            <div
                class="progress-bar green"
                :style="{ width: percentage(summaryData[1].value, summaryData[0].value) + '%' }"
            ></div>
        </div>

        <v-skeleton-loader
            v-if="waiting"
            style="position: relative; top: -26px"
            :height="8"
            type="text"
            class="progress"
        />
        <div v-else class="progress-bar-border gray" style="position: relative; top: -15px">
            <div
                class="progress-bar"
                :style="{ width: percentage(summaryData[3].value, summaryData[2].value) + '%' }"
            ></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import util from '../../common/util'

export default {
    name: 'GeneralBalance',

    props: {
        summaryData: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapState(mainStore, ['waiting']),
    },

    methods: {
        percentage(n, d) {
            return util.percentage(n, d)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/summary-card.scss';

.general-balance {
    .progress-bar-border {
        background-color: $revenue-green !important;

        &.gray {
            background: #e6e6e6 !important;
        }
    }

    .progress-bar {
        background-color: $expense-red !important;

        &.green {
            background: $revenue-green !important;
        }
    }
}
</style>
