<template>
    <div class="preferences-config">
        <div class="flex">
            <div class="flex preferences-config-box column">
                <!-- <div>
                            <h1>Notificações</h1>

                            <div class="flex center toggle-switch-container">
                                    <p class="toggle-label">Receber notificações por e-mail</p>

                                    <toggle-switch
                                            @toggleEvent="toggleNotifications"
                                    />
                            </div>

                            <div class="form-width">
                                    <stacked-input
                                            label="Hora para notificações"
                                            :value="preferences.notificationTime"
                                            @input="updateNotificationTime"
                                    />
                            </div>
                    </div> -->

                <div>
                    <h1>Sua Conta</h1>

                    <div class="flex center toggle-switch-container">
                        <p class="toggle-label">Pular tutorial inicial</p>

                        <toggle-switch :toggle="user.skipTutorial || false" @toggleEvent="toggleTutorial" />
                    </div>

                    <!-- <div class="flex center toggle-switch-container">
                            <p class="toggle-label">Somar saldo de meses anteriores</p>

                            <toggle-switch
                                @toggleEvent="toggleSumBalance"
                            />
                        </div> -->

                    <div class="form-width flex">
                        <stacked-select
                            class="flex-basis"
                            label="Moeda"
                            :model="currencies"
                            @change="updateCurrencyType"
                            :previousSelected="usuario.currency"
                            v-show="usuario.currency"
                        />
                    </div>
                </div>

                <div class="contend-margin" style="margin-top: 0">
                    <div class="info-btn">
                        Exporte suas movimentações {{ $variables.brandWithArtigo('n') }} em uma planilha em formato CSV.
                    </div>

                    <small class="sucess-msg" :class="{ err }">{{ sucessMsg }}</small>
                    <form @submit.prevent.self="gerarPlanilha" class="form-planilha" novalidate>
                        <basic-button
                            label="Exportar histórico"
                            type="submit"
                            width="max-content"
                            :loading="disabled"
                            data-cy="submit-export-historic"
                        />
                    </form>

                    <div class="info-btn">
                        Você pode apagar todos as transações de sua conta (receitas, despesas, investimentos) para
                        recomeçar do zero. <strong>Esta ação é irreversível!</strong>
                    </div>

                    <basic-button
                        data-cy="reset-account"
                        label="Reiniciar Conta"
                        :reverse="true"
                        :width="192"
                        @click="openConfirmationModal('reset')"
                    />

                    <div class="info-btn">
                        Você pode excluir <strong>definitivamente</strong> sua conta
                        {{ $variables.brandWithArtigo('n') }}, perdendo todos os seus dados.
                    </div>

                    <basic-button
                        data-cy="delete-account"
                        label="excluir Conta"
                        :width="192"
                        bgColor="#FF113A"
                        @click="openConfirmationModal('delete')"
                    />
                </div>

                <div class="contend-margin">
                    <h1>Sobre {{ $variables.brandWithArtigo() }}</h1>

                    <div class="flex column">
                        <div class="flex justify-start text-box">
                            <span class="box-title">Painel {{ $variables.brand }} Web</span>
                            <span>versão {{ version }}</span>
                        </div>
                    </div>

                    <div style="display: none">
                        <div class="info-btn">
                            Problemas, sugestões ou críticas? Entre em contato com o suporte {{ $variables.brand }}.
                        </div>

                        <button class="btn talk-to-us-btn" @click="select('chat')">Fale com a gente</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="manageAccountModal.open" class="overlay">
            <v-scale-transition origin="center center 0">
                <confirmation-modal
                    v-if="manageAccountModal.open"
                    isGeneric
                    :text="manageAccountModal.text"
                    @closeModalEvent="manageAccountModal.open = false"
                >
                    <template #generic-btn>
                        <basic-button
                            :style="{ marginBottom: '8px' }"
                            data-cy="modal-confirm-reset"
                            class="gutter"
                            :label="manageAccountModal.btnLabel"
                            width="100%"
                            @click="manageAccountModal.action"
                        />
                    </template>
                </confirmation-modal>
            </v-scale-transition>
        </div>

        <div v-show="checkModal" class="overlay">
            <v-scale-transition origin="center center 0">
                <confirmation-create-modal
                    v-if="checkModal"
                    title="Confirmação"
                    :text="checkText"
                    :error="checkError"
                    @confirmEvent="closeCheckModal"
                >
                </confirmation-create-modal>
            </v-scale-transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'

import StackedInput from '../../components/inputs/StackedInput.vue'
import StackedSelect from '../../components/inputs/StackedSelect.vue'
import ToggleSwitch from '../../components/inputs/ToggleSwitch.vue'
import ConfirmationModal from '../../components/modals/confirmation-modals/ConfirmationModal.vue'
import BasicButton from '../../components/buttons/BasicButton.vue'
import ConfirmationCreateModal from '../../components/modals/confirmation-modals/ConfirmationCreateModal.vue'

import authService from '../../services/auth.service'
import { errorHandler } from '../../common/errorHandler'
import _request from '@/common/request'
import { environment } from '@/common/environment'

export default {
    name: 'PreferencesConfig',

    components: {
        ToggleSwitch,
        StackedInput,
        StackedSelect,
        BasicButton,
        ToggleSwitch,
        ConfirmationModal,
        ConfirmationCreateModal,
    },

    data() {
        return {
            preferences: {
                notifications: true,
                notificationTime: '09:00',
                sumBalance: true,
                currency: authService.getOnLocalStorage('currency'),
            },
            currencies: [
                {
                    name: 'Real',
                    _id: 'BRL',
                },
                {
                    name: 'Dólar',
                    _id: 'USD',
                },
                {
                    name: 'Euro',
                    _id: 'EUR',
                },
                {
                    name: 'Iene',
                    _id: 'JPY',
                },
                {
                    name: 'Libra',
                    _id: 'GBP',
                },
            ],
            currency: authService.getOnLocalStorage('currency'),
            errMsg: '',
            sucessMsg: '',
            err: false,
            email: '',
            checkModal: false,
            checkText: 'Conta reiniciada com sucesso',
            checkError: false,

            manageAccountModal: {
                open: false,
                text: '',
                btnLabel: '',
                action: () => {},
            },
            usuario: {},
        }
    },

    computed: {
        ...mapState(mainStore, ['user', 'disabled']),

        version() {
            return process.env.VUE_APP_VERSION
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        openConfirmationModal(mode) {
            switch (mode) {
                case 'reset':
                    this.manageAccountModal.text = 'Deseja mesmo reiniciar esta conta?'
                    this.manageAccountModal.btnLabel = 'Reiniciar'
                    this.manageAccountModal.action = this.resetUserAccount
                    break
                case 'delete':
                    this.manageAccountModal.text = 'Deseja mesmo excluir esta conta?'
                    this.manageAccountModal.btnLabel = 'excluir'
                    this.manageAccountModal.action = this.deleteUserAccount
            }

            this.manageAccountModal.open = true
        },

        updateNotificationTime(value) {
            this.preferences.notificationTime = value
        },

        toggleNotifications(value) {
            this.preferences.notifications = value
        },

        toggleTutorial(value) {
            this.DISABLED()

            _request
                .patch({ collection: 'users', data: { skipTutorial: value }, _id: this.user._id })
                .catch((err) => console.error(err.response.data.message))
                .finally(() => this.DISABLED())
        },

        toggleSumBalance(value) {
            this.preferences.sumBalance = value
        },

        updateCurrencyType(value) {
            this.currency = value
            authService.saveOnLocalStorage('currency', value.toString())
            this.patchUser()
        },

        async resetUserAccount() {
            this.DISABLED()

            authService.resetAccount().then(async () => {
                try {
                    await _request.fetch(['payments', 'transactions'])
                    this.checkModal = true
                } catch (error) {
                    this.checkModal = true
                    this.checkError = true
                    this.checkText = 'Houve um erro ao reiniciar a conta! Por favor tente mais tarde!'
                } finally {
                    this.manageAccountModal.open = false
                    this.DISABLED()
                }
            })
        },

        async deleteUserAccount() {
            try {
                const response = await authService.deleteAccount()

                switch (response.status) {
                    case 204:
                        authService.logout()
                        break
                    default:
                        throw new Error(response.message)
                }
            } catch (e) {
                this.checkModal = true
                this.checkError = true
                this.checkText = e.message ? e.message : 'Não foi possível deletar sua conta no momento'
            } finally {
                this.manageAccountModal.open = false
            }
        },

        gerarPlanilha: async function () {
            this.DISABLED()
            this.err = false
            this.sucessMsg = ''

            axios
                .post(`${environment.USERS_URL}/export_user_story`)
                .then((response) => {
                    let blobUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
                    this.downloadDocument(blobUrl, 'historico_Otto_2.0.csv')
                })
                .catch(async (e) => {
                    const msg = errorHandler(e)
                    this.sucessMsg = msg
                    this.err = true
                })
                .finally(() => this.DISABLED())
        },

        downloadDocument(blob, filename) {
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: blob,
                download: filename,
            }).click()
        },

        select(route) {
            this.$router.push({
                name: route,
            })
        },

        closeCheckModal() {
            this.checkModal = false
        },

        resetAccount() {
            this.resetUserAccount()
        },

        patchUser() {
            this.DISABLED()

            _request
                .patch({ collection: 'users', data: { currency: this.currency }, _id: this.user._id })
                .catch((err) => console.error(err.response.data.message))
                .finally(() => this.DISABLED())
        },
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler(u) {
                this.usuario = Object.assign({}, this.usuario, u)
                if (!this.usuario.currency) this.usuario.currency = 'BRL'
            },
        },
    },

    mounted() {
        this.usuario = Object.assign({}, this.usuario, this.user)
        if (!this.usuario.currency) this.usuario.currency = 'BRL'
    },
}
</script>

<style lang="scss" scoped>
.preferences-config {
    padding-top: 41px;

    .preferences-config-box {
        width: 780px;

        .form-width {
            max-width: 190px;
        }

        .stacked-input {
            margin-top: 0px;
        }

        .info-btn {
            color: #848484;
            font-size: 16px;
            line-height: 22px;
            margin: 20px 0;
        }

        .contend-margin {
            margin-top: 32px;
            position: relative;

            .form-planilha {
                margin-top: 20px;
                display: flex;

                .stacked-input {
                    display: inline-block;
                    width: 300px;
                    margin-right: 10px;
                }
            }

            .text-box {
                width: 398.98px;
                color: #848484;
                font-size: 16px;
                margin: 16px 0 5px 0;

                .box-title {
                    width: 300px;
                    font-weight: 500;
                }
            }
        }

        h1 {
            @include section-title();
        }

        .toggle-switch-container {
            margin: 24px 0 20px 20px;

            .toggle-label {
                width: 298px;
                color: #646464;
                font-size: 16px;
                line-height: 19px;
            }

            .info-btn {
                line-height: 1.5;
            }
        }

        .basic-button {
            &.not-allowed {
                cursor: not-allowed;
            }
        }

        .sucess-msg {
            color: limegreen;
            font-weight: 500;

            &.err {
                color: $hint-red;
            }
        }

        .checkbox-input {
            margin-top: 20px;

            label {
                color: $action-btn-color;
                font-size: 13px;
                margin-left: 10px;
                font-weight: 500;
                position: relative;
                bottom: 1px;
            }
        }

        .talk-to-us-btn {
            color: white;
            font-weight: 400;
            background: linear-gradient(45deg, #5617bc 0%, #9b56e0 100%);
        }
    }
}

.flex {
    display: flex;
}

.flex-basis {
    flex-basis: 190px;
}

.space-around {
    justify-content: space-around;
}

.column {
    flex-direction: column;
}

.center {
    align-items: center;
}

.grow-1 {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.space-between {
    justify-content: space-between;
}
</style>
