import moment from 'moment'

export default {
    user: {},
    payments: [],
    transactions: [],
    invoices: [],
    nextMonthInvoices: [],
    invoicesWithoutFiltering: [], // listagem de invoice sem levar em consideração o período do cartão
    categories: [],
    budgets: {},
    balances: {
        currentBalance: 0,
    },
    balancesChart: [],
    objectives: [],
    subscriptions: [],
    bankaccesses: [],
    families: {},
    linkedtransactions: [],
    profiles: {},
    experts: [],
    trails: [],
    userconfigs: {},
    tasks: [],
}
