<template>
    <modal-frame title="Projeção do orçamento" headerAlign="left" width="95vw" @close-modal="$emit('close-modal')">
        <template #content>
            <planning-details-list
                :headers="labels"
                :items="listData"
                :revenuesItems="getPlanningValues('revenues')"
                :essentialItems="getPlanningValues('essentialCategories')"
                :noessentialitems="getPlanningValues('noessentialCategories')"
                :debtsItems="getPlanningValues('debtsCategories')"
                :investimentsItems="getPlanningValues('investimentCategories')"
                :titles="listTitles"
                :categoriesLists="categoriesLists"
                style="margin-top: -28px"
                @submit-value="updateAccumulateValue"
            />
        </template>
    </modal-frame>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { mainStore } from '../../../store'

import ModalFrame from '../../../containers/ModalFrame.vue'
import PlanningDetailsList from '../../lists/PlanningDetailsList.vue'

import sort from '../../../common/sort'
import apiService from '../../../services/api.service'
import { environment } from '../../../common/environment'
import util from '../../../common/util'

export default {
    name: 'PreviewModal',

    components: { ModalFrame, PlanningDetailsList },

    props: {
        budgets: {
            type: Array,
            default: () => [],
        },
        update: {
            type: Object,
            default: () => {},
        },
        previousMonths: {
            type: Number,
            default: 0,
        },
        id: {
            type: String,
        },
    },

    data() {
        return {
            labels: [],
            listTitles: [
                'Receitas',
                'Despesas Fixas',
                'Despesas Variáveis',
                'Divídas & parcelas',
                'Investimentos',
                'Saldo',
                'Acumulado',
            ],
            plannings: [],
            categoriesLists: undefined,
        }
    },

    computed: {
        ...mapState(mainStore, ['user', 'profiles', '_date_selected', '_date', 'isOttoFamily', 'selectedUser']),

        listData() {
            const items = [...this.balanceProgress()[0].data, ...this.balanceProgress()[1].data]
            return items
        },
    },

    methods: {
        ...mapActions(mainStore, ['WAITING', 'DISABLED']),

        updateAccumulateValue(value) {
            const index = this.plannings.findIndex((planning) =>
                this.$moment(planning.date.slice(0, 10)).isSame(this.$moment(this._date.inicio).add(-1, 'M'), 'M'),
            )
            let promise
            const isFake = this.plannings[index].fake

            this.DISABLED()

            if (isFake) {
                const { _id, fake, ...payload } = this.plannings[index]

                payload.accumulated = Number(payload.accumulated)

                promise = apiService.post(
                    `${environment.API_URL}budgets${this.isOttoFamily ? `?userId=${this.selectedUser}` : ''}`,
                    {
                        ...payload,
                        accumulated: util.sanitizeMoney(value),
                    },
                )
            } else
                promise = apiService.patch(`${environment.API_URL}budgets/${this.plannings[index]._id}`, {
                    accumulated: util.sanitizeMoney(value),
                })

            promise
                .then((resp) => {
                    this.$emit('update-planning-list', resp.data)
                    if (isFake) this.plannings.splice(index, 1, resp.data)
                })
                .catch(console.error)
                .finally(() => this.DISABLED())
        },

        balanceProgress() {
            let acumulado = 0

            const series = [
                {
                    data: Array(13).fill(0),
                    name: 'Saldo',
                },
                {
                    data: [],
                    name: 'Progressivo',
                },
            ]

            this.plannings
                .sort(sort.byDate)
                .slice(0, 13)
                .forEach((budget, index) => {
                    const totalSpend =
                        budget.investimentValue + budget.essentialValue + budget.noessentialValue + budget.debtsValue

                    const waste = budget.revenuesValue - totalSpend

                    series[0].data[index] = waste
                    acumulado += !index ? this.previousMonths : waste
                    series[1].data[index] = acumulado
                })

            return series
        },

        /**
         * @param {'essentialCategories' | 'noessentialCategories' | 'investimentCategories' | 'debtsCategories' | 'revenues'} type - tipo de gasto
         */
        getPlanningValues(type) {
            const values = Array(13).fill(0)

            if (!this.plannings.length) return values
            return values.map((value, index) => {
                if (!this.plannings[index]) return 0
                if (type === 'revenues') return this.plannings[index].revenuesValue
                if (type === 'investimentCategories' && !this.plannings[index].investimentCategories.length)
                    return this.plannings[index].revenuesValue * (this.plannings[index].investimentSpending / 100)
                return this.plannings[index][type].reduce((prevValue, cB) => {
                    return prevValue + cB.value
                }, 0)
            })
        },

        fillList() {
            this.plannings = structuredClone({ budgets: this.budgets }).budgets
            let count = -1
            const labels = []

            while (count < 12) {
                let date = this.$moment(this._date.inicio).add(count, 'M')

                const index = this.plannings.findIndex((b) => {
                    return date.isSame(this.$moment(b.date.slice(0, 10)), 'M')
                })

                labels.push(date.format('MMM YYYY'))

                if (index < 0) {
                    this.plannings.push({
                        revenuesValue: 0,
                        revenuesCategories: [],
                        debtsSpending: 0,
                        debtsValue: 0,
                        debtsCategories: [],
                        essentialSpending: 0,
                        essentialValue: 0,
                        noessentialSpending: 0,
                        noessentialValue: 0,
                        investimentSpending: 0,
                        investimentValue: 0,
                        _id: count,
                        essentialCategories: [],
                        noessentialCategories: [],
                        date: date.toISOString(),
                        user: this.user._id,
                        investimentCategories: [],
                        accumulated: 0,
                        fake: true,
                    })
                }

                count++
            }

            this.plannings = this.plannings.sort(sort.byDate)
            this.labels = labels
            this.fillCategoriesList()
        },

        fillCategoriesList() {
            const lists = [
                {
                    id: 'revenuesCategories',
                    categories: [],
                },
                {
                    id: 'essentialCategories',
                    categories: [],
                },
                {
                    id: 'noessentialCategories',
                    categories: [],
                },
                {
                    id: 'debtsCategories',
                    categories: [],
                },
                {
                    id: 'investimentCategories',
                    categories: [],
                },
            ]

            this.plannings.forEach((planning, index) => {
                Array(
                    'revenuesCategories',
                    'essentialCategories',
                    'noessentialCategories',
                    'debtsCategories',
                    'investimentCategories',
                ).forEach((key, _index) => {
                    planning[key].forEach((categoryBudget) => {
                        const finded = lists[_index].categories.findIndex((c) => c._id === categoryBudget.category._id)

                        if (finded > -1) lists[_index].categories[finded].balance[index] = categoryBudget.value
                        else {
                            const length = lists[_index].categories.push({
                                _id: categoryBudget.category._id,
                                name: categoryBudget.category.name,
                                group: categoryBudget.category.group,
                                category: categoryBudget.category.category,
                                planningCategory: categoryBudget.category.planningCategory,
                                balance: Array(13).fill(0),
                            })
                            lists[_index].categories[length - 1].balance[index] = categoryBudget.value
                        }
                    })
                })
            })

            this.categoriesLists = lists
        },
    },

    mounted() {
        this.fillList()
    },
}
</script>
