<template>
    <login-container>
        <transition name="fade">
            <form id="account-activation" class="screen-container" @submit.prevent="resendActivationEmail">
                <div v-if="disabled" class="loader"></div>

                <span v-else-if="!errorMessage" class="guide-message">
                    {{ ottoMsg }}
                </span>
                <span v-else class="guide-message error-message">
                    {{ errorMessage }}
                </span>

                <stacked-input
                    v-mask="'# # # # # #'"
                    v-if="!user.active"
                    label="Código de ativação"
                    v-model="activationCode"
                    :errorValidation="errors.activationCode.error"
                    :errorMsg="errors.activationCode.message"
                />

                <basic-button
                    v-if="showResendEmailBtn && !user.active"
                    label="Ativar Conta"
                    :disabled="disabled"
                    @click="activateAccount"
                />

                <a
                    v-if="showResendEmailBtn && !user.active"
                    @click="resendActivationEmail"
                    type="button"
                    style="text-transform: uppercase"
                >
                    Reenviar código de ativação
                </a>

                <basic-button
                    v-if="(!disabled && !showResendEmailBtn) || user.active"
                    label="Entrar"
                    :disabled="disabled"
                    @click="loginAfterActivation"
                />

                <a v-if="showResendEmailBtn && !user.active" type="button" @click="logout">Voltar ao login</a>
            </form>
        </transition>
    </login-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import BasicButton from '../components/buttons/BasicButton.vue'
import StackedInput from '../components/inputs/StackedInput.vue'
import LoginContainer from '../containers/LoginContainer.vue'

import authService from '../services/auth.service'

export default {
    components: { LoginContainer, BasicButton, StackedInput },

    name: 'AccountActivation',

    data() {
        return {
            ottoMsg: 'Informe o código de 6 dígitos que você recebeu no email cadastrado',
            showResendEmailBtn: true,

            activationCode: '',

            errorMessage: '',
            errors: {
                activationCode: {
                    error: false,
                    message: undefined,
                },
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['disabled', 'user']),
    },

    mounted() {
        if (this.user.active) this.ottoMsg = 'Sua conta já está ativada!'
    },

    methods: {
        ...mapActions(mainStore, ['SET_DATA']),

        resendActivationEmail() {
            authService.sendActivationEmail().then((response) => {
                const status = response.status || response

                switch (status) {
                    case 204:
                        this.errorMessage = ''
                        this.ottoMsg = 'Email reenviado!'
                        break
                    case 400:
                    case 404:
                        this.errorMessage = response.message
                        break
                    default:
                        this.errorMessage = 'Erro ao tentar enviar email, tente novamente'
                }
            })
        },

        activateAccount() {
            const activationCode = this.activationCode.replace(/\s+/g, '')

            authService.activateAccount(activationCode).then((response) => {
                const status = response.status || response

                switch (status) {
                    case 200:
                        if (authService.getOnLocalStorage('token')) {
                            this.SET_DATA({
                                data: response.data,
                                attr: 'user',
                            })
                        }
                        this.errorMessage = ''
                        this.ottoMsg = 'Sua conta foi ativada!'
                        break
                    case 400:
                        this.errorMessage = response.message
                        this.showResendEmailBtn = true
                        break
                    default:
                        this.errorMessage = 'Erro ao ativar a conta, tente novamente'
                        this.showResendEmailBtn = true
                }
            })
        },

        loginAfterActivation() {
            if (authService.getOnLocalStorage('token')) {
                this.$router.push({ name: 'dashboard' })
            } else {
                this.$router.push({ name: 'login' })
            }
        },

        logout() {
            authService.logout()
            this.$router.push('/login')
        },
    },
}
</script>

<style lang="scss" scoped>
.loader {
    font-size: 20px;
    margin: 20px 0;
}

.screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 400px;
    position: relative;
    padding: 24px 40px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.guide-message {
    font-weight: 400;
    margin-bottom: 15px;
    color: #34495e;
    font-weight: 500;
    text-align: center;
    padding: 0 5px;
    line-height: 1.35rem;
}

.error-message {
    color: firebrick;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    padding: 0 10px;
}

p {
    font-weight: 500;
}

button {
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    padding: 20px 35px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bolder;
    min-width: 100%;
    border: none;
    margin: 0 0 25px 0;
}

button:hover {
    cursor: pointer;
}

.terms {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;

    & input[type='checkbox'] {
        width: 20px !important;
        margin-right: 10px;
        position: relative;
        top: 3px;
    }

    & label {
        width: 100%;
        font-weight: normal;
        font-size: 13px;
        margin-top: 5px;
    }
}

.stacked-input {
    width: 100%;
}

.basic-button {
    margin: 1em 0 1.5em;
}

a {
    font-style: inherit;
    font-size: inherit;
    margin-bottom: 30px;
    cursor: pointer;
    color: $action-btn-color !important;
    font-weight: 500;
}

@media screen and (max-width: 480px) {
    .screen-container {
        width: 100%;
    }

    .content {
        padding: 0 3%;
    }

    .logo {
        margin: 30px auto 15px;
    }
}
</style>
