import { mainStore } from '../store'

const red = process.env.VUE_APP_EXPENSES
const blue = '#63b9f2'
const green = process.env.VUE_APP_REVENUES

export const summaryTransactions = (config) => {
    const store = mainStore()

    let previsto = store.balanceMonthExpectedPerType(config.type, config.payment, config.user)
    let atual

    if (!config.payment) {
        atual = store.balances.currentBalance
    } else {
        const payment = store.findById('payments', config.payment)

        if (!!payment) atual = payment.value
        else atual = 0
    }

    let array = [
        {
            title: (config.type === 'revenues' ? 'Receitas ' : 'Despesas ').concat('do mês'),
            color: previsto >= 0 ? blue : red,
            value: previsto,
        },
        {
            title: 'Saldo atual',
            color: atual >= 0 ? blue : red,
            value: atual,
        },
    ]

    switch (config.type) {
        case 'revenues':
            array.splice(0, 0, {
                title: 'Total Recebido',
                color: blue,
                value: store.balanceMonthPerType('revenues', true, config.payment, config.user),
            })

            array.splice(2, 0, {
                title: 'Resta a Receber',
                color: blue,
                value: store.balanceMonthPerType('revenues', false, config.payment, config.user),
            })

            return array
        case 'expenses':
            array.splice(0, 0, {
                title: 'Total Pago',
                color: blue,
                value: Math.abs(store.balanceMonthPerType('expenses', true, config.payment, config.user)),
            })

            array.splice(2, 0, {
                title: 'Resta a Pagar',
                color: red,
                value: Math.abs(store.balanceMonthPerType('expenses', false, config.payment, config.user)),
            })

            return array
        default:
            return [
                {
                    title: 'A Receber',
                    color: blue,
                    value: Math.abs(store.balanceMonthPerType('revenues', false, config.payment, config.user)),
                },
                {
                    title: 'Recebidas',
                    color: blue,
                    value: Math.abs(store.balanceMonthPerType('revenues', true, config.payment, config.user)),
                },
                {
                    title: 'A Pagar',
                    color: red,
                    value: Math.abs(store.balanceMonthPerType('expenses', false, config.payment, config.user)),
                },
                {
                    title: 'Pagas',
                    color: blue,
                    value: Math.abs(store.balanceMonthPerType('expenses', true, config.payment, config.user)),
                },
            ]
    }
}

export const summaryPayments = () => {
    const store = mainStore()

    let dinheiro = store.balancePaymentsPerType('money')
    let contaCorrente = store.balancePaymentsPerType('debit', 'Conta corrente')
    let poupanca = store.balancePaymentsPerType('debit', 'Poupança')
    let credito = store.balancePaymentsPerType('credit')

    return [
        {
            title: 'Dinheiro',
            color: dinheiro < 0 ? red : blue,
            value: dinheiro,
        },
        {
            title: 'Cartões de crédito',
            color: red,
            value: Math.abs(credito),
        },
        {
            title: 'Contas correntes',
            color: contaCorrente < 0 ? red : blue,
            value: contaCorrente,
        },
        {
            title: 'Contas Poupança',
            color: poupanca < 0 ? red : blue,
            value: poupanca,
        },
    ]
}

/**
 * Retorna os dados do summary card da tela de planejento
 * @param {{ accumulated: number, revenues: number, expenses: number }} payload - Acumulado dos planejamentos anteriores
 * @returns {[{ title: string, color: string, value: number }]}
 */
export const summaryBudget = (payload = { accumulated: 0, revenues: 0, expenses: 0 }) => {
    const balance = payload.revenues - payload.expenses

    return [
        {
            title: 'Receitas previstas',
            color: green,
            value: payload.revenues,
        },
        {
            title: 'Despesas previstas',
            color: red,
            value: payload.expenses,
        },
        {
            title: 'Saldo previsto',
            color: balance < 0 ? red : blue,
            value: balance,
        },
        {
            title: 'Saldo acumulado',
            color: payload.accumulated < 0 ? red : blue,
            value: payload.accumulated,
        },
    ]
}

export const summaryCalendar = () => {
    const store = mainStore()

    return [
        {
            title: 'RECEITAS',
            color: blue,
            value: store.balanceCalendar({ 'transaction.transactionType': 'revenues' }),
        },
        {
            title: 'RECEBIDAS',
            color: blue,
            value: store.balanceCalendar({ 'transaction.transactionType': 'revenues' }, true),
        },
        {
            title: 'DESPESAS',
            color: red,
            value: Math.abs(store.balanceCalendar({ 'transaction.transactionType': 'expenses' })),
        },
        {
            title: 'PAGAS',
            color: red,
            value: Math.abs(store.balanceCalendar({ 'transaction.transactionType': 'expenses' }, true)),
        },
    ]
}
