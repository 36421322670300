import moment from 'moment'
import sort from '../../common/sort'

const pluggyBelvoDictionary = {
    itau_br_retail: 201,
    bradesco_br_retail: 203,
    santander_br_retail: 208,
    bancodobrasil_br_retail: 211,
    nubank_br_retail: 212,
    bancointer_br_retail: 215,
    caixa_br_retail: 219,
}

export default {
    findPayments() {
        return (bankaccessId) => {
            return this.payments.filter((payment) => payment.bankAccess === bankaccessId).sort(sort.byPaymentType)
        }
    },

    getBelvoRelatedPluggyConnectors() {
        return this.bankaccesses
            .filter((bankAccess) => bankAccess.type !== 'pluggy')
            .map((bankAccess) => {
                return pluggyBelvoDictionary[bankAccess.institution]
            })
    },

    getAvailablePluggyConnectors() {
        const pluggyConnectors = Object.values(pluggyBelvoDictionary)
        const belvoRelatedPluggyConnectors = this.getBelvoRelatedPluggyConnectors

        return pluggyConnectors.filter((connector) => {
            if (belvoRelatedPluggyConnectors.indexOf(connector) === -1) return connector
        })
    },

    accountNeedSync() {
        return (payment) => {
            if (!payment.bankAccess || payment.user !== this.user._id) return false

            const { lastUpdate, lastSynced } = this.findById('bankaccesses', payment.bankAccess)
            const diff = moment().diff(moment(lastUpdate || lastSynced), 'days')

            return diff > 1
        }
    },
}
