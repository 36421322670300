import sort from '@/common/sort'

export default {
    // FILTRO DE FORMAS PAGAMENTOS POR TIPO
    filteredPayments() {
        return (type, notExternal, userId) => {
            const payments = this.payments
                .filter((payment) => {
                    let valid = true

                    if (notExternal) valid = !payment.externalId
                    if (userId) valid = valid && payment.user === userId
                    if (this.plannerWithFamily) valid = valid && payment.user !== this.user._id

                    return payment.type === type && valid
                })
                .sort(sort.byName)

            if (type === 'debit') payments.sort(sort.byPaymentType)

            return payments.sort(sort.byName)
        }
    },

    // FORMAS PAGAMENTOS QUE NÃO SÃO DO TIPO CRÉDITO
    notCredit(state) {
        return state.payments.filter((payment) => payment.type !== 'credit').sort(sort.byName)
    },

    // FORMAS PAGAMENTOS QUE NÃO SÃO DO TIPO DINHEIRO
    notMoney(state) {
        return state.payments.filter((payment) => payment.type !== 'money').sort(sort.byName)
    },

    // ENCONTRA O PAGAMENTO "DINHEIRO"
    findMoney(state) {
        const { user } = state
        return (_user = user._id) =>
            state.payments.find((payment) => payment.type === 'money' && payment.user === _user)._id
    },

    // AGRUPAR OS PAGAMENTOS POR TIPO ORDENANDO EM ORDEM ALFABÉTICA
    groupedPayments() {
        return (group, notExternalPayments, userId) => {
            const _id = userId || this.user._id

            let pays = [
                {
                    name: 'Dinheiro',
                    data: this.filteredPayments('money', notExternalPayments, _id),
                },
                {
                    name: 'Contas Bancárias',
                    data: this.filteredPayments('debit', notExternalPayments, _id),
                },
            ]

            if (['all', 'expenses'].includes(group))
                pays.push({
                    name: 'Cartões de crédito',
                    data: this.filteredPayments('credit', notExternalPayments, _id),
                })

            return pays
        }
    },

    findTransactionInvoice(state) {
        return (creditCardId) => {
            let invoice = state.invoices.find((invoice) => invoice.creditCardId === creditCardId)

            if (invoice) {
                return state.transactions.find((transaction) => transaction.invoiceReferenceId === invoice._id)
            }
            return null
        }
    },
}
