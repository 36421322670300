export default {
    waiting: false,
    waitingTime: 1000,
    error: false,
    fetched: false,
    disabled: false,
    action: false, // action bar
    chat: false,
    showSidebar: false,
    _date: {}, // data atual
    _date_selected: {}, // data da ultima query
    _next_month_date: {}, // date do mês seguinte a última query
    hideCategoriesModal: false,
    selectedUser: '',
}
