<template>
    <div class="task-card-list">
        <div
            class="task"
            :class="{ 'hide-border': index === tasks.length - 1 }"
            v-for="(t, index) in tasks"
            :key="index"
            @click="showId = t._id"
        >
            <div>
                <img src="../../../../assets/img/otto.svg" alt="" />

                <div>
                    <h4>{{ t.title }}</h4>
                    <p>{{ t.description }}</p>
                </div>
            </div>

            <v-expand-transition>
                <div v-show="showId === t._id" class="btn-container">
                    <basic-button
                        v-bind="buttonProps"
                        :loading="loading"
                        label="Não vou fazer"
                        bgColor="#F05656"
                        @click="setTask(t._id, 'rejected')"
                    />
                    <basic-button
                        v-bind="buttonProps"
                        :loading="loading"
                        label="Vou fazer"
                        bgColor="#34B28D"
                        @click="setTask(t._id)"
                    />
                </div>
            </v-expand-transition>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { mainStore } from '../../../../store'

import BasicButton from '../../../buttons/BasicButton.vue'

import request from '../../../../common/request'

defineProps({
    tasks: {
        type: Array,
        default: () => [],
    },
})

const store = mainStore()

const showId = ref('')
const buttonProps = ref({
    height: 32,
    width: 148,
    size: 'x-small',
})

const loading = computed(() => store.disabled)

function setTask(taskId, mode = 'accepted') {
    let data = {}

    switch (mode) {
        case 'accepted':
            data.tasksAccepted = [...store.tasksAccepted, taskId]
            break
        default:
            data.tasksRejected = [...store.tasksRejected, taskId]
    }

    store.DISABLED()

    request
        .patch({ collection: 'userconfigs', data, _id: store.userconfigs._id, upsert: true })
        .catch(console.error)
        .finally(() => store.DISABLED())
}
</script>

<style lang="scss" scoped>
.task-card-list {
    height: 60%;
    overflow-y: scroll;
    overflow-x: hidden;

    .task {
        border-bottom: 1px solid $lighter-grey;
        padding: 12px;
        display: flex;
        flex-direction: column;
        width: 100%;

        &.hide-border {
            border-bottom: unset;
        }

        img {
            border-radius: 50%;
            border: 1px solid $btn-blue;
            padding: 2px;
            margin-right: 12px;
            width: 40px;
            height: 40px;
        }

        & > div {
            display: flex;

            h4 {
                color: $black;
                font-weight: 600;
                margin-bottom: 8px;
            }

            p {
                color: $black;
                font-size: 14px;
            }
        }

        .btn-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}
</style>
