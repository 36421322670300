<template>
    <div class="page-model">
        <fieldset v-if="disabled" :disabled="disabled"></fieldset>

        <alert-header
            v-show="error"
            :show="error"
            message="Erro de comunicação com o servidor"
            backgroundColor="#FF5436"
        />

        <sidebar />

        <action-menu @openModalEvent="openModal" />

        <chat-window :options="chatOptions" />

        <div class="overlay" v-show="show">
            <v-slide-y-reverse-transition>
                <component
                    :is="component"
                    v-show="!confirmation.status"
                    v-bind="modalData"
                    :editData="data"
                    @delete="openConfirmationModal"
                    @editRecurrentTransaction="openConfirmationModal"
                    @modalClosedEvent="closeModal"
                    @show-snack-bar="showSnackBar"
                />
            </v-slide-y-reverse-transition>

            <v-slide-y-reverse-transition>
                <confirmation-modal
                    v-if="confirmation.status"
                    v-bind="confirmation.payload"
                    @closeModalEvent="closeConfirmationModal"
                >
                    <template #generic-btn>
                        <component
                            style="width: 100%; margin-bottom: 5px"
                            :is="confirmation.payload.button"
                            :label="confirmation.payload.buttonLabel"
                        />
                    </template>
                </confirmation-modal>
            </v-slide-y-reverse-transition>
        </div>

        <router-view
            @openModalEvent="openModal"
            @openConfirmationModalEvent="openConfirmationModal"
            @editTransactionEvent="openEditionModal"
            @updateChat="(options) => (chatOptions = options)"
        />

        <snack-bar v-bind="snackBar" @closed="snackBar = { message: '', bgColor: 'success' }" />
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../store'

import Sidebar from '../components/Sidebar'
import ModalTransition from '@/components/transitions/ModalTransition'
import ActionMenu from '../components/ActionMenu'
import AlertHeader from '../components/AlertHeader'
import ChatWindow from '../components/ChatWindow'
import TransactionsModal from '../components/modals/TransactionsModal'
import PaymentsModal from '../components/modals/PaymentsModal'
import BankTransitionModal from '../components/modals/BankTransitionModal'
import ObjectivesModal from '../components/modals/objectives/ObjectivesModal.vue'
import BankIntegrationModal from '../components/modals/BankIntegrationModal'
import EditLinkedTransaction from '../components/modals/EditLinkedTransaction.vue'
import ConfirmationModal from '../components/modals/confirmation-modals/ConfirmationModal.vue'
import BasicButton from '../components/buttons/BasicButton.vue'
import SnackBar from '../components/SnackBar.vue'
import CategoryBudgetModal from '../components/modals/CategoryBudgetModal.vue'

import util from '../common/util'

export default {
    name: 'PageModel',

    components: {
        Sidebar,
        ActionMenu,
        AlertHeader,
        ChatWindow,
        TransactionsModal,
        PaymentsModal,
        BankTransitionModal,
        ObjectivesModal,
        ModalTransition,
        ConfirmationModal,
        BankIntegrationModal,
        EditLinkedTransaction,
        BasicButton,
        SnackBar,
        CategoryBudgetModal,
    },

    data() {
        return {
            isOpen: false,
            component: '',
            modalData: {},
            texto: '',
            data: {},
            startDate: '',
            endDate: '',
            show: false,
            confirmation: {
                status: false,
                payload: {},
            },
            loading: false,
            err: false,
            transform: {
                translateX: 0,
                translateY: 100,
            },
            chatOptions: undefined,

            snackBar: {
                message: '',
                bgColor: 'success',
            },
        }
    },

    methods: {
        openModal(config) {
            this.show = true
            this.component = config.state
            this.modalData = { ...config.modalData }
            this.data = config.data || {}
        },

        showSnackBar(payload) {
            Object.assign(this.snackBar, payload)
        },

        openConfirmationModal(payload) {
            this.confirmation.payload = payload
            this.confirmation.status = this.show = true
        },

        closeModal() {
            this.component = null
            this.data = this.modalData = {}

            // esperar a animação acabar para esconder o overlay
            setTimeout(() => (this.show = false), 300)
        },

        closeConfirmationModal() {
            this.confirmation.status = false

            this.closeModal()
        },

        openEditionModal(payload, component = 'TransactionsModal') {
            this.data = payload
            this.component = component
            this.show = true
        },

        addEventListener() {
            this.$root.$on('confirmationDelete', (modal) => {
                this.openConfirmationModal(modal)
            })

            this.$root.$on('editTransactionEvent', (transaction) => {
                let date = transaction.date.toString().slice(0, 10)

                this.data = transaction
                this.component = 'TransactionsModal'
                this.show = true
                this.modalData = { modalData: util.translateTransaction(transaction.transactionType) }

                this.data.date = date
            })

            this.$root.$on('openModalEvent', (payload) => {
                this.data = payload.data
                this.modalData = { ...payload.modalData }
                this.component = payload.component
                this.show = true
            })

            this.$root.$on('openConfirmationModalEvent', (payload) => {
                this.openConfirmationModal(payload)
            })

            this.$root.$on('show-snack-bar', (payload) => {
                Object.assign(this.snackBar, payload)
            })
        },
    },

    computed: {
        ...mapState(mainStore, ['waiting', 'error', 'disabled', 'isExpired', 'snackBarData']),
    },

    watch: {
        snackBarData: {
            deep: true,
            handler() {
                this.snackBar = this.snackBarData
            },
        },
    },

    created() {
        this.addEventListener()
    },

    beforeUnmount() {
        Array.of(
            'confirmationDelete',
            'editTransactionEvent',
            'openModalEvent',
            'openObjetiveModal',
            'openConfirmationModalEvent',
        ).map((key) => {
            this.$root.$off(key)
        })
    },
}
</script>
