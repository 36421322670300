<template>
    <div class="radial-bar">
        <apexcharts :height="height" type="radialBar" ref="radial" :options="chartOptions" :series="series" />
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
    name: 'RadialBar',
    components: {
        apexcharts: VueApexCharts,
    },
    props: {
        series: Array,
        height: {
            type: String,
            default: '140',
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    fontFamily: 'Metropolis',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '55%',
                        },
                        track: {
                            background: '#DDDDDD',
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                fontSize: '20px',
                                fontFamily: 'Metropolis',
                                fontWeight: 'bold',
                                offsetY: 8,
                                formatter: function (val) {
                                    if (val < 1) return '< 1%'
                                    else return val.toFixed(0) + '%'
                                },
                            },
                        },
                    },
                },
                stroke: {
                    lineCap: 'round',
                },
                colors: ['#63D1B2'],
            },
        }
    },
}
</script>

<style lang="scss" scoped></style>
