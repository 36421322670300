<template>
    <div class="variable-spending">
        <div class="progress-bar-container" style="padding: 0">
            <div style="justify-content: flex-end; width: 100%">
                <!-- LOADER -->
                <v-skeleton-loader v-if="waiting" style="margin-top: 20px" type="text" />

                <div v-else class="row" style="margin: 0 0 8px; justify-content: space-between; display: flex">
                    <div class="subtitle">Semanal</div>

                    <div class="chart-text percentage">{{ weeklyPercentage.toFixed(0) }}%</div>
                </div>
            </div>

            <div v-if="!waiting" class="progress-bar-border">
                <div
                    class="progress-bar"
                    :class="weeklyPercentage < 100 ? 'green-background' : 'red-background'"
                    :style="{ width: weeklyPercentage + '%' }"
                ></div>
            </div>

            <div class="text-box">
                <p>
                    Projetado:
                    <v-skeleton-loader v-if="waiting" type="text" style="display: inline-block" :width="50" />
                    <span v-else>{{ formatNumber(noessentialValue / 4) }}</span>
                </p>
                <p>
                    Realizado:
                    <v-skeleton-loader v-if="waiting" type="text" style="display: inline-block" :width="50" />
                    <span>{{ formatNumber(Math.abs(weeklySpending)) }}</span>
                </p>
            </div>
        </div>

        <div class="progress-bar-container" style="padding: 0">
            <div style="justify-content: flex-end; width: 100%">
                <!-- LOADER -->
                <v-skeleton-loader v-if="waiting" style="margin-top: 20px" type="text" />

                <div v-else class="row" style="margin: 0 0 8px; justify-content: space-between; display: flex">
                    <div class="subtitle">Mensal</div>

                    <div class="chart-text percentage">{{ Math.abs(monthPercentage.toFixed(0)) }}%</div>
                </div>
            </div>

            <div v-if="!waiting" class="progress-bar-border second">
                <div
                    class="progress-bar"
                    :class="monthPercentage < 100 ? 'green-background' : 'red-background'"
                    :style="{ width: monthPercentage + '%' }"
                ></div>
            </div>

            <div class="text-box">
                <p>
                    Projetado:
                    <v-skeleton-loader v-if="waiting" type="text" style="display: inline-block" :width="50" />
                    <span>{{ formatNumber(noessentialValue) }}</span>
                </p>
                <p>
                    Realizado:
                    <v-skeleton-loader v-if="waiting" type="text" style="display: inline-block" :width="50" />
                    <span>{{ formatNumber(Math.abs(monthSpending)) }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { mainStore } from '../../store'

import util from '../../common/util'

const store = mainStore()

const waiting = computed(() => store.waiting)

const weeklyPercentage = computed(() => {
    return Math.abs(percentage(weeklySpending.value, noessentialValue.value / 4))
})

const monthPercentage = computed(() => {
    return Math.abs(percentage(monthSpending.value, noessentialValue.value))
})

const weeklySpending = computed(() => store.weeklySpending)
const monthSpending = computed(() => store.monthSpending)

const noessentialValue = computed(() => store.budgets.noessentialValue || 0)

const percentage = util.percentage
const formatNumber = util.formatNumber
</script>

<style lang="scss" scoped>
.variable-spending {
    margin: 8px 16px;
    @include easy-progress-bar();

    .progress-bar-container {
        margin-bottom: 16px;
    }

    .subtitle {
        font-size: 14px;
        color: #617085;
    }

    .chart-text {
        font-weight: 600;
        font-size: 10px;
        margin-top: auto;
        margin-bottom: auto;

        &.expenses-color {
            color: $expense-red;
            font-size: 13px !important;
            margin-right: 3px;
        }

        &.revenues-color {
            color: $revenue-green;
            font-size: 13px !important;
            margin-left: 3px;
        }

        &.percentage {
            font-size: 13px !important;
            color: $light-blue;
        }
    }

    .text-box {
        margin: 12px 0;

        p {
            margin-bottom: 4px;
            font-size: 14px;

            span {
                font-weight: 500;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .green-background {
        background-color: $green;
    }

    .red-background {
        background-color: $hint-red;
    }

    .progress-bar-border.second {
        .green-background {
            background-color: #efb256;
        }

        .red-background {
            background-color: $hint-red;
        }
    }
}
</style>
