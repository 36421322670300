import axios from 'axios'

import { mainStore } from '../store'

const { cpf } = require('cpf-cnpj-validator')

const validateCPF = (inputCpf) => {
    if (!cpf.isValid(inputCpf)) {
        return 'CPF INVÁLIDO'
    }
}

const validatePhone = (inputPhone) => {
    const cleanPhoneNumber = inputPhone ? inputPhone.replace(/[^\d]/g, '') : ''
    if (cleanPhoneNumber.length < 10) {
        return 'TELEFONE INVÁLIDO'
    }
}

const validateCEP = async (inputCep) => {
    const store = mainStore()

    const cleanCepNumber = inputCep ? inputCep.replace(/[^\d]/g, '') : ''
    if (cleanCepNumber.length !== 8) {
        return 'CEP INVÁLIDO'
    }

    try {
        store.DISABLED()
        delete axios.defaults.headers.common['Authorization']

        const cep = (await axios.get(`https://viacep.com.br/ws/${cleanCepNumber}/json/`)).data

        if (cep.erro) return 'CEP INVÁLIDO'
        return cep
    } catch (e) {
        console.error(e)
    } finally {
        store.DISABLED()
    }
}

const validateCreditcardNumber = (inputNumber) => {
    const validationMsg = 'NÚMERO DE CARTÃO INVÁLIDO'
    const cleanNumber = inputNumber ? inputNumber.replace(/[^\d]/g, '') : ''

    if (cleanNumber.length < 14) {
        return validationMsg
    }
    const cardBrand = window.Iugu.utils.getBrandByCreditCardNumber(inputNumber)
    if (!cardBrand) {
        return 'BANDEIRA NÃO ACEITA'
    }
    let cardNumberLength = 16
    switch (cardBrand) {
        case 'amex':
            cardNumberLength = 15
            break
        case 'diners':
            cardNumberLength = 14
            break
    }
    if (cleanNumber.length < cardNumberLength || !window.Iugu.utils.validateCreditCardNumber(cleanNumber)) {
        return validationMsg
    }
}

const validateExpirationValidDate = (month, year) => {
    if (!window.Iugu.utils.validateExpiration(month, year)) {
        return 'VALIDADE INVÁLIDA'
    }
}

const validateCVV = (inputCVV, inputNumber) => {
    const validationMsg = 'CVV INVÁLIDO'
    const cleanNumber = inputCVV ? inputCVV.replace(/[^\d]/g, '') : ''
    if (cleanNumber.length < 3) {
        return validationMsg
    }
    const cardBrand = window.Iugu.utils.getBrandByCreditCardNumber(inputNumber)
    let cvvLength = 3
    switch (cardBrand) {
        case 'amex':
            cvvLength = 4
            break
    }
    if (cleanNumber.length < cvvLength) {
        return validationMsg
    }
}

const validateCardOwner = (owner) => {
    const validationMsg = 'NOME INVÁLIDO'
    owner = owner.trim()
    const names = owner.split(' ')
    if (names.length < 2) {
        return validationMsg
    }
    let emptyLastName = false
    names.map((part) => {
        if (part.length === 0) {
            emptyLastName = true
        }
    })
    if (emptyLastName) {
        return validationMsg
    }
}

const svvDictionaryMessageValidation = (validationMsg) => {
    switch (validationMsg) {
        case 'Required.':
            validationMsg = 'CAMPO OBRIGATÓRIO'
            break
        case 'Invalid email.':
            validationMsg = 'E-MAIL INVÁLIDO'
            break
    }
    return validationMsg
}

const validateEmail = (email) => {
    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return emailRegex.test(email)
}

const validateUrl = (url) => {
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

    return urlRegex.test(url)
}

export default {
    validateCPF,
    validatePhone,
    validateCEP,
    validateCreditcardNumber,
    validateExpirationValidDate,
    validateCVV,
    validateCardOwner,
    svvDictionaryMessageValidation,
    validateEmail,
    validateUrl,
}
