import helper from '../helpers/mutation.helper'

export default {
    //fetch dos dados

    SET_DATA(payload) {
        this[payload.attr] = helper.prepareData(payload.data, payload.attr, this, payload.options)
    },

    ATT_SETED_DATA(payload) {
        let index = this[payload.attr].findIndex((s) => s._id === payload.data._id)
        this[payload.attr].splice(index, 1, payload.data)
    },

    //post de dados

    PUSH_DATA(payload) {
        if (helper.isPushableData(this, payload)) {
            if (payload.attr === 'invoices') {
                this.invoices
                    .find((invoice) => invoice.creditCardId === payload.data.payment._id)
                    .items.push(payload.data)
            } else if (payload.attr === 'budgets' || payload.attr === 'families') {
                this[payload.attr] = payload.data
            } else {
                this[payload.attr].push(payload.data)
            }
        }
    },

    //patch dos dados

    CHANGE_DATA(payload) {
        const singleDocumentCollections = ['user', 'families', 'userconfigs']
        let handled = helper.patchHandle(this, payload)

        if (!handled) {
            if (singleDocumentCollections.includes(payload.attr)) {
                this[payload.attr] = payload.data
            } else {
                let index = this[payload.attr].findIndex((el) => el._id === payload.data._id)
                if (index > -1) this[payload.attr].splice(index, 1, payload.data)
            }
        }
    },

    //delete dados

    REMOVE_DATA(payload) {
        let index

        const fullyHandled = helper.deleteHandle(this, payload)

        // Removendo membro da familia
        if (fullyHandled) return

        // Removendo transação do cartão
        // if (payload.attr === 'invoices') {
        //     let invoice = state.invoices.find((invoice) => invoice._id === payload.params._id)

        //     index = invoice.items.findIndex((item) => item._id === payload.params.sub_id)
        //     invoice.items.splice(index, 1)

        //     return
        // }

        index = this[payload.attr].findIndex((el) => el._id === payload.params._id)
        this[payload.attr].splice(index, 1)
    },

    /** Set data geral
     * @param {object} payload
     * @param {string} payload.attr - chave do store
     * @param payload.value - valor da chave
     */
    SET_MISC_DATA(payload) {
        this[payload.attr] = payload.value
    },

    //MODO DE ESPERA (ANIMAÇÔES)
    WAITING(start) {
        if (this.waiting && !this.disabled) {
            this.waitingTime = 0
            this.waiting = false
        } else if (this.disabled || start) {
            this.waitingTime = 1000
            this.waiting = true
        }

        // this.waiting = true
    },

    // QUANTO DA FETCH BEM SUCEDIDO APÓS O LOGIN
    FETCHED() {
        this.fetched = true
    },

    // ERRO DURANTE COMUNICAÇAO COM API
    ERRO() {
        this.error = true
        this.waiting = false
    },

    // DESFAZER ALERTA DE ERRO
    UNERR() {
        this.error = false
    },

    // DESABILITANDO EVENTOS DURANTE AS CHAMADAS NA API
    DISABLED() {
        this.disabled = !this.disabled
    },

    // LIMPA O STORE APÓS O LOGOUT
    LOGOUT() {
        this.$reset()
    },

    // PEGA A DATA DO DIA DE HOJE
    setAtualDate(payload) {
        this._date = payload
        this._date_selected = payload
        this._next_month_date = helper.nextMonth(payload)
    },

    // SALVA A DATA SELECIONADA NO QUERY ATRAVÉS DO CONTROLES NA TELA DE TRANSAÇÕES E ORÇAMENTOS
    setSelectedDate(payload) {
        this._date_selected = payload
        this._next_month_date = helper.nextMonth(payload)
    },

    //MOSTRA/ESCONDA A SIDEBAR
    toggleSidebar(payload) {
        if (payload !== undefined) this.showSidebar = payload
        else this.showSidebar = !this.showSidebar
    },

    closed(payload) {
        if (payload.value !== undefined) {
            this[payload.field] = payload.value
        } else {
            this[payload.field] = !this[payload.field]
        }
    },
}
