<template>
    <div class="accounts-box">
        <h1>{{ boxTitle }}</h1>

        <div class="row">
            <div v-if="waiting" class="accounts-list">
                <ul>
                    <li v-for="(n, index) in 5" :key="index" class="list-item" style="padding: 0">
                        <div class="row" style="width: 100%">
                            <div class="left-col">
                                <v-skeleton-loader :type="types.head" style="margin: auto" class="head" />

                                <v-skeleton-loader
                                    :type="types.arms"
                                    style="max-width: 150px"
                                    :width="randomWidth(100)"
                                    class="arms"
                                />
                            </div>

                            <div class="right-col" style="margin-top: 14px">
                                <v-skeleton-loader
                                    :type="types.foot"
                                    style="max-width: 100px"
                                    :width="randomWidth(100)"
                                    class="foot"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div v-else class="accounts-list" :class="{ scroll: sortedPayments().length > 5 }">
                <transition-group name="list" tag="ul">
                    <li v-for="payment in sortedPayments()" :key="payment._id" class="list-item">
                        <div class="list-btn">
                            <div class="row">
                                <router-link :to="/movimentacoes/ + payment._id" class="left-col">
                                    <div class="item-icon">
                                        <div
                                            v-if="payment.type == 'money'"
                                            class="list-item_icon"
                                            style="background-image: url(img/payments/dinheiro.svg)"
                                        ></div>

                                        <img
                                            v-if="payment.type == 'debit'"
                                            class="list-item_icon"
                                            :src="
                                                payment.accountType === 'Conta corrente'
                                                    ? 'img/payments/banco.png'
                                                    : 'img/payments/poupanca.svg'
                                            "
                                        />

                                        <div
                                            v-if="payment.type == 'credit'"
                                            class="list-item_icon"
                                            :style="
                                                'background-image:url(img/payments/' + sanitize(payment.flag) + '.png)'
                                            "
                                        ></div>
                                    </div>

                                    <div class="item-category">
                                        <h3>{{ payment.name }}</h3>
                                        <h4 v-if="payment.type === 'money'">Dinheiro</h4>
                                        <h4 v-if="payment.type === 'debit'">Conta bancária</h4>
                                        <h4 v-if="payment.type === 'credit'">Cartão de crédito</h4>
                                    </div>

                                    <div
                                        v-show="accountNeedSync(payment)"
                                        class="icon"
                                        title="Conta automática desatualizada"
                                    >
                                        <v-icon>mdi-alert-circle</v-icon>
                                    </div>
                                </router-link>

                                <div class="right-col">
                                    <h3 v-if="payment.type === 'credit'" class="expenses">
                                        {{ formatNum(balanceActualMonth(payment._id)) }}
                                    </h3>

                                    <h3 v-else :class="[payment.value >= 0 ? 'investments' : 'expenses']">
                                        {{ formatNum(payment.value + getRecurrencyInvoiceSum(payment._id)) }}
                                    </h3>

                                    <router-link
                                        v-if="accountNeedSync(payment)"
                                        class="icon refresh"
                                        :to="`/configuracoes/contas-automaticas/${payment.bankAccess}`"
                                    >
                                        <v-icon>mdi-refresh</v-icon>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </li>
                </transition-group>
            </div>

            <div class="balances">
                <h2>Saldo contas correntes</h2>

                <v-skeleton-loader v-if="waiting" :height="30" width="150" type="list-item" class="legend_payments" />

                <h3
                    v-else
                    class="medium-value"
                    :class="[
                        balancePaymentsPerType('debit', 'Conta corrente') < 0
                            ? 'red'
                            : balancePaymentsPerType('debit', 'Conta corrente') === 0
                              ? 'gray'
                              : 'blue',
                    ]"
                >
                    {{ formatNum(balancePaymentsPerType('debit', 'Conta corrente')) }}
                </h3>

                <h2>Saldo contas poupanças</h2>

                <v-skeleton-loader v-if="waiting" :height="30" width="150" type="list-item" class="legend_payments" />

                <h3
                    v-else
                    class="medium-value"
                    :class="[
                        balancePaymentsPerType('debit', 'Poupança') < 0
                            ? 'red'
                            : balancePaymentsPerType('debit', 'Poupança') === 0
                              ? 'gray'
                              : 'blue',
                    ]"
                >
                    {{ formatNum(balancePaymentsPerType('debit', 'Poupança')) }}
                </h3>

                <h2>Saldo cartões de crédito</h2>

                <v-skeleton-loader v-if="waiting" :height="30" width="150" type="list-item" class="legend_payments" />

                <h3 v-else class="medium-value last" :class="[balancePaymentsPerType('credit') === 0 ? 'gray' : 'red']">
                    {{ formatNum(balancePaymentsPerType('credit')) }}
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import util from '../../common/util'

export default {
    name: 'AccountsBox',

    props: {
        boxTitle: {
            type: String,
            default: 'Carteiras',
        },
    },

    data() {
        return {
            types: {
                head: 'avatar',
                arms: 'sentences',
                foot: 'text',
            },
            syncing: '',
        }
    },

    methods: {
        sanitize(str) {
            return util.sanitize(str)
        },

        randomWidth(base) {
            return util.randomWidth(base)
        },

        formatNum(number) {
            return util.formatNumber(number)
        },

        sortedPayments() {
            let payments = []

            payments.push.apply(payments, this.filteredPayments('money'))
            payments.push.apply(payments, this.filteredPayments('debit'))
            payments.push.apply(payments, this.filteredPayments('credit'))

            return payments
        },
    },

    computed: {
        ...mapState(mainStore, [
            'waiting',
            'filteredPayments',
            'balancePaymentsPerType',
            'getRecurrencyInvoiceSum',
            'findTransactionInvoice',
            'balanceActualMonth',
            'accountNeedSync',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.accounts-box {
    position: relative;
    min-width: 570px;
    @include rounded-box();
    @include box-shadow();
    overflow: hidden;
    height: max-content;

    h1 {
        padding: 22px 24px 16px;
        @include card-title();
        border-bottom: 2px solid #eef1f6;
    }

    .row {
        @include flex-between();
        flex-wrap: nowrap;
        margin: 0;
        height: 100%;
    }

    .accounts-list {
        flex: 1;
        padding: 0 12px 0 24px;
        max-height: 295px;

        &.scroll {
            overflow-y: scroll;
        }

        .list-item {
            padding: 12px 0;
            background-color: white;
            display: flex;
            align-items: center;

            .list-btn {
                width: 100%;
            }

            .icon {
                font-size: 16px;
                color: $hint-red;
                margin: auto 0 auto 12px;
                box-shadow: unset;

                &.refresh {
                    color: $light-grey;
                    font-size: 24px;
                    width: 24px;
                    height: 24px;
                    padding: unset;
                }

                &.syncing {
                    pointer-events: none;
                    cursor: wait;
                    animation: round 2s infinite;
                }
            }

            &:not(:last-child) {
                border-bottom: solid 1px $lighter-grey;
            }

            .left-col {
                @include flex-between();
            }

            .item-icon {
                .list-item_icon {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 0.5em;
                }
            }

            .item-category h3 {
                margin-bottom: 4px;
                @include list-item();
                max-width: 130px;
                letter-spacing: initial;
                color: $light-black;
                font-weight: normal;
            }

            .item-category h4 {
                @include list-category();
                letter-spacing: initial;
            }

            .right-col {
                text-align: right;
                display: flex;
                align-items: center;

                h3 {
                    margin-bottom: 4px;
                    @include std-value();
                    font-weight: 500;
                }
            }
        }
    }

    .balances {
        width: 230px;
        padding: 1em 16px;
        border-left: solid 2px $lighter-grey;
        text-transform: uppercase;

        h2 {
            margin-bottom: 6px;
            font-size: 12px;
            font-weight: 500;
            color: $light-grey;
        }

        h3 {
            margin-bottom: 27px;
            @include medium-value();
            color: $title-grey;

            &.last {
                margin-bottom: 0;
            }
        }

        .blue {
            color: $light-blue;
        }

        .red {
            color: $expense-red;
        }
    }

    .expenses {
        color: $expense-red;
    }

    .revenues {
        color: $revenue-green;
    }

    .investments {
        color: $light-blue;
    }

    .grey {
        color: $medium-grey;
    }
}

@media screen and (max-width: 700px) {
    .accounts-list {
        max-height: unset;
    }
}
</style>
