<template>
    <div class="bars-chart">
        <v-skeleton-loader
            v-if="waiting || loading"
            style="padding: 20px 0; margin-bottom: 24px"
            :width="550"
            :height="150"
            type="image"
        />

        <apexcharts v-else width="550" type="bar" :height="150" ref="bars" :options="chartOptions" :series="series" />
    </div>
</template>

<script>
import apexcharts from 'vue3-apexcharts'

import { mapState } from 'pinia'
import { mainStore } from '../../store'

import util from '@/common/util'

export default {
    name: 'BarsChart',

    components: {
        apexcharts,
    },

    props: {
        categories: Array,
        series: Array,
        loading: Boolean,
    },

    data() {
        return {
            chartOptions: {
                chart: {
                    fontFamily: 'Metropolis',
                    toolbar: {
                        show: false,
                    },
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: false,
                        columnWidth: '40px',
                        distributed: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    y: {
                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return util.formatNumber(value)
                        },
                    },
                },
                yaxis: {
                    show: false,
                },
                grid: {
                    show: false,
                },
                colors: ['#b3ddff', '#b3ddff', '#b3ddff', '#b3ddff', '#b3ddff', '#028FFF'],
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['waiting']),
    },

    methods: {
        updateChart(options) {
            this.chartOptions = {
                ...this.chartOptions,
                ...{
                    xaxis: {
                        categories: options,
                    },
                },
            }
        },
    },

    watch: {
        categories: {
            deep: true,
            immediate: true,
            handler() {
                this.updateChart(this.categories)
            },
        },

        series: {
            deep: true,
            immediate: true,
            handler() {
                this.updateChart(this.categories)
            },
        },
    },

    mounted() {
        this.updateChart(this.categories)
    },
}
</script>
