<template>
    <div class="task-card-help">
        <h4>Atinja seus objetivos mais rapidamente com a ajuda de um especialista em finanças pessoais</h4>

        <h5>
            Assine um de nossos planos e receba acompanhamento personalizado de um Planejador Financeiro certificado.
        </h5>

        <button @click="openLink">Quero atendimento personalizado</button>
    </div>
</template>

<script setup>
const { default: variables } = require('../../../../variables')

function openLink() {
    window.open(
        `https://api.whatsapp.com/send?phone=${variables.contactPhone}&text=Olá%2C%20vim%20da%20plataforma%20da%20Plano%20e%20quero%20conhecer%20mais, ${variables.brand}!`,
        '_blank',
    )
}
</script>

<style lang="scss" scoped>
.task-card-help {
    height: 100%;
    width: 100%;
    padding: 18px;
    background: linear-gradient(45deg, rgba(113, 27, 222, 1) 0%, rgba(63, 99, 237, 1) 100%);
    color: white;
    border-radius: 12px;
    text-align: center;
    @include flex-center();
    flex-direction: column;

    & > * {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
    }

    h5 {
        font-size: 18px;
        line-height: 20px;
        font-weight: medium;
    }

    button {
        font-size: 13px;
        text-transform: uppercase;
        border-radius: 27px;
        background-color: $otto-orange;
        width: 100%;
        height: 49px;
    }
}
</style>
