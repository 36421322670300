<template>
    <div class="advisors view-container">
        <div class="view-content">
            <ActionBar title="Especialistas" :showSearchIcon="true" @searchItem="(value) => (filter = value)">
                <div>
                    <select class="custom-select" v-model="filter.expertise">
                        <option value="" />
                        <option
                            v-for="(expertise, index) in Object.entries(expertizesDictonary)"
                            :key="index"
                            :value="expertise[0]"
                        >
                            {{ expertise[1] }}
                        </option>
                    </select>

                    <div class="arrow"></div>
                </div>
            </ActionBar>

            <div class="experts-content">
                <div v-for="(expert, index) in experts" :key="index">
                    <AdvisorCardVue :expert="expert" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import ActionBar from '../components/ActionBar.vue'
import AdvisorCardVue from '../components/cards/AdvisorCard.vue'

import { expertizesDictonary } from '../common/lists'
import request from '../common/request'

export default {
    name: 'Advisor',

    components: {
        ActionBar,
        AdvisorCardVue,
    },

    data() {
        return {
            filter: {},
            expertizesDictonary,
        }
    },

    computed: {
        ...mapState(mainStore, ['filteredExperts']),

        experts() {
            return this.filteredExperts(this.filter)
        },
    },

    methods: {
        ...mapActions(mainStore, ['closed']),
    },

    mounted() {
        this.closed({ field: 'action', value: false })
        request.fetch({ collection: 'experts' }).catch(console.error)
    },
}
</script>

<style lang="scss" scoped>
@include custom-select();

.advisors {
    .custom-select {
        margin-left: 12px;

        option {
            text-transform: capitalize;
        }
    }

    .experts-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}
</style>
