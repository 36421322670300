<template>
    <div class="flex bars-chart" style="height: 100%; width: '100%;">
        <v-skeleton-loader v-if="waiting" :width="'100%'" :height="'80%'" type="image" />
        <v-chart v-else :option="option" />
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { UniversalTransition } from 'echarts/features'
import { BarChart, LineChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import util from '@/common/util'

use([
    CanvasRenderer,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    LineChart,
    UniversalTransition,
])

export default {
    name: 'ColumnLineChart',

    components: {
        VChart,
    },

    props: {
        line: Array,
        series: Array,
    },

    computed: {
        ...mapState(mainStore, ['waiting']),

        option() {
            if (!this.waiting) {
                return {
                    color: [process.env.VUE_APP_REVENUES, process.env.VUE_APP_EXPENSES, '#028FFF'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                        },
                        valueFormatter: function (value) {
                            return util.returnCurrencyPrefix().prefix + ' ' + value.toFixed(2)
                        },
                    },
                    textStyle: {
                        fontFamily: "Metropolis",
                        fontSize: 11
                    },
                    grid: {
                        right: '2%',
                        left: '8%',
                        top: '20%',
                        bottom: '10%',
                    },
                    legend: {
                        data: ['Receitas', 'Despesas', 'Planejado'],
                        right: '0%',
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {
                                alignWithLabel: true,
                            },
                            // prettier-ignore
                            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        },
                    ],
                    yAxis: [
                        {
                            axisLabel: {
                                formatter: function (value) {
                                    let valor = Number(Math.abs(value)).toFixed(2)
                                    return value < 0 ? '-' + util.sanitizeChartLabels(valor) : util.sanitizeChartLabels(valor)
                                },
                            }
                        },
                        {
                            show: false,
                        },
                        {
                            type: 'value',
                            position: 'left',
                            alignTicks: true,
                            axisLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            },
                        },
                    ],
                    series: [
                        {
                            name: 'Receitas',
                            type: 'bar',
                            symbol: 'none',
                            data: this.series[0].data,
                            barWidth: '16px',
                        },
                        {
                            name: 'Despesas',
                            type: 'bar',
                            symbol: 'rect',
                            yAxisIndex: 1,
                            data: this.series[1].data,
                            barWidth: '16px',
                        },
                        {
                            name: 'Planejado',
                            type: 'line',
                            symbol: 'none',
                            yAxisIndex: 2,
                            data: this.line,
                        },
                    ],
                    responsive: true,
                    maintainAspectRatio: false,
                }
            }
        },
    },
}
</script>

<style scoped>
.chart {
    height: 220px;
    width: '100%';
}
</style>
