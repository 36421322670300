<template>
    <div class="task-card-bottom">
        <basic-button
            :reverse="true"
            bgColor="#F05656"
            label="Criar Nova tarefa Pessoal"
            width="100%"
            height="40px"
            @click="openModal = true"
        />

        <task-modal v-if="openModal" @close-modal="openModal = false" />
    </div>
</template>

<script setup>
import { ref } from 'vue'

import BasicButton from '../../../buttons/BasicButton.vue'
import TaskModal from '../../../modals/tasks/TaskModal.vue'

const openModal = ref(false)
</script>

<style lang="scss" scoped>
.task-card-bottom {
    padding: 10px 16px;
    height: 20%;
    border-top: 1px solid $lighter-grey;
}
</style>
