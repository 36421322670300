<template>
    <div class="categories-list">
        <div class="category flex column">
            <table style="width: 100%">
                <div class="hide-border"></div>

                <tr v-for="(element, index) in list" :key="index" class="flex column center justify-center">
                    <div class="flex tab column">
                        <div class="border-category"></div>

                        <div class="flex tab-contend center">
                            <td class="icon-contend flex center justify-center">
                                <img
                                    v-if="!isMembersList"
                                    :src="require('@/assets/img/categories/' + getImgUrl(element))"
                                    class="icon"
                                    :class="[sanitizeCategoryName(element), element.group]"
                                />

                                <i v-else :class="[familyStatusIcon(element)]"></i>
                            </td>

                            <td class="flex space-between grow-1 center">
                                <template v-if="!isMembersList">
                                    <h1 class="td-el">{{ element.name }}</h1>
                                    <h2 class="td-el">{{ getCategoryName(element.category) }}</h2>
                                    <a @click="$emit('edit-item', element)"> <edit-icon /> </a>
                                    <a @click="deleteElement(element)"> <delete-icon /> </a>
                                </template>

                                <template v-if="isMembersList">
                                    <h1 class="td-el">{{ element.email }}</h1>
                                    <h2 class="td-el">
                                        {{ element.role == 'admin' ? 'Administrador' : '' }}
                                    </h2>

                                    <a v-if="showDeleteIcon(element)" @click="deleteElement(element)">
                                        <delete-icon />
                                    </a>

                                    <a
                                        v-show="showLeaveFamilyButton(element)"
                                        class="leave-btn"
                                        :style="{
                                            pointerEvents: disabled ? 'none' : 'default',
                                            opacity: disabled ? 0.5 : 1,
                                        }"
                                        @click="leaveFamily(element)"
                                    >
                                        <span>SAIR</span>
                                    </a>
                                </template>
                            </td>
                        </div>
                    </div>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import DeleteIcon from '../icons/DeleteIcon.vue'
import BasicButton from '../buttons/BasicButton.vue'
import EditIcon from '../icons/EditIcon.vue'

import util from '@/common/util'

export default {
    components: { DeleteIcon, BasicButton, EditIcon },

    name: 'SettingsList',

    props: {
        list: {
            type: Array,
            require: true,
        },
        isMembersList: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        deleteElement(subcategory) {
            let params = {
                id: subcategory._id,
                categoryID: subcategory.category,
                leavingFamily: false,
            }

            if (this.isMembersList) {
                delete params['categoryID']
            }

            this.$emit('deleteElementEvent', params)
        },

        leaveFamily(element) {
            let params = {
                id: element._id,
                leavingFamily: true,
            }
            this.$emit('deleteElementEvent', params)
        },

        sanitizeCategoryName(category) {
            return util.sanitizeCategoryName(category)
        },

        getImgUrl(category) {
            return util.getImgUrl(category)
        },

        getCategoryName(category) {
            let categoryId = category._id || id
            return this.findById('categories', categoryId).name
        },

        showLeaveFamilyButton(element) {
            return element.role !== 'admin' && element.email === this.user.email
        },

        showDeleteIcon(element) {
            return element.role !== 'admin' && this.isFamilyAdminLogged
        },

        familyStatusIcon(element) {
            const active = element.status === 'active' && element.user
            const disable = element.status === 'active' && !element.user

            return active ? 'fas fa-check' : disable ? 'fa fa-exclamation' : 'far fa-clock'
        },
    },

    computed: {
        ...mapState(mainStore, ['user', 'disabled', 'subscriptions', 'findById', 'families']),

        isFamilyAdminLogged() {
            return this.families.user ? this.families.user === this.user._id : false
        },
    },
}
</script>

<style lang="scss" scoped>
.categories-list {
    width: 100%;
    margin-top: 24px;

    .hide-border {
        position: absolute;
        top: -1px;
        width: 100%;
        height: 2px;
        background-color: white;
    }

    .category {
        max-height: 319.15px;
        color: #434343;
        font-size: 16px;
        position: relative;

        table {
            position: relative;

            tr {
                box-shadow: 0px 0px 7px #0000000d;
                border: 1px solid #ebebeb;
            }
        }

        .border-category {
            width: 100%;
            border-bottom: 1px solid #ebebeb;
            padding: 0 13.75px 0 14.6px;
        }

        .tab {
            width: 100%;

            .tab-contend {
                padding: 15px;
            }

            .td-el {
                width: 40%;
                position: relative;
                top: 3px;
            }
        }
    }
}

.icon-contend {
    margin-right: 10px;

    .icon {
        height: 33px;
        width: 33px;
        border-radius: 50%;
    }

    .fa-check {
        height: 20px;
        width: 20px;
        border: 2px solid #16a085;
        border-radius: 50%;
        color: #16a085;
        font-size: 13px;
        text-align: center;
        line-height: 17px;
    }

    .fa-clock {
        background-color: white;
        color: #d0d0d0;
        font-size: 22px;
        font-weight: 100;
    }

    .fa-exclamation {
        height: 20px;
        width: 20px;
        border: 2px solid $hint-red;
        border-radius: 50%;
        color: $hint-red;
        font-size: 13px;
        text-align: center;
        line-height: 17px;
    }
}

.flex {
    display: flex;
}

.space-around {
    justify-content: space-around;
}

.column {
    flex-direction: column;
}

.center {
    align-items: center;
}

.grow-1 {
    flex-grow: 1 !important;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.leave-btn {
    font-weight: 500;
    color: white;
    width: 80px;
    background-color: #ff113a;
    border-radius: 4px;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
}
</style>
