<template>
    <head class="menu-head flex space-between">
        <h1 class="page-title left">
            <span :class="{ fade: !!subtitle }">{{ `${title}${subtitle ? ' > ' : ''}` }}</span>
            <span v-if="subtitle">{{ subtitle }}</span>
            <badge v-if="badge" label="manual" />
        </h1>

        <nav>
            <ul class="flex">
                <li v-if="showSearchIcon">
                    <input
                        class="search-input"
                        :class="{ active: showSearchInput }"
                        @input="(e) => $emit('searchItem', { name: e.target.value })"
                        @blur="onSearchInputBlur"
                        type="text"
                    />
                    <search-icon @click="showSearchInput = true" />
                </li>

                <li v-if="isOttoFamily && isTransactions">
                    <person-icon @updateElement="changeUser" :members="members" :_userId="userId" />
                </li>

                <slot />
                <!-- <li><upload-icon /></li>
                <li><download-icon /></li> -->
                <!--                 <li>
                    <i class="fas fa-filter" @click="openModalFilter" @closeFilterModalEvent="closeModalFilter"></i>
                    <div class="trasactions-filter">
                        <trasactions-filter-modal v-show="isFilter && isTransactions" />
                    </div>
                </li>
                <li><i class="fas fa-question-circle"></i></li> -->
            </ul>
        </nav>
    </head>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import TrasactionsFilterModal from '../components/modals/TrasactionsFilterModal'
import Badge from './icons/Badge.vue'
import DownloadIcon from './icons/DownloadIcon.vue'
import SearchIcon from './icons/SearchIcon.vue'
import UploadIcon from './icons/UploadIcon.vue'
import PersonIcon from './icons/PersonIcon.vue'

export default {
    name: 'ActionBar',

    components: {
        TrasactionsFilterModal,
        Badge,
        SearchIcon,
        UploadIcon,
        DownloadIcon,
        PersonIcon,
    },

    props: {
        title: {
            type: String,
            require: true,
        },
        subtitle: String,
        isTransactions: {
            type: Boolean,
            default: false,
        },
        badge: Boolean,
        showSearchIcon: Boolean,
        notAllOption: Boolean,
        userId: String,
    },

    computed: {
        ...mapState(mainStore, ['disabled', 'activeMembers', 'isOttoFamily']),

        members() {
            const people = [{ name: 'Todos', _id: '', image: undefined }]

            if (this.notAllOption) people.splice(0, 1)

            people.push(
                ...this.activeMembers().map((member) => {
                    const { name, _id, image } = member.user
                    const splitedName = name.split(' ')

                    return { name: splitedName[0], _id, image }
                }),
            )

            return people
        },
    },

    data() {
        return {
            isFilter: false,
            showSearchInput: false,
            // opções do filtro de transações por pessoa
        }
    },

    methods: {
        ...mapActions(mainStore, ['SET_MISC_DATA']),

        openModalFilter() {
            this.isFilter = true
        },

        closeModalFilter() {
            this.isFilter = !this.isFilter
        },

        onSearchInputBlur(e) {
            if (e.target.value) return
            this.showSearchInput = false
        },

        changeUser(userId) {
            this.SET_MISC_DATA({ attr: 'selectedUser', value: userId })
            this.$emit('filterByUser', userId)
        },
    },
}
</script>

<style lang="scss" scoped>
ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    width: 225px;
    justify-content: flex-end;
    align-items: center;
}

ul li {
    display: inline;
    padding-left: 14px;
    position: relative;

    .search-input {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #b7b8b9;
        position: absolute;
        right: 0;
        opacity: 0;
        color: #1e3351;
        padding: 0 30px 0 10px;
        font-size: 14px;
        transition: all 300ms ease-in-out;

        &.active {
            width: 260px;
            opacity: 1;

            & + .search-icon {
                pointer-events: none;
            }
        }
    }
}

ul li a {
    padding: 2px 10px;
    display: inline-block;
    font-size: 21px;
    color: #9fa5b1;
}

.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

.trasactions-filter {
    position: fixed;
    top: 83px;
    right: 67px;
    z-index: 2;
}

.menu-head {
    padding-bottom: 24px;
    text-align: left;
}

.page-title {
    @include page-title();

    .fade {
        opacity: 0.5;
    }
}

.space-between {
    justify-content: space-between;
}

.center {
    justify-content: center;
}

.fas {
    cursor: pointer;
}

.badge {
    position: relative;
    bottom: 3px;
}

.transactions-filter {
    position: absolute;
    top: 35px;
    right: -45px;
    min-width: 80px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;

    table {
        color: #9fa5b1;
        font-size: 5px;
        text-align: left;
        margin: 11px 0px 13px 5px;

        .btn-filter {
            position: absolute;
            left: -10px;
            width: 105px;
            box-shadow: unset !important;
        }
        tr {
            border-bottom: 1px solid #ebebeb;
            font-size: 5px;
        }

        td > a {
            color: #93979e;
            font-size: 18px;
        }
    }
}
</style>
