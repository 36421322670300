import request from './request'
import { mainStore } from '../store'

/* Checa o status atual da assinatura */
export const checkSubscription = async function () {
    const store = mainStore()

    await request.fetch({ collection: 'subscriptions' })
    return store.isExpired
}
