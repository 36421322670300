<template>
    <div class="social-login-buttons">
        <button
            v-if="$variables.facebookLoginEnabled"
            class="external-button facebook-button"
            @click.prevent="$emit('social-login', 'facebook')"
        >
            <ion-icon name="logo-facebook"></ion-icon>
        </button>

        <button
            v-if="$variables.googleLoginEnabled"
            class="external-button google-button"
            @click.prevent="$emit('social-login', 'google')"
        >
            <ion-icon name="logo-google"></ion-icon>
        </button>

        <button
            v-if="$variables.appleLoginEnabled"
            class="external-button apple-button"
            @click.prevent="$emit('social-login', 'apple')"
        >
            <ion-icon name="logo-apple"></ion-icon>
        </button>
    </div>
</template>

<script>
export default {
    name: 'SocialLoginButtons',
}
</script>

<style lang="scss" scoped>
.social-login-buttons {
    @include flex-between-center();
    align-self: center;
    margin-bottom: 1em;

    button {
        background-color: #3498db;
        color: white;
        border-radius: 4px;
        padding: 10px 8px 8px;
        font-family: inherit;
        font-size: 1rem;
        font-weight: bolder;
        border: none;
        margin: 0 12px;

        &:hover {
            cursor: pointer;
        }
    }

    .external-button {
        width: 50px;
        height: 50px;
        font-size: 1.8rem;
        text-align: center;

        &.facebook-button {
            background-color: $facebook-blue;
        }

        &.google-button {
            background-color: $google-red;
        }

        &.apple-button {
            background-color: #000;
            color: #fff;
        }
    }
}
</style>
