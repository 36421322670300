<template>
    <div class="wallet view-container">
        <div class="view-content">
            <ActionBar title="Formas de Pagamento" />

            <div class="flex">
                <div class="column grow" style="width: 800px">
                    <div class="card flex">
                        <div class="grow">
                            <payments-card title="Dinheiro" paymentType="Dinheiro" :payments="money" />
                        </div>
                    </div>

                    <div class="no-data" v-if="noData" style="margin-top: 80px">
                        <img :src="$variables.listIcon" alt="" />
                        <div>
                            <p>Hmmm… Nada por aqui…</p>
                            <p>Use os botões abaixo para conectar uma conta ou registrar transações manualmente</p>
                        </div>
                    </div>

                    <div v-else>
                        <div class="card flex">
                            <div class="grow">
                                <payments-card
                                    title="Contas Bancárias"
                                    paymentType="Contas Bancárias"
                                    :payments="debit"
                                    @openModalEvent="openModal"
                                    emptyText="Use os botões abaixo para conectar uma conta ou cadastrar uma conta bancária manualmente"
                                />
                            </div>
                        </div>

                        <div class="card flex">
                            <div class="grow">
                                <payments-card
                                    title="Cartões de Crédito"
                                    paymentType="Cartões de Crédito"
                                    :payments="credit"
                                    @openModalEvent="openModal"
                                    emptyText="Use os botões abaixo para conectar uma conta ou cadastrar uma cartão de crédito manualmente"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <summary-model
                    header="Saldo total carteiras"
                    :tableData="summaryData"
                    :extraValue="balancePayments"
                    :chartTitle="chartSeries.length > 0 ? 'Pagamentos por carteira' : ''"
                >
                    <template v-if="chartSeries.length > 0" v-slot:chart>
                        <CategoryChart :_options="chartOptions" :series="chartSeries" />
                    </template>
                </summary-model>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import ActionBar from '../components/ActionBar.vue'
import SummaryModel from '../components/cards/summary-model/SummaryModel.vue'
import PaymentsCard from '../components/cards/PaymentsCard.vue'
import CategoryChart from '../components/charts/CategoryChart.vue'

import { summaryPayments } from '../common/summary_configs'
import request from '../common/request'

export default {
    name: 'Wallet',

    components: {
        ActionBar,
        SummaryModel,
        PaymentsCard,
        CategoryChart,
    },

    data() {
        return {
            paymentsOpen: false,
            paymentsType: '',
            chartSeries: [],
            chartOptions: {},
            summaryData: summaryPayments(),
        }
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        updateSummaryCard() {
            let options = this.expensesPaymentsChart

            this.summaryData = summaryPayments()
            this.chartSeries = options.chartSeries
            this.chartOptions = options.chartOptions
        },

        openModal(payload) {
            this.$emit('openModalEvent', {
                data: payload.data,
                modalData: { modalData: payload.type },
                state: payload.component,
            })
        },
    },

    watch: {
        payments: function () {
            this.updateSummaryCard()
        },

        transactions: function () {
            this.updateSummaryCard()
        },
    },

    computed: {
        ...mapState(mainStore, [
            'payments',
            'transactions',
            'filteredPayments',
            'balancePayments',
            'expensesPaymentsChart',
        ]),

        money() {
            return this.filteredPayments('money')
        },

        debit() {
            return this.filteredPayments('debit')
        },

        credit() {
            return this.filteredPayments('credit')
        },

        noData() {
            return !this.credit.length && !this.debit.length
        },
    },

    async mounted() {
        this.DISABLED()
        await request.fetch(['payments'])
        this.DISABLED()
    },
}
</script>

<style lang="scss" scoped>
@include empty-list();

.chart {
    color: #9fa5b1;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 33px;

    .chart-title {
        margin-left: 24px;
    }
}

.card {
    margin-bottom: 24px;
}

.margin-right {
    margin-right: 20px;
}

.item {
    min-width: 75%;
}

.grow {
    flex-grow: 1;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.flex {
    display: flex;
}

.half-col {
    width: 50%;
}
</style>
