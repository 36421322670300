<template>
    <div class="avatar" :style="styles" @mouseover="$emit('mouseover')">
        <div v-if="avatar" class="img-container">
            <img :src="resolveAvatar()" />
        </div>

        <div v-else class="letter-avatar">{{ letter }}</div>
    </div>
</template>

<script>
import validation from '../../common/validation'
import { avatares, avatarBgColor } from '@/common/resolveAvatar'
import util from '../../common/util'

export default {
    name: 'Avatar',

    emits: ['mouseover'],

    props: {
        avatar: String,
        letter: String,
    },

    computed: {
        isValidUrl() {
            return validation.validateUrl(this.avatar)
        },

        styles() {
            return {
                '--letter-color': util.letterColor(this.letter),
                '--img-width': this.isValidUrl ? '100%' : '80%',
                '--avatar-bg': this.resolveAvatarBgColor(),
            }
        },
    },

    methods: {
        resolveAvatar() {
            if (this.isValidUrl) return this.avatar
            return avatares[this.avatar]
        },

        resolveAvatarBgColor() {
            if (this.isValidUrl) return 'transparent'
            return avatarBgColor[this.avatar]
        },
    },
}
</script>

<style lang="scss" scoped>
@include easy-avatar();

.avatar {
    .img-container {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        background-color: var(--avatar-bg);

        img {
            width: var(--img-width);
            margin: auto;
        }
    }
}
</style>
