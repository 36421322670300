<template>
    <div class="view-icon" @click="$emit('click')">
        <svg
            class="stUf5b"
            fill="grey"
            height="18px"
            viewBox="0 0 24 24"
            width="18px"
            aria-hidden="true"
            focusable="false"
        >
            <path
                d="M12,7c-2.48,0-4.5,2.02-4.5,4.5S9.52,16,12,16s4.5-2.02,4.5-4.5S14.48,7,12,7z M12,14.2c-1.49,0-2.7-1.21-2.7-2.7 c0-1.49,1.21-2.7,2.7-2.7s2.7,1.21,2.7,2.7C14.7,12.99,13.49,14.2,12,14.2z"
            ></path>
            <path
                d="M12,4C7,4,2.73,7.11,1,11.5C2.73,15.89,7,19,12,19s9.27-3.11,11-7.5C21.27,7.11,17,4,12,4z M12,17 c-3.79,0-7.17-2.13-8.82-5.5C4.83,8.13,8.21,6,12,6s7.17,2.13,8.82,5.5C19.17,14.87,15.79,17,12,17z"
            ></path>
            <path fill="none" d="M0,0h24v24H0V0z"></path>
        </svg>

        <small>{{ tooltip }}</small>
    </div>
</template>

<script>
export default {
    name: 'ViewIcon',

    emits: ['click'],

    props: {
        tooltip: {
            type: String,
            default: 'Ver detalhes',
        },
    },
}
</script>

<style lang="scss" scoped>
.view-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: #ecf0fd;
        border-radius: 50%;

        .a {
            fill: $darker-blue;
        }
    }

    .a {
        fill: #b7b8b9;
    }

    small {
        @include easy-tooltip();
        line-height: 40px;
        right: -15px;
        width: max-content;
    }
}
</style>
