<template>
    <div class="distribution-modal-rule">
        <div class="rule">
            <div v-for="n in 5" :key="n" class="step-container">
                <div class="step" :class="{ active: n <= step }">{{ n }}</div>
                <div class="label" :class="{ active: n <= step }">{{ texts[n - 1] }}</div>
            </div>
        </div>

        <div class="rule-line"></div>
    </div>
</template>

<script>
export default {
    name: 'DistributionModalRule',

    props: {
        step: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            texts: ['Receitas', 'Despesas fixas', 'Despesas variáveis', 'Dívidas & Parcelas', 'Investimentos'],
        }
    },
}
</script>

<style lang="scss" scoped>
.distribution-modal-rule {
    margin: -24px 0 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .rule-line {
        height: 1px;
        background-color: $medium-grey;
        position: absolute;
        width: 350px;
        top: 26%;
    }
    .rule {
        display: flex;
        justify-content: space-between;
        position: relative;

        .step-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .label {
                font-size: 14px;
                width: 80px;
                text-align: center;
                color: #b3b3b3;
                margin-top: 8px;

                &.active {
                    color: inherit;
                }
            }

            .step {
                border-radius: 50%;
                background-color: #e5e5e5;
                color: $medium-grey;
                font-weight: bold;
                position: relative;
                z-index: 1000;
                width: 35px;
                height: 35px;
                line-height: 35px;
                text-align: center;

                &.active {
                    background-color: $green;
                    color: white;
                }
            }
        }
    }
}
</style>
