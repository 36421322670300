<template>
    <div class="empresas" :style="styles">
        <div class="content">
            <img class="logo" :src="$variables.brandIcon" alt="" />

            <transition name="fade">
                <div class="screen-container" v-show="show">
                    <div v-if="disabled" class="loader"></div>
                    <span v-else-if="!errorMessage" class="guide-message">
                        {{ ottoMsg }}
                    </span>
                    <span v-else class="guide-message error-message">
                        {{ errorMessage }}
                    </span>

                    <basic-button v-show="!disabled" :label="label" @click="goTo()" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import BasicButton from '../components/buttons/BasicButton.vue'

import apiService from '../services/api.service'
import { environment } from '../common/environment'
import { errorHandler } from '../common/errorHandler'

export default {
    components: { BasicButton },

    name: 'Empresas',

    data() {
        return {
            show: false,
            ottoMsg: 'Seu plano empresa foi ativado com sucesso',
            errorMessage: '',
        }
    },

    async mounted() {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const activationToken = urlSearchParams.get('activationToken')

        setTimeout(() => {
            this.show = true
        }, 500)

        await this.activateAccount(activationToken)
    },

    computed: {
        ...mapState(mainStore, ['disabled', 'user']),

        logged() {
            return Object.keys(this.user).length > 0
        },

        label() {
            let string = 'Voltar ao '

            if (this.logged) return string.concat('Painel')
            return string.concat('Login')
        },

        styles() {
            return {
                '--background': `url(${this.$variables.background})`,
            }
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        goTo() {
            if (this.logged) this.$router.push({ name: 'dashboard' })
            this.$router.push({ name: 'login' })
        },

        activateAccount(activationToken) {
            this.DISABLED()

            apiService
                .post(`${environment.ACTIVATE_CORPORTATE_PLAN}`, { activationToken })
                .catch((e) => (this.errorMessage = errorHandler(e)))
                .finally(() => this.DISABLED())
        },
    },
}
</script>

<style lang="scss" scoped>
.empresas {
    min-height: 100vh;
    padding-top: 40px;
    background-color: #ecf0fd;
    background-blend-mode: multiply;
}

.loader {
    font-size: 20px;
    margin: 20px 0;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 400px;
    position: relative;
    padding: 24px 40px 10px;
    box-shadow: 0px 3px 6px #00000029;
    transition: height ease-in-out;
}

.guide-message {
    font-weight: 400;
    margin-bottom: 15px;
    color: #34495e;
    font-weight: 500;
    text-align: center;
    padding: 0 5px;
    line-height: 1.35rem;
}

.top-divider {
    margin-top: -10px;
    padding-top: 18px;
    border-top: 1px solid #aaa;
}

#forgot-password {
    padding-bottom: 23px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #aaa;
}

.external-button {
    font-size: 0.9rem;
    text-align: left;
}

.facebook-button {
    background-color: #3b5998;
    margin-top: 5px;
}

.google-button {
    background-color: #fff;
    color: #9fa5b1;
    margin-top: -5px;
    margin-bottom: 30px;
}

.error-message {
    color: firebrick;
}

.logo {
    display: block;
    width: 150px;
    margin: 24px auto 2em;

    &.icon {
        width: 70px;
        margin-top: 0;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    padding: 0 10px;
}

p {
    font-weight: 500;
}

button {
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    padding: 20px 35px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bolder;
    min-width: 100%;
    border: none;
    margin: 0 0 25px 0;
}

button:hover {
    cursor: pointer;
}

label {
    width: 1px;
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    padding: 27px 17px !important;
    height: 35px;
    font-family: inherit;
    font-size: inherit;
    border: 1px solid #bbb;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100% !important;
    margin-bottom: 15px;

    &.erro {
        border: 1px solid #d0021b;
        background: rgba(208, 2, 27, 0.1);
    }
}

a {
    font-style: inherit;
    font-size: inherit;
    margin-bottom: 30px;
    cursor: pointer;
    color: $action-btn-color !important;
    font-weight: 500;
}

.pass-container {
    position: relative;
    width: 100%;
}

.terms {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;

    & input[type='checkbox'] {
        width: 20px !important;
        margin-right: 10px;
        position: relative;
        top: 3px;
    }

    & label {
        width: 100%;
        font-weight: normal;
        font-size: 13px;
    }
}

input {
    background: white;
    cursor: auto;
}

@media screen and (max-width: 768px) {
    .content {
        padding: 0 3%;
    }

    .logo {
        margin: 30px auto 15px;
    }
}

.stacked-input {
    width: 100%;
}

.basic-button {
    margin-top: 1.5em;
    width: 100%;
}
</style>
