<template>
    <div class="ebooks" id="ebooks">
        <iframe :src="ebooks[route.params.id]" frameborder="0" width="100%" height="100%"></iframe>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import entenda_dividas from '../assets/documents/ebooks/Entenda_suas_dividas/Ebook_-_Dividas.pdf'
import guia_completo_equilibrio_financeiro from '../assets/documents/ebooks/Guia_Completo_Equilibrio_Financeiro/Ebook_-_Guia_completo_para_o_Equilibrio_Financeiro.pdf'

const route = useRoute()

const ebooks = ref({
    entenda_dividas,
    guia_completo_equilibrio_financeiro,
})
</script>

<style lang="scss">
.ebooks {
    height: 100vh;
    width: 100vw;
}
</style>
