<template>
    <div class="wavy-chart">
        <v-skeleton-loader v-if="waiting" style="padding: 10px 20px 0" :height="200" type="image" class="legend_wavy" />

        <apexcharts
            v-else
            width="770"
            height="250"
            type="area"
            ref="wave"
            :options="chartOptions"
            :series="chartSeries"
        />
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import util from '@/common/util'

export default {
    name: 'WavyChart',

    components: {
        apexcharts: VueApexCharts,
    },

    props: {
        categories: Array,
        chartSeries: Array,
        filter: String,
    },

    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'basic-bar',
                    toolbar: {
                        show: false,
                    },
                    fontFamily: 'Metropolis',
                },
                name: 'wavy-chart',
                colors: [process.env.VUE_APP_REVENUES, process.env.VUE_APP_EXPENSES],
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'left',
                    floating: true,
                    offsetX: 35,
                    offsetY: 35,
                    markers: {
                        radius: 0,
                    },
                },
                xaxis: {
                    type: 'category',
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            let valor = Number(Math.abs(value)).toFixed(2)
                            return value < 0 ? '-' + util.sanitizeChartLabels(valor) : util.sanitizeChartLabels(valor)
                        },
                    },
                },
                grid: {
                    strokeDashArray: 3,
                },
                tooltip: {
                    y: {
                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return util.formatNumber(value)
                        },
                    },
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
            },
        }
    },

    methods: {
        updateChart(options) {
            if (this.$refs.wave) {
                this.chartOptions = {
                    ...this.chartOptions,
                    ...{
                        xaxis: {
                            categories: options,
                        },
                    },
                }
            }
        },

        toggleChartLegend(filtro) {
            if (this.$refs.wave) {
                switch (filtro) {
                    case 'revenues':
                        this.$refs.wave.showSeries('receitas')
                        this.$refs.wave.hideSeries('despesas')
                        break
                    case 'expenses':
                        this.$refs.wave.showSeries('despesas')
                        this.$refs.wave.hideSeries('receitas')
                        break
                    default:
                        this.$refs.wave.showSeries('despesas')
                        this.$refs.wave.showSeries('receitas')
                        break
                }
            }
        },
    },

    computed: {
        ...mapState(mainStore, ['waiting']),
    },

    watch: {
        filter: {
            immediate: true,
            handler(filtro) {
                this.toggleChartLegend(filtro)
            },
        },

        categories: {
            deep: true,
            immediate: true,
            handler() {
                this.updateChart(this.categories)
            },
        },

        chartSeries: {
            deep: true,
            immediate: true,
            handler() {
                this.updateChart(this.categories)
            },
        },
    },

    mounted() {
        this.updateChart(this.categories)
    },
}
</script>
