<template>
    <div class="card-footer">
        <div v-if="isSubscribed" class="btn-group">
            <basic-button
                v-if="!subscription.suspended"
                label="CANCELAR"
                :width="140"
                :reverse="true"
                :loading="disabled"
                @click="$router.push('/assinatura/cancelar')"
                data-cy="update-payment-method-inactive"
            />

            <basic-button
                v-else
                label="ativar"
                :width="140"
                :reverse="true"
                :loading="disabled"
                @click="$parent.$emit('update-subscription', 'active')"
                data-cy="update-payment-method-active"
            />

            <basic-button
                label="Alterar"
                :width="140"
                :disabled="disabled"
                @click="changePaymentMethod"
                data-cy="change-payment-method"
            />
        </div>

        <div v-else-if="hasBeenSubscribed" class="btn-group">
            <basic-button
                label="RENOVAR ASSINATURA"
                :reverse="true"
                :width="140"
                @click="$emit('update-subscription', 'active')"
            />

            <basic-button
                label="Alterar"
                :width="140"
                :disabled="disabled"
                @click="changePaymentMethod"
                data-cy="change-payment-method"
            />
        </div>

        <div
            v-else-if="subscription.plan !== 'otto-company' && subscription.plan !== 'otto-family-member'"
            class="btn-group"
        >
            <basic-button label="Assinar" :width="140" @click="$router.push('/assine')" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../../../store'

import BasicButton from '../../../buttons/BasicButton.vue'

import { freePlans } from '../../../../common/constants'

export default {
    components: { BasicButton },

    name: 'CardFooter',

    props: {
        subscription: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState(mainStore, ['disabled']),

        giftedPremium() {
            return this.subscription.plan === 'otto-company' || this.subscription.plan === 'otto-family-member'
        },

        isSubscribed() {
            return this.subscription
                ? this.subscription.status && !freePlans.includes(this.subscription.plan) && !this.giftedPremium
                : false
        },

        hasBeenSubscribed() {
            return this.subscription
                ? !this.subscription.status &&
                      !!this.subscription.clientId &&
                      !!this.subscription.subscriptionId &&
                      !this.giftedPremium
                : false
        },
    },

    methods: {
        changePaymentMethod() {
            this.$router.push('/assinatura/update')
        },
    },
}
</script>

<style lang="scss" scoped>
.card-footer {
    position: absolute;
    bottom: 8px;

    .btn-group {
        width: 100%;
        padding: 0 24px;
        display: flex;
        justify-content: center;
        margin-bottom: 8px;

        & > .basic-button:first-child {
            margin-right: 8px;
        }

        .basic-button {
            height: 50px !important;
        }
    }
}
</style>
