<template>
    <div class="advisor-card">
        <div class="content">
            <div class="img" :style="styles"></div>
            <p>{{ expert.name }}</p>
            <small>{{ expertizes }}</small>

            <star-rating-vue :rating="expert.stars" />

            <p>
                <strong>{{ money(expert.sessionPrice) }}</strong
                >/sessão
            </p>

            <div class="like">
                <p>{{ likes }}</p>
                <a @click.prevent="likeExpert" :class="{ disabled }">
                    <ion-icon v-if="youLiked" class="icon red" name="heart"></ion-icon>
                    <ion-icon v-else class="icon" name="heart-outline"></ion-icon>
                </a>
            </div>

            <basic-button-vue label="Conheça mais" :reverse="true" :width="280" @click="openModal = true" />
        </div>

        <expert-modal v-if="openModal" :expert="modifiedExpert" @close-modal="openModal = false" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'

import BasicButtonVue from '../buttons/BasicButton.vue'
import StarRatingVue from '../icons/StarRating.vue'
import ExpertModal from '../modals/ExpertModal.vue'

import { expertizesDictonary } from '../../common/lists'
import util from '../../common/util'
import request from '../../common/request'

export default {
    name: 'AdvisorCard',

    components: {
        BasicButtonVue,
        StarRatingVue,
        ExpertModal,
    },

    props: {
        expert: {
            required: true,
            default: () => {},
        },
    },

    data() {
        return {
            openModal: false,
            disabled: false,
        }
    },

    computed: {
        ...mapState(mainStore, ['user']),

        expertizes() {
            return this.expert.expertizes
                .map((expertise, index) => {
                    let translated = expertizesDictonary[expertise.name]
                        ? expertizesDictonary[expertise.name]
                        : expertise.name
                    return index > 0 ? ' ' + translated : translated
                })
                .toString()
        },

        modifiedExpert() {
            return {
                ...this.expert,
                expertizes: this.expertizes,
            }
        },

        likes() {
            const _likes = this.expert.likes
            return _likes > 999 ? util.sanitizeNumber(String(_likes)) : _likes
        },

        youLiked() {
            return false
        },

        styles() {
            return {
                '--background': `url(${this.resolveImg()})`,
            }
        },
    },

    methods: {
        ...mapActions(mainStore, ['CHANGE_DATA']),

        money: util.formatNumber,

        resolveImg() {
            return this.expert.image || this.$variables.placeholder
        },

        likeExpert() {
            const expert = structuredClone(this.expert)
            expert.likes += 1

            this.CHANGE_DATA({ attr: 'experts', data: expert })

            request.patch({ collection: 'experts', data: { likes: 1 }, _id: this.expert._id }).catch(console.error)
        },
    },

    mounted() {
        this.resolveImg()
    },
}
</script>

<style lang="scss" scoped>
.advisor-card {
    width: 330px;
    height: 280px;
    margin: 0 24px 50px 0;
    border-radius: 4px;
    position: relative;
    box-shadow: 0px 0px 10px #0000002b;
    padding: 16px 0;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    .img {
        height: 80px;
        width: 80px;
        border: 2px solid $btn-blue;
        background: white var(--background) no-repeat;
        border-radius: 50%;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
    }

    small {
        text-transform: capitalize;
    }

    .like {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;

        & > :disabled {
            cursor: waiting;
            pointer-events: none;
        }

        p {
            line-height: 20px;
        }

        .icon {
            visibility: inherit;
            transform: scale(1.5);
            margin-left: 6px;

            &.red {
                color: $hint-red;
            }
        }
    }
}
</style>
