export default {
    /**
     * Retorna os membros da familia. Membros planejadores são ignorados.
     * @returns {Array}
     */
    familyMembers() {
        return (showAllMembers) => {
            const user = this.user || {}
            const family = this.families || {}

            let members = family.members ? family.members : user.family ? user.family.members : []

            if (user?.profiles?.includes('planner') && !showAllMembers)
                members = members.filter((member) => member?.user?._id !== user._id)

            return members
        }
    },

    /**
     * Retorna o usuário principal da conta. Quando o usuário é planejador e está em uma familia, retorna o usuário admnistrador da familia
     */
    mainUserId() {
        const { _id } = this.user

        if (this.plannerWithFamily) return this.familyMembers().find((member) => member.role === 'admin')?.user?._id
        return _id
    },

    activeMembers() {
        return (showAllMembers) => {
            return this.familyMembers(showAllMembers).filter((member) => member.status === 'active' && !!member.user)
        }
    },
}
