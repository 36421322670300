<template>
    <div class="flex bars-chart" style="height: 100%">
        <v-skeleton-loader v-if="waiting" :width="'100%'" :height="'80%'" type="image" />
        <v-chart v-else :option="option" />
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { UniversalTransition } from 'echarts/features'
import { BarChart, LineChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import util from '@/common/util'

use([
    CanvasRenderer,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    LineChart,
    UniversalTransition,
])

export default {
    name: 'DualColumnChart',

    components: {
        VChart,
    },

    provide: {
        [THEME_KEY]: 'light',
    },

    props: {
        categories: Array,
        series: Array,
        labels: {
            type: Array,
            default: () => ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
        },
        loading: Boolean,
    },

    computed: {
        ...mapState(mainStore, ['waiting']),

        option() {
            if (!this.waiting) {
                return {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
                        },
                        valueFormatter: function (value) {
                            return util.returnCurrencyPrefix().prefix + ' ' + value.toFixed(2)
                        },
                    },
                    textStyle: {
                        fontFamily: 'Metropolis',
                        fontSize: 11,
                    },
                    grid: {
                        top: '4%',
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: function (value) {
                                let valor = Number(Math.abs(value)).toFixed(2)
                                return value < 0
                                    ? '-' + util.sanitizeChartLabels(valor)
                                    : util.sanitizeChartLabels(valor)
                            },
                        },
                    },
                    xAxis: {
                        type: 'category',
                        data: this.labels,
                    },
                    series: [
                        {
                            name: 'Receitas',
                            type: 'bar',
                            stack: 'total',
                            emphasis: {
                                focus: 'series',
                            },
                            data: this.series[0],
                        },
                        {
                            name: 'Parcelas',
                            type: 'bar',
                            stack: 'total',
                            emphasis: {
                                focus: 'series',
                            },
                            data: this.series[1],
                        },
                    ],
                    responsive: true,
                    maintainAspectRatio: false,
                    color: ['#028FFF', process.env.VUE_APP_EXPENSES],
                }
            }
        },
    },
}
</script>

<style scoped>
.chart {
    height: 150px;
    width: '100%';
}
</style>
