<template>
    <div class="report view-container">
        <div class="view-content content">
            <ActionBar title="RELATÓRIO DE EVOLUÇÃO MENSAL" />

            <div class="flex column">
                <div class="row" style="margin: 0 !important">
                    <report-chart-box
                        type="simpleColumn"
                        boxTitle="RESULTADOS MÊS A MÊS (PLANEJADO X REALIZADO)"
                        boxSubTitle="Quanto sobrou em cada mês"
                        :marginBottom="true"
                        :marginRight="true"
                        :width="750"
                        :height="346"
                        :tooltipText="'Quanto sobrou das suas receitas depois que todas as despesas foram pagas'"
                    >
                        <column-chart
                            style="margin-top: -10px"
                            :series="planningRealisedChart"
                            :labels="columnChartLabels"
                            :colors="chartColors"
                            width="100%"
                            :height="300"
                        />
                    </report-chart-box>

                    <report-chart-box v-if="!smallScreen" :margin-bottom="true" box-title="STATUS DO MÊS">
                        <planning-progress />
                    </report-chart-box>
                </div>

                <div v-if="smallScreen" class="row" style="margin: 0 !important">
                    <report-chart-box :margin-bottom="true" box-title="STATUS DO MÊS">
                        <planning-progress />
                    </report-chart-box>
                </div>

                <div class="row" style="margin: 0 !important">
                    <report-chart-box
                        type="columnLine"
                        boxTitle="RECEITAS x DESPESAS MÊS A MÊS"
                        :marginBottom="true"
                        :marginRight="true"
                        :width="564"
                        :height="240"
                    >
                        <column-line-chart :series="_revenuesExpensesBalance" :line="budgetsBalance" />
                    </report-chart-box>

                    <report-chart-box
                        v-if="!smallScreen"
                        type="dualColumn"
                        boxTitle="COMPRAS PARCELADAS NO CARTÃO X ORÇAMENTO"
                        :marginBottom="true"
                        :width="564"
                        :height="240"
                        :tooltipText="'Quanto do seu orçamento está comprometido com despesas parceladas no cartão de crédito.'"
                    >
                        <dual-column-chart :series="_creditInstallments" :labels="chartLabels" />
                    </report-chart-box>
                </div>

                <div v-if="smallScreen" class="row" style="margin: 0 !important">
                    <report-chart-box
                        type="dualColumn"
                        boxTitle="COMPRAS PARCELADAS NO CARTÃO X ORÇAMENTO"
                        :marginBottom="true"
                        :width="564"
                        :height="240"
                        :tooltipText="'Quanto do seu orçamento está comprometido com despesas parceladas no cartão de crédito.'"
                    >
                        <dual-column-chart :series="_creditInstallments" :labels="chartLabels" />
                    </report-chart-box>
                </div>

                <div class="row" style="margin: 0 !important">
                    <report-chart-box
                        type="simpleColumn"
                        boxTitle="ACÚMULO DE RESERVA (POUPANÇA)"
                        :marginRight="true"
                        :margin-bottom="smallScreen"
                        :width="564"
                        :height="240"
                        :tooltipText="'Quanto você acumulou em economias (poupança ou investimentos) até o mês atual.'"
                    >
                        <simple-column-chart
                            :width="564"
                            :columnColor="'#51A7DA'"
                            :series="savings"
                            :labels="chartLabels"
                        />
                    </report-chart-box>

                    <report-chart-box
                        v-if="!smallScreen"
                        type="simpleBar"
                        boxTitle="PROGRESSO DOS OBJETIVOS"
                        :width="564"
                        :height="240"
                    >
                        <simple-bar-chart :series="objectives" />
                    </report-chart-box>
                </div>

                <div v-if="smallScreen" class="row" style="margin: 0 !important">
                    <report-chart-box
                        type="simpleBar"
                        boxTitle="PROGRESSO DOS OBJETIVOS"
                        :width="564"
                        :height="240"
                        :marginBottom="true"
                    >
                        <simple-bar-chart :series="objectives" />
                    </report-chart-box>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import ActionBar from '../components/ActionBar'
import ReportChartBox from '../components/cards/ReportChartBox.vue'
import CategorySummaryCard from '../components/cards/CategorySummaryCard.vue'
import ColumnLineChart from '../components/charts/ColumnLineChart.vue'
import ColumnChart from '../components/charts/ColumnChart.vue'
import DualColumnChart from '../components/charts/DualColumnChart.vue'
import SimpleColumnChart from '../components/charts/SimpleColumnChart.vue'
import SimpleBarChart from '../components/charts/SimpleBarChart.vue'
import PlanningProgress from '../components/lists/PlanningProgress.vue'

import apiService from '../services/api.service'
import { environment } from '../common/environment'
import { months } from '../common/lists'

export default {
    name: 'EvolutionReport',

    components: {
        ActionBar,
        ReportChartBox,
        CategorySummaryCard,
        ColumnLineChart,
        DualColumnChart,
        SimpleColumnChart,
        SimpleBarChart,
        ColumnChart,
        PlanningProgress,
    },

    data() {
        return {
            chartSeries: [],
            chartOptions: {},
            yBalance: [],
            revenuesExpensesBalance: [],
            budgetsBalance: [],
            plannedBalance: [],
            creditInstallments: Array(6).fill(0),
            savings: [],
            columnChartLabels: months,

            chartColors: function ({ value, seriesIndex, w }) {
                const add = !seriesIndex ? 0 : 12
                const index = w.config.series[seriesIndex].data.findIndex((s) => s === value)

                const count = index + add

                if (count < 12) return '#BAC0CC'
                else return value < 0 ? process.env.VUE_APP_EXPENSES : '#028FFF'
            },
        }
    },

    computed: {
        ...mapState(mainStore, [
            'objectives',
            '_date',
            'balancesChart',
            'payments',
            'transactions',
            'filteredPayments',
            'balancePayments',
            'expensesPaymentsChart',
            'disabled',
            'filteredTransactions',
            'smallScreen',
            'waiting',
            'plannerWithFamily',
            'mainUserId',
        ]),

        fullYearQuery() {
            return `&startDate=${this.$moment(this._date.inicio)
                .startOf('Y')
                .format('YYYY-MM-DD')}&endDate=${this.$moment(this._date.inicio).endOf('Y').format('YYYY-MM-DD')}`
        },

        halfYearQuery() {
            let month = this.$moment(this._date.inicio).month()
            if (month < 5) month = 5

            return `&startDate=${this.$moment(this._date.inicio)
                .month(month - 5)
                .startOf('M')
                .format('YYYY-MM-DD')}&endDate=${this.$moment(this._date.inicio)
                .month(month)
                .endOf('M')
                .format('YYYY-MM-DD')}`
        },

        money() {
            return this.filteredPayments('money')
        },

        debit() {
            return this.filteredPayments('debit')
        },

        credit() {
            return this.filteredPayments('credit')
        },

        noData() {
            return !this.credit.length && !this.debit.length
        },

        balançosGrafico() {
            // evitar alguns warnings do pinia a respeito de mutação de estado
            return [...this.balancesChart]
        },

        _revenuesExpensesBalance() {
            return [
                {
                    data: this.revenuesExpensesBalance.revenues,
                    name: 'receitas',
                },
                {
                    data: this.revenuesExpensesBalance.expenses,
                    name: 'despesas',
                },
            ]
        },

        planningRealisedChart() {
            return [
                {
                    name: 'Planejado',
                    data: this.plannedBalance,
                },
                {
                    name: 'Realizado',
                    data: this.yBalance,
                },
            ]
        },

        _creditInstallments() {
            let revenues = Array(6).fill(0)
            if (this.balançosGrafico.length) {
                revenues = this.balançosGrafico[0].data
            }
            return [revenues, this.creditInstallments]
        },

        usedTransaction() {
            let ft = this.filteredTransactions('expenses').filter((transaction) => {
                return transaction.category.group !== 'invoice' && transaction.paid
            })
            let countCategories = []
            let total = 0

            ft.forEach((el) => {
                total += 1
                let index = 0
                index = countCategories.findIndex((cat) => {
                    return cat.name === el.category.name
                })
                if (index !== -1) {
                    countCategories[index].count += 1
                } else {
                    countCategories.push({ name: el.category.name, count: 1 })
                }
            })

            if (countCategories.length > 0)
                countCategories = countCategories.reduce((max, cat) => (max.count > cat.count ? max : cat))
            return [countCategories, total]
        },

        chartLabels() {
            let labels = []
            let currentMonth = new Date().getMonth()
            let qtd = 5
            let months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

            while (labels.length < 6) {
                let index = currentMonth - qtd
                if (index >= 0) {
                    labels.push(months[index])
                } else {
                    labels.push(months[months.length + index])
                }
                qtd -= 1
            }

            return labels
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED', 'WAITING']),

        async loadCharts() {
            const extraQuery = this.plannerWithFamily ? `&family=true&userId=${this.mainUserId}` : ''

            let response = await apiService.get(
                `${environment.BALANCES_URL}?type=chart${this.fullYearQuery}${extraQuery}`,
            )

            let yearBalance = []
            this.revenuesExpensesBalance = response.data
            response.data.revenues.forEach((el, i) => {
                yearBalance.push(el - response.data.expenses[i])
            })
            this.yBalance = yearBalance

            let chartType = 'budgets'
            response = await apiService.get(
                `${environment.BALANCES_URL}?type=chart&chartType=${chartType}${this.fullYearQuery}${extraQuery}`,
            )
            response.data.forEach((item) => {
                this.budgetsBalance.push(item.planned)
                this.plannedBalance.push(item.balance)
            })

            chartType = 'creditInstallments'
            response = await apiService.get(
                `${environment.BALANCES_URL}?type=chart&chartType=${chartType}${this.halfYearQuery}${extraQuery}`,
            )
            response.data.forEach((x, index) => {
                this.creditInstallments[index] = Math.abs(x)
            })

            chartType = 'savings'
            response = await apiService.get(
                `${environment.BALANCES_URL}?type=chart&chartType=${chartType}${this.halfYearQuery}${extraQuery}`,
            )
            this.savings = response.data
        },
    },

    mounted() {
        this.WAITING()

        this.loadCharts()
            .catch(console.error)
            .finally(() => {
                if (this.waiting) this.DISABLED()
            })
    },
}
</script>

<style lang="scss" scoped>
@include empty-list();

.content {
    width: calc(100%);
}

.chart {
    color: #9fa5b1;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 33px;

    .chart-title {
        margin-left: 24px;
    }
}

.card {
    margin-bottom: 32px;
}

.margin-right {
    margin-right: 20px;
}

.item {
    min-width: 75%;
}

.grow {
    flex-grow: 1;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
    display: flex;
}

.flex {
    display: flex;
}

.half-col {
    width: 50%;
}

@media screen and (max-width: 700px) {
    .report {
        margin-bottom: 30px;
    }
    .row {
        width: 100%;
        overflow-x: scroll;

        .chart-box {
            margin-left: 2px;
        }
    }
}
</style>
