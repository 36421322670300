<template>
    <div class="task-card-achievements">
        <div class="item" v-for="(i, index) in checklist" :key="index">
            <div v-if="i.checked" class="checked">
                <i class="fas fa-check"></i>
            </div>

            <div v-else class="icon">{{ index + 1 }}</div>

            <p>{{ i.title }}</p>
        </div>

        <div class="line"></div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { mainStore } from '../../../../store'

const store = mainStore()

const checklist = computed(() => [
    {
        title: 'Realizou diagnóstico financeiro',
        checked: !!store.profiles._id,
    },
    // {
    //     title: 'Possuir capacidade de poupar',
    //     checked: getUserProfileChatMode() === 'reserveCapacity',
    // },
    {
        title: 'Informou orçamento',
        checked: store.user.achievements.includes('budget-tutorial'),
    },
    {
        title: 'Fez tutoriais de cadastro',
        checked: store.user.achievements.includes('end-tutorial'),
    },
    {
        title: 'Definiu um planejamento',
        checked: store.user.achievements.includes('planning-tutorial'),
    },
    {
        title: 'Iniciar reserva emergencial',
        checked: !!store.profiles.hasSavings || store.user.achievements.includes('emergency-reserve'),
    },
    {
        title: 'Investir no objetivo principal',
        checked: store.user.achievements.includes('main-objective'),
    },
])
</script>

<style lang="scss" scoped>
.task-card-achievements {
    height: 80%;
    width: 100%;
    padding: 18px 0 18px 16px;
    @include flex-column-between();
    position: relative;

    .item {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 999;

        .checked,
        .icon {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            vertical-align: middle;
            background-color: #2dc785;
        }

        i {
            color: white;
            font-size: 12px;
        }

        .icon {
            background-color: $btn-blue;
            color: white;
            font-size: 12px;
        }

        p {
            font-size: 14px;
            margin-left: 12px;
        }
    }

    .line {
        height: 80%;
        width: 1px;
        background-color: $light-grey;
        position: absolute;
        left: 25px;
        top: 25px;
    }
}
</style>
