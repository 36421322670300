<template>
    <div class="terms-of-use">
        <iframe :src="termsOfUse" frameborder="0" width="100%" height="100%"></iframe>
    </div>
</template>

<script>
import pdf from '../assets/documents/Termos_e_Condicoes_de_Uso_-_Plataforma_Web.pdf'

export default {
    name: 'TermsOfUse',

    data() {
        return {
            termsOfUse: pdf,
        }
    },
}
</script>

<style lang="scss">
.terms-of-use {
    width: 100vw;
    height: 100vh;
}
</style>
