<template>
    <div>
        <label class="switch">
            <input type="checkbox" v-model="value" @input="updateValue" />
            <span class="slider round"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'ToggleSwitch',
    props: {
        toggle: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            value: this.toggle,
        }
    },
    methods: {
        updateValue(event) {
            this.$emit('toggleEvent', !this.value)
        },
    },
}
</script>

<style lang="scss" scoped>
.switch {
    position: relative;
    display: inline-block;
    height: 14px;
    width: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c0c4c98c;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    top: -3px;
    background-color: $action-btn-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $toggle-slider-color;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.slider.round {
    border-radius: 50px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
