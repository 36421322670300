<template>
    <form @submit.prevent="checkForm" method="post" class="form flex column">
        <div>
            <stacked-input
                class="form-el"
                label="Nome do banco"
                :required="true"
                :errorValidation="error[0]"
                v-model="paymentBank.name"
                name="name"
            />

            <stacked-select
                class="form-el"
                label="Tipo de conta"
                :required="true"
                :errorValidation="error[1]"
                :notTransaction="true"
                :previousSelected="previousAcc"
                :model="model"
                :disabled="editData.externalId !== undefined"
                @change="updateType"
            />

            <stacked-input label="Valor inicial" :isMoney="true" v-model="paymentBank.value" name="value" ref="valor" />
        </div>

        <div>
            <basic-button
                v-if="!editData._id"
                label="Cadastrar Forma de pagamento"
                :loading="disabled"
                type="submit"
                :width="320"
                data-cy="submit-form-payment"
            />

            <basic-button
                data-cy="submit-form-payment"
                v-if="editData._id"
                label="Salvar Alterações"
                :loading="disabled"
                type="submit"
                :width="320"
            />

            <basic-button
                v-if="editData._id && !editData.externalId"
                label="Excluir"
                :reverse="true"
                :width="320"
                @click="deletePayment"
                data-cy="submit-form-payment-delete"
            />
        </div>
    </form>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'
import { mask } from 'vue-the-mask'

import StackedInput from '../../components/inputs/StackedInput.vue'
import StackedSelect from '../../components/inputs/StackedSelect.vue'
import BasicButton from '../buttons/BasicButton.vue'

import util from '../../common/util'
import _date from '@/common/formatDate'
import _request from '@/common/request'

export default {
    name: 'FormPaymentsBank',

    components: {
        StackedInput,
        StackedSelect,
        BasicButton,
    },

    directives: {
        mask,
    },

    props: {
        editData: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            model: ['Conta corrente', 'Poupança'],
            paymentBank: {},
            error: [],
            msg: null,
            previousAcc: '',
        }
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        updateType(value) {
            this.paymentBank.accountType = value
        },

        createPayment() {
            this.paymentBank.type = 'debit'
            let _data = Object.assign({}, this.paymentBank)

            _data.value = util.sanitizeMoney(_data.value)
            this.DISABLED()

            _request
                .post({ collection: 'payments', data: _data })
                .then(async () => {
                    let sameDate = _date.compareDates(this._date.inicio, this._date_selected.inicio)

                    if (sameDate) await _request.fetch([{ collection: 'transactions', queryParams: this._date }])
                    this.closeModal()
                })
                .finally(() => this.DISABLED())
        },

        editPayment() {
            let _data = { ...this.paymentBank }

            _data.value = util.sanitizeMoney(_data.value)
            this.DISABLED()

            _request
                .patch({ collection: 'payments', data: _data, _id: _data._id })
                .then(() => this.closeModal())
                .finally(() => this.DISABLED())
        },

        checkForm: function (e) {
            let error = Array(2).fill(false)
            let errorCount = 0

            if (!this.paymentBank.name) {
                error[0] = true
                errorCount++
            }
            if (!this.paymentBank.accountType) {
                error[1] = true
                errorCount++
            }

            this.error = error

            if (!errorCount && !this.editData._id) this.createPayment()
            else if (!errorCount && this.editData._id) this.editPayment()
        },

        closeModal() {
            this.$emit('closePaymentModalEvent')
        },

        deletePayment() {
            let payload = {
                data: {
                    collection: 'payments',
                    _id: this.editData._id,
                },
                text: 'Tem certeza que deseja excluir esta carteira? Todas as transações e objetivos associadas a ela serão removidos permanentemente.',
                strongText: '',
                creditCardAlert: '',
            }

            this.$root.$emit('confirmationDelete', payload)
        },
    },

    computed: {
        ...mapState(mainStore, ['_date', '_date_selected', 'disabled', 'findInitialBalanceCategory']),
    },

    mounted() {
        if (this.editData.type) {
            this.editData.value = this.editData.value.toFixed(2)
            this.paymentBank = this.editData
            if (this.editData.externalId) this.$refs.valor.read = true
            this.previousAcc = this.paymentBank.accountType
        }
    },
}
</script>

<style lang="scss" scoped>
.form {
    margin: 0 25px 25px 25px;
    position: relative;
    height: 100%;
    justify-content: space-between;
}

.form-el {
    margin-bottom: 10px;
}

.basic-button {
    margin-top: 10px;
    width: 100%;

    &:first-child {
        margin-top: 20px;
    }
}

.column {
    flex-direction: column;
}

.flex {
    display: flex;
}
</style>
