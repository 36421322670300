<template>
    <div class="chart-box" :style="style">
        <div class="row" style="margin: 0; justify-content: space-between">
            <div class="row" style="margin: 0">
                <h1>{{ boxTitle }}</h1>
                <h2>{{ boxSubTitle }}</h2>
            </div>
            <a
                v-if="type === 'simpleColumn' || type === 'dualColumn'"
                href="#"
                class="check"
                :data-tooltip="tooltipText"
            >
                <img src="../../assets/img/help.svg" alt="" />
            </a>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ReportChartBox',

    props: {
        width: {
            type: Number,
        },
        height: {
            type: Number,
        },
        boxTitle: {
            type: String,
            required: true,
        },
        boxSubTitle: {
            type: String,
        },
        chart: String,
        objectiveName: String,
        objAchieved: Number,
        objValue: Number,
        data: [Object, Array],
        chartSeries: Array,
        chartOptions: Object,
        chartProps: {
            type: Object,
            default: () => {},
        },
        type: String,
        marginBottom: Boolean,
        marginRight: Boolean,
        tooltipText: String,
    },

    data() {
        return {
            transform: {
                translateX: 0,
                translateY: 100,
            },
        }
    },

    computed: {
        style() {
            let style = ''
            if (this.marginBottom) {
                style += 'margin-bottom: 24px;'
            }
            if (this.marginRight) style += 'margin-right: 24px;'
            style += 'min-width: ' + this.width + 'px;'
            style += 'height: ' + this.height + 'px;'
            style += 'padding: 16px;'
            return style
        },
    },
}
</script>

<style lang="scss" scoped>
.full {
    width: auto;
}
.half {
    width: calc(50% - 12px);
}
.float-l {
    float: left;
}
.margin-left {
    margin-left: 16px;
}

.row {
    display: flex;
}

.chart-box {
    position: relative;
    @include rounded-box();
    @include box-shadow();
    display: flex;
    height: 100%;
    flex-flow: column;

    h1 {
        padding: 0;
        @include card-title();
        margin-bottom: 18px;
    }
}

h2 {
    color: #b7b8b9;
    font-size: 12px;
    margin-left: 8px;
}

img {
    width: 16px;
    height: 16px;
}

img:hover {
    filter: invert(0.4) sepia(0) saturate(1) hue-rotate(0deg) brightness(0);
}

.check {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    color: #000;
    text-decoration: none;
    z-index: 2;
}
.check:after {
    content: attr(data-tooltip);
    position: absolute;
    top: 16;
    right: 0;
    background-color: #fff;
    border: 1px solid #70707031;
    border-radius: 4px;
    color: #1e3351;
    font-family: 'Metropolis' !important;
    font-weight: 400;
    width: 160px;
    font-size: 10px;
    padding: 4px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity;
}

.check:hover:after {
    opacity: 1;
    pointer-events: auto;
}
</style>
