<template>
    <div class="form-row">
        <div class="grid-container">
            <input type="date" :min="dateRange.min" :max="dateRange.max" v-model="formData.date" />

            <div class="combo-box">
                <select v-model="formData.category" @change="updateName">
                    <option v-for="category in categories" :key="category._id" :value="category._id">
                        {{ category.name }}
                    </option>
                </select>

                <div class="arrow"></div>
            </div>

            <input type="text" v-model="formData.value" v-model.lazy="formData.value" v-money3="money" />

            <!-- <input type="text" v-model="formData.name" /> -->

            <!-- <div class="combo-box">
                <select v-model="formData.payment">
                    <option v-for="payment in payments" :key="payment._id" :value="payment._id">
                        {{ payment.name }}
                    </option>
                </select>

                <div class="arrow"></div>
            </div> -->

            <input type="text" v-model="formData.description" />

            <div class="combo-box">
                <select v-model="formData.recurrentType" @change="updateRecurrency" style="padding-right: 8px">
                    <option :value="undefined" />
                    <option v-for="(item, index) in Object.entries(recurrencyPeriods)" :value="item[1]" :key="index">
                        {{ item[0] }}
                    </option>
                </select>
            </div>

            <input type="number" v-model.number="formData.lastInstallment" @input="updateInstallment" />

            <div class="icon">
                <paid-icon @click="formData.paid = !formData.paid" :transaction-type="paidType" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'

import PaidIcon from '../../../../icons/PaidIcon.vue'
import RecurrencyIcon from '../../../../icons/RecurrencyIcon.vue'

import { mainStore } from '../../../../../store'
import util from '../../../../../common/util'

export default {
    name: 'FormRow',

    components: {
        PaidIcon,
        RecurrencyIcon,
    },

    props: {
        transactionType: {
            type: String,
            required: true,
        },
        categoryType: {
            type: String,
        },
    },

    data() {
        return {
            formData: {
                date: this.$moment().format('YYYY-MM-DD'),
                category: '',
                name: '',
                value: '',
                payment: '',
                description: '',
                paid: false,
                recurrentType: 'monthly',
                recurrentDay: '',
                recurrentIndex: 0,
                recurrent: true,
                lastInstallment: '',
            },

            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
                prefix: util.returnCurrencyPrefix().prefix + ' ',
            },

            recurrencyPeriods: {
                // 1: 'dayly',
                // 7: 'weekly',
                // 15: 'fortnightly',
                30: 'monthly',
                180: 'semiannual',
                365: 'annual',
            },
        }
    },

    computed: {
        ...mapState(mainStore, [
            'filteredCategories',
            'groupedPayments',
            'newGroupedCategories',
            'findById',
            '_date_selected',
            'user',
            'selectedUser',
        ]),

        max() {
            return this.$moment().endOf('M').date()
        },

        dateRange() {
            return {
                max: this.$moment(this._date_selected.inicio).add(11, 'M').endOf('M').format('YYYY-MM-DD'),
                min: this.$moment(this._date_selected.inicio).format('YYYY-MM-DD'),
            }
        },

        paidType() {
            return this.formData.paid ? this.transactionType : undefined
        },

        categories() {
            if (this.transactionType === 'revenues') return this.filteredCategories('revenues', this.selectedUser)

            const groupedCategories = this.newGroupedCategories(true, true, this.selectedUser)

            switch (this.categoryType) {
                case 'essential':
                    return groupedCategories[0].data
                case 'noessential':
                    return groupedCategories[1].data
                case 'debts':
                    return groupedCategories[2].data
                default:
                    return groupedCategories[3].data
            }
        },

        payments() {
            let list = this.groupedPayments('revenues', true, this.selectedUser)

            return list.reduce((prevValue, el) => {
                return [...prevValue, ...el.data]
            }, [])
        },
    },

    methods: {
        updateName(e) {
            const category = this.categories.find((c) => c._id === e.target.value)
            if (category) this.formData.name = category.name
        },

        updateDay(e) {
            let day = Number(e.target.value)

            if (day < 1 || isNaN(day)) {
                this.formData.day = 1
                return
            }

            if (day > this.max) {
                this.formData.day = this.max
                return
            }

            this.formData.day = day
        },

        updateRecurrency() {
            if (this.formData.lastInstallment) this.formData.lastInstallment = ''

            if (!this.formData.recurrentType) {
                this.formData.recurrent = false
                return
            }

            this.formData.recurrent = true
        },

        updateInstallment() {
            if (this.formData.recurrentType) this.formData.recurrentType = undefined

            if (!this.formData.lastInstallment) {
                this.formData.recurrent = false
                return
            }

            this.formData.recurrent = true

            if (this.formData.lastInstallment > 999) this.formData.lastInstallment = 999
        },
    },

    watch: {
        formData: {
            deep: true,
            handler() {
                let payment = ''

                if (this.formData.payment) {
                    const { _id, type } = this.findById('payments', this.formData.payment)
                    payment = { _id, type }
                }

                this.formData.transactionType = this.transactionType

                this.$emit('update-transaction', { ...this.formData, payment })
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.form-row {
    margin-bottom: 8px;
    .grid-container {
        display: grid;
        grid-template-columns: 140px 2fr 150px 2fr 50px 50px 50px;
        column-gap: 8px;

        input,
        select {
            height: 40px;
            border-radius: 4px;
            border: 1px solid $medium-grey;
            padding: 8px;
            color: $darker-blue;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }

        .combo-box {
            position: relative;
            width: 100%;

            select {
                width: 100%;
                padding-right: 24px;
            }

            .arrow {
                position: absolute;
                top: 45%;
                right: 12px;
            }
        }

        .icon {
            color: #b7becc;
            cursor: pointer;
            @include flex-center();

            &.active.expenses {
                color: $expense-red;
            }

            &.active.revenues {
                color: $revenue-green;
            }
        }
    }
}
</style>
