<template>
    <div class="chart-container">
        <router-link to="/objetivos" class="more">Ver mais</router-link>

        <p class="obj-text">
            Você está mais perto de completar seu objetivo <strong>{{ dataset.name }}</strong>
        </p>

        <apexchart type="radialBar" width="253" height="200" :options="chartOptions" :series="series" />

        <div class="obj-info">
            <p>Você já alcançou</p>
            <p class="actual-value">{{ formatNum(dataset.paidValue) }}</p>
            <p>do total de</p>
            <p class="total-value">{{ formatNum(dataset.totalValue) }}</p>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

import util from '../../common/util'

export default {
    name: 'ObjectivesChart',
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        objectiveName: String,
        objAchieved: Number,
        objValue: Number,
        dataset: [Object, Array],
    },
    data() {
        return {
            chartOptions: {
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        },
                    },
                },
                labels: [],
                colors: ['#2DC785'],
                stroke: {
                    lineCap: 'round',
                },
            },
            series: [],
        }
    },

    methods: {
        formatNum(number) {
            return util.formatNumber(number)
        },
    },

    mounted() {
        let percent = (this.dataset.paidValue / this.dataset.totalValue) * 100
        this.series.push(percent)
        this.chartOptions.labels.push(this.dataset.name)
    },
}
</script>

<style lang="scss" scoped>
.float-l {
    float: left;
}

.chart-container {
    padding: 0 24px 24px;

    .more {
        position: absolute;
        top: -32px;
        right: 24px;
        color: #3f63ed;
        font-size: 10px;
        text-transform: uppercase;
        text-decoration: none;
    }

    .obj-text {
        font-size: 14px;
        color: $medium-grey;
        font-weight: 300;
        line-height: 1.3;
    }

    .obj-info {
        margin-top: -16px;
        color: $dark-blue;
        font-size: 14px;
        line-height: 1.4;
        text-align: center;

        p {
            font-weight: 300;
        }

        .actual-value {
            color: $green;
            font-size: 18px;
        }

        .total-value {
            color: $green;
            font-size: 16px;
        }
    }
}
</style>
