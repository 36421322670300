import general from './getters.general'
import payments from './getters.payments'
import categories from './getters.categories'
import transactions from './getters.transactions'
import balances from './getters.balances'
import charts from './getters.charts'
import invoices from './getters.invoices'
import subscriptions from './getters.subscriptions'
import budgets from './getters.budgets'
import bankaccesses from './getters.bankaccesses'
import families from './getters.families'
import linkedTransactions from './getters.linked-transactions'
import experts from './getters.experts'
import miscellaneous from './getters.miscellaneous'
import trails from './getters.trails'
import tasks from './getters.tasks'
import users from './getters.users'

export default {
    ...general,
    ...payments,
    ...categories,
    ...transactions,
    ...balances,
    ...charts,
    ...invoices,
    ...subscriptions,
    ...budgets,
    ...bankaccesses,
    ...families,
    ...linkedTransactions,
    ...experts,
    ...miscellaneous,
    ...trails,
    ...tasks,
    ...users,
}
