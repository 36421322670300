import { createApp, configureCompat } from 'vue'

import { vuetify } from '@/plugins/vuetify'
import moment from 'moment'
import 'moment/locale/pt-br'
import SimpleVueValidation from 'simple-vue-validator'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTheMask from 'vue-the-mask'
import { createPinia, setMapStoreSuffix } from 'pinia'
import money from 'v-money3'
import { createManager } from '@vue-youtube/core'

import App from './App.vue'

import router from './router/router'
import { initializeFirebaseApp } from './services/firebase.service'

import variables from './variables/index'

const app = createApp(App)

app.config.globalProperties.$variables = variables
app.config.globalProperties.$firebase = initializeFirebaseApp()
app.config.globalProperties.$moment = moment

configureCompat({
    CONFIG_PRODUCTION_TIP: false,
    WATCH_ARRAY: false,
})

app.use(VueTheMask)
app.use(SimpleVueValidation)
app.use(VueSweetalert2)

const manager = createManager()
app.use(manager)

const pinia = createPinia()
app.use(pinia)
setMapStoreSuffix('')

app.use(router)

app.use(vuetify)

app.use(money)

app.directive('focus', {
    // Quando o elemento vinculado é inserido no DOM...
    inserted: function (el, binding) {
        if (binding.value) el.focus()
    },
})

app.mount('#app')
