<template>
    <modal-frame
        class="distribution-modal"
        title="lançamentos financeiros"
        width="75vw"
        :extBtnClasses="['flex', step < 2 ? 'right' : '']"
        :close-btn="true"
        @close-modal="$emit('close-modal')"
    >
        <template v-if="!step" #content>
            <video controls>
                <source src="@/assets/videos/presentation-video.mp4" type="video/mp4" />
                Seu navegador não suporta este formato de vídeo
            </video>
        </template>

        <template v-else #content>
            <distribution-modal-rule :step="step" />

            <step-messages :step="step" @show-tutorial="step = 0" />

            <div v-show="step < 6">
                <form-table
                    v-for="n in 5"
                    v-show="n === step"
                    :key="n"
                    :step="step"
                    @update-transaction="updateTransaction"
                />
            </div>

            <div class="img-container" v-show="step > 5">
                <img src="@/assets/img/success-onboarding.png" alt="success-onboarding" />
            </div>
        </template>

        <template #btn-container>
            <basic-button v-if="step > 1" v-bind="btnProps" label="Anterior" @click="step--" :disabled="disabled" />

            <basic-button v-if="step < 6" v-bind="btnProps" :label="midBtnLabel" @click="step++" />

            <basic-button v-else v-bind="btnProps" label="Fechar" @click="endOnboarding" :disabled="disabled" />
        </template>
    </modal-frame>
</template>

<script>
import { mapState } from 'pinia'

import ModalFrame from '../../../../containers/ModalFrame.vue'
import DistributionModalRule from './components/DistributionModalRule.vue'
import FormTable from './components/FormTable.vue'
import StepMessages from './components/StepMessages.vue'
import BasicButton from '../../../buttons/BasicButton.vue'

import { mainStore } from '../../../../store'
import util from '../../../../common/util'

export default {
    name: 'DistribuitionModal',

    components: {
        ModalFrame,
        DistributionModalRule,
        FormTable,
        StepMessages,
        BasicButton,
    },

    data() {
        return {
            step: 0,
            transactions: [],

            btnProps: {
                width: 200,
                height: 40,
                bgColor: '#fa7d20',
                textTransform: 'capitalize',
                fontWeight: 'semi-bold',
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['disabled', 'user', '_date_selected', 'findMoney', 'selectedUser']),

        midBtnLabel() {
            if (!this.step) return 'Iniciar'
            else if (this.step < 5) return 'Próxima'
            else return 'Finalizar'
        },
    },

    methods: {
        updateTransaction(transaction) {
            const index = this.transactions.findIndex((t) => t.id === transaction.id)

            if (index > -1) this.transactions[index] = transaction
            else this.transactions.push(transaction)
        },

        endOnboarding() {
            const payload = {
                debit: [],
                credit: [],
                user: this.selectedUser,
                date: new Date(this._date_selected.inicio).toISOString(),
            }

            this.transactions.forEach((transaction) => {
                delete transaction.id

                Object.keys(transaction).forEach((key) => {
                    if (!transaction[key]) delete transaction[key]
                })

                transaction.value = Number(util.sanitizeMoney(transaction.value))

                if (transaction.category && transaction.value && transaction.date) {
                    if (transaction.lastInstallment) transaction.value *= transaction.lastInstallment
                    transaction.date = this.$moment(transaction.date).toISOString()
                    transaction.user = this.selectedUser
                    transaction.payment = this.findMoney(this.selectedUser)
                    transaction.paid = !!transaction.paid

                    if (transaction.transactionType === 'expenses') transaction.value *= -1

                    payload.debit.push(transaction)
                }
            })

            if (!payload.debit.length && !payload.credit.length) {
                this.$emit('close-modal')
                return
            }

            this.$emit('save-planning', payload)
        },
    },

    mounted() {
        if (this.user.skipTutorial) this.step += 1
    },
}
</script>

<style lang="scss" scoped>
.distribution-modal {
    .img-container {
        display: flex;
        justify-content: center;
        margin-bottom: 48px;

        img {
            width: 280px;
        }
    }

    video {
        width: 50vw;
        display: flex;
        margin: 0 auto 24px;
    }
}
</style>
