<template>
    <div class="transactions-modal">
        <form v-show="!confirmation" @submit.prevent.self="checkForm" class="container modal">
            <header class="item head" :class="[type]">
                <h1>{{ modalTitle }}</h1>

                <button data-cy="close-transactions-modal" class="close" type="button" @click.stop="closeModal">
                    <ion-icon name="close"></ion-icon>
                </button>
            </header>

            <div>
                <div v-if="!editData._id" class="tab-menu" :class="[type]">
                    <label
                        class="item label tab-label"
                        @click="changeTransactionType('despesa')"
                        :class="[{ active: isActive('despesa') }, type]"
                        data-cy="change-transaction-type-expense"
                    >
                        <div></div>
                        <div>Despesa</div>
                    </label>

                    <label
                        class="item label tab-label"
                        @click="changeTransactionType('receita')"
                        :class="[{ active: isActive('receita') }, type]"
                        data-cy="change-transaction-type-revenue"
                    >
                        <div></div>
                        <div>Receita</div>
                    </label>

                    <!-- <label class="item label tab-label"
                        @click="changeTransactionType('investimento')"
                        :class="{ active: isActive('investimento')}">
                        Investimento
                    </label> -->
                </div>

                <div class="modal-body">
                    <!-- VALOR -->
                    <div class="container">
                        <stacked-input
                            label="Valor"
                            :errorValidation="error[0]"
                            :errorMsg="errorMsg"
                            focus
                            textAlign="right"
                            :required="true"
                            :font="24"
                            :readonly="transaction.externalId !== undefined"
                            v-model="transaction.value"
                            name="value"
                            :is-money="true"
                        />
                    </div>

                    <div class="container">
                        <!-- NOME -->
                        <stacked-input
                            :label="`Descrição ${type !== 'investimento' ? `da ${type}` : `do ${type}`}`"
                            :errorValidation="error[1]"
                            ph="Insira aqui seu texto"
                            :required="true"
                            v-model="transaction.name"
                            name="name"
                        />

                        <!-- CATEGORIA -->
                        <stacked-select
                            v-show="showForm.categories"
                            label="Categoria"
                            ref="categories"
                            :model="categories"
                            :isGroupSelect="type === 'despesa'"
                            :required="true"
                            :previousSelected="previousCategory"
                            :errorValidation="error[2]"
                            @change="updateCategory"
                            data-cy="select-category"
                        />

                        <div class="linha">
                            <!-- DATA -->
                            <stacked-input
                                type="date"
                                :label="labelData"
                                ref="date"
                                :required="true"
                                :errorValidation="error[3]"
                                :readonly="transaction.externalId !== undefined"
                                v-model="transaction.date"
                                name="date"
                            />

                            <!-- PAGO -->
                            <div v-if="!editData._id" class="check-box">
                                <input
                                    v-model="transaction.paid"
                                    class="icon-check"
                                    :disabled="disabledPaidButton"
                                    type="checkbox"
                                    name="paid"
                                />

                                <a class="icon ion-md-checkmark" :class="[transaction.paid ? type + '-paid' : '']"
                                    ><ion-icon name="checkmark-outline"></ion-icon
                                ></a>

                                <label for="paid" style="position: absolute; top: 35px">Paga</label>
                            </div>
                        </div>

                        <!-- PAGAMENTO -->
                        <stacked-select
                            ref="payment"
                            :disabled="disableSelect || transaction.externalId !== undefined"
                            :label="type !== 'receita' ? 'Pago com' : 'Receber com'"
                            :model="pagamentos"
                            :isGroupSelect="true"
                            :required="true"
                            :previousSelected="previousPayment"
                            :errorValidation="error[4]"
                            :errorMsg="paymentErrorMsg"
                            @change="updatePayment"
                            data-cy="select-payment"
                        />

                        <!-- OBSERVAÇÔES -->
                        <div class="linha">
                            <stacked-input
                                class="full-width"
                                label="Observações"
                                :errorValidation="error[5]"
                                :readonly="transaction.externalId !== undefined"
                                v-model="transaction.description"
                                name="description"
                            />
                        </div>

                        <!-- RECORRENCIA/PARCELAMENTO -->
                        <div v-if="showForm.recurrencyOptions && !transaction.externalId">
                            <div class="show-button">
                                <button class="icon-btn" type="button" @click="showRecurrency">
                                    <i
                                        class="icon"
                                        :class="[
                                            { 'text-receita': type === 'receita' && recurrent },
                                            { 'text-despesa': type === 'despesa' && recurrent },
                                        ]"
                                        ><ion-icon name="refresh-outline"></ion-icon>
                                    </i>
                                    Repetir
                                </button>

                                <div class="repeat-select" v-show="recurrent">
                                    <select
                                        class="custom-select"
                                        v-model="transaction.recurrentType"
                                        @change="updateRecurrent"
                                    >
                                        <option v-for="(period, index) in periods" :key="index" :value="period.value">
                                            {{ period.title }}
                                        </option>
                                    </select>

                                    <div class="arrow"></div>
                                </div>
                            </div>

                            <div class="show-button">
                                <button class="icon-btn" type="button" @click="showInstallment">
                                    <i
                                        class="icon fas fa-divide"
                                        :class="[
                                            { 'text-receita': type === 'receita' && split },
                                            { 'text-despesa': type === 'despesa' && split },
                                        ]"
                                    ></i>
                                    Parcelar
                                </button>

                                <div class="repeat-select" v-show="split">
                                    <select
                                        class="custom-select"
                                        v-model="transaction.lastInstallment"
                                        @change="updateInstallment"
                                    >
                                        <option v-for="(n, index) in 360" :value="n" :key="index">{{ n + 'x' }}</option>
                                    </select>

                                    <div class="arrow"></div>

                                    <span v-if="installmentValue">
                                        de
                                        <strong>
                                            {{ formatNum(installmentValue) }}
                                        </strong>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div v-if="transaction.externalId" class="linha">
                            <div @click="saveOnCalendar = !saveOnCalendar" class="calendar">
                                <calendar-icon :fill="!saveOnCalendar ? undefined : btnBgColor" />

                                <p v-if="!saveOnCalendar">Incluir no calendário de pagamentos</p>
                                <p class="active" v-else>{{ `${type} mensal` }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer class="modal-footer">
                <basic-button
                    data-cy="submit-modal"
                    :label="labelBtn"
                    type="submit"
                    :loading="disabled"
                    :bgColor="btnBgColor"
                    :width="300"
                />
            </footer>
        </form>

        <!-- MODAL DE CADASTRO -->
        <div class="overlay" v-show="confirmation">
            <v-slide-y-reverse-transition>
                <created-transaction v-bind="confirmationData" @confirmEvent="confirm">
                    <basic-button
                        v-if="!confirmationData.error"
                        label="Cadastrar mais"
                        width="100%"
                        @click="createAnother"
                        data-cy="create-another-modal"
                    />

                    <basic-button
                        :style="confirmationData.error ? {} : { marginTop: '10px' }"
                        label="Fechar"
                        :bgColor="confirmationData.error ? '#ff113a' : undefined"
                        :reverse="!confirmationData.error"
                        width="100%"
                        @click="confirm"
                        data-cy="close-confirmation-modal"
                    />
                </created-transaction>
            </v-slide-y-reverse-transition>
        </div>

        <!-- MODAL DE RENOMEIO DE TRANSAÇÕES AUTOMÁTICAS -->
        <confirmation-modal-generic
            v-if="confirmationTranslate.show"
            v-bind="confirmationTranslate"
            @close-modal="confirmationTranslate.show = false"
        >
            <template #buttons>
                <basic-button label="Sim" width="100%" @click="() => patchExternalTransaction(true)" />

                <basic-button
                    style="margin-top: 10px"
                    label="Não"
                    :reverse="true"
                    width="100%"
                    @click="() => patchExternalTransaction()"
                />
            </template>
        </confirmation-modal-generic>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'

import StackedInput from '../../components/inputs/StackedInput.vue'
import StackedSelect from '../../components/inputs/StackedSelect.vue'
import CreatedTransaction from '../modals/confirmation-modals/ConfirmationCreateModal.vue'
import ModalTransition from '@/components/transitions/ModalTransition.vue'
import BasicButton from '../buttons/BasicButton.vue'
import Dropdown from '../inputs/Dropdown.vue'
import ConfirmationModalGeneric from './confirmation-modals/ConfirmationModalGeneric.vue'
import CalendarIcon from '../icons/CalendarIcon.vue'

import { errorHandler } from '../../common/errorHandler'
import formatDate from '../../common/formatDate'
import date from '../../common/formatDate'
import util from '../../common/util'
import _request from '@/common/request'

const initialForm = { categories: true, recurrencyOptions: true }

export default {
    name: 'TransactionsModal',

    components: {
        StackedInput,
        StackedSelect,
        CreatedTransaction,
        ModalTransition,
        BasicButton,
        Dropdown,
        ConfirmationModalGeneric,
        CalendarIcon,
    },

    props: {
        modalData: String,
        editData: Object,
    },

    data() {
        return {
            disabledPaidButton: false,
            transaction: {
                paid: false,
            },
            periods: [
                { title: 'anual', value: 'annual' },
                { title: 'semestral', value: 'semiannual' },
                { title: 'mensalmente', value: 'monthly' },
                { title: 'quinzenalmente', value: 'fortnightly' },
                { title: 'semanalmente', value: 'weekly' },
                { title: 'diariamente', value: 'daily' },
            ],
            categories: [],
            pagamentos: [],
            payload: {},
            type: null,
            recurrent: false,
            split: false,
            show: false,
            confirmation: false,
            edit: false,
            error: [],
            errorMsg: 'VALOR INVÁLIDO',
            paymentErrorMsg: undefined,
            previousCategory: null,
            previousPayment: null,
            previousValue: 0,
            readonly: false,
            confirmationData: {
                text: '',
                error: false,
                title: '',
            },
            showForm: { ...initialForm },

            confirmationTranslate: {
                show: false,
                message: 'Deseja aplicar esta alteração a todos os lançamentos com mesmo nome?',
            },

            saveOnCalendar: false,
        }
    },

    computed: {
        ...mapState(mainStore, [
            '_date',
            '_date_selected',
            'payments',
            'disabled',
            'user',
            'findById',
            'filteredCategories',
            'getInvoiceByPaymentId',
            'groupedPayments',
            'getRecurrencyInvoiceSum',
            'groupedCategoriesByType',
            'findReversalCategory',
            'newGroupedCategories',
        ]),

        modalTitle() {
            return !this.editData._id ? `Nova ${this.type}` : 'Editar ' + this.type
        },

        installmentValue() {
            let value = util.sanitizeMoney(this.transaction.value || '0')

            return value / (this.transaction.lastInstallment || 1)
        },

        sameDate() {
            return date.compareDates(this._date.inicio, this._date_selected.inicio)
        },

        labelData() {
            return 'data ' + (this.type !== 'investimento' ? 'da ' + this.type : 'do ' + this.type)
        },

        labelBtn() {
            return !this.editData._id ? `Salvar nova ${this.type}` : `Editar ${this.type}`
        },

        // desabilita o select na edição de despesas do cartão
        disableSelect() {
            if (this.editData._id) return this.isCreditCard(this.editData.payment)
            else return false
        },

        btnBgColor() {
            switch (this.type) {
                case 'investimento':
                    return '#63b9f2'
                case 'despesa':
                    return process.env.VUE_APP_EXPENSES
                default:
                    return process.env.VUE_APP_REVENUES
            }
        },

        hasExternalId() {
            return Object.keys(this.editData).length ? !this.editData.externalId : true
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED', 'SET_MISC_DATA']),

        formatNum(number) {
            return util.formatNumber(number)
        },

        changeTransactionType(type) {
            this.type = type
            Object.assign(this.showForm, initialForm)
            this.transaction.category =
                this.transaction.payment =
                this.$refs.categories.selected =
                this.$refs.payment.selected =
                    undefined
            this.getTransactionType()
        },

        isActive(type) {
            return type === this.type
        },

        isPaid() {
            this.transaction.paid = !this.transaction.paid
        },

        blurred(e) {
            this.errorMsg = 'VALOR INVÁLIDO'
            if (
                e.target.value ===
                util.returnCurrencyPrefix().prefix + '0' + util.returnCurrencyPrefix().decimal + '00'
            )
                document.getElementById('value').style.display = 'block'
            else document.getElementById('value').style.display = 'none'
        },

        createTransaction() {
            let isCreditCard = this.isCreditCard(this.transaction.payment)
            let _transaction = Object.assign({}, this.transaction)

            if (!_transaction.recurrentType && !_transaction.lastInstallment) {
                _transaction.recurrent = false
            }

            _transaction.transactionType = this.getTransactionType()
            _transaction.value = this.checkValue()

            if (isCreditCard) this.createInvoiceTransaction(_transaction)
            else this.createNormalTransaction(_transaction)
        },

        createNormalTransaction(transaction) {
            this.DISABLED()
            this.confirmationData.title = 'Nova movimentação'
            this.confirmationData.error = false

            _request
                .post({ collection: 'transactions', data: transaction })
                .then(async (resp) => {
                    if (resp.recurrent) {
                        await _request.fetch([{ collection: 'transactions', queryParams: this._date_selected }])
                    }

                    if (resp.paid) await _request.getById({ collection: 'payments', _id: resp.payment._id })

                    await _request.fetch([
                        { collection: 'balances', queryParams: this._date_selected },
                        { collection: 'balancesChart', queryParams: this._date_selected },
                    ])
                })
                .then(() => {
                    this.confirmationData.text = `${this.type} cadastrada com sucesso`
                    this.confirmation = true
                })
                .catch((e) => {
                    console.error(e)
                    this.confirmationData.text = `Não foi possível cadastrar sua movimentação`
                    this.confirmationData.error = true
                    this.confirmation = true
                })
                .finally(() => this.DISABLED())
        },

        createInvoiceTransaction(transaction) {
            this.DISABLED()
            this.confirmationData.title = 'Nova movimentação'
            this.confirmationData.error = false

            _request
                .post({ collection: 'invoices', data: transaction })
                .then(async (resp) => {
                    _request.getById({ collection: 'payments', _id: resp.payment._id })
                    await _request.changeMonth(this._date_selected)
                })
                .then(() => {
                    this.confirmationData.text = `${this.type} cadastrada com sucesso`
                    this.confirmation = true
                })
                .catch((e) => {
                    console.error(e)
                    this.confirmationData.text = `Não foi possível cadastrar sua movimentação`
                    this.confirmationData.error = true
                    this.confirmation = true
                })
                .finally(() => this.DISABLED())
        },

        openConfirmationEditModal(payload) {
            let modal = {
                data: payload,
                text: 'Tem certeza que deseja editar esta(s) ' + this.type.concat('s'),
                recurrent: true,
                isEdit: true,
            }

            this.$emit('editRecurrentTransaction', modal)
        },

        editTransaction(translation) {
            const paymentId = this.transaction.payment._id || this.transaction.payment
            const isCreditCard = this.isCreditCard(paymentId)
            let _invoiceId, _installment, _id, collection, sub_id
            let data = { ...this.transaction }

            // v-money gera uma uma string, portanto sempre tem que se chamar o método "checkValue" para converte devolta para number
            data.value = this.checkValue()

            let payload = { data }

            if (isCreditCard) {
                if (this.type === 'receita') data.category = this.editData.category

                collection = 'invoices'
                sub_id = data._id
                ;(_id = _invoiceId = this.getInvoiceByPaymentId(paymentId)._id),
                    (_installment = this.transaction.lastInstallment ? true : false)
            } else {
                collection = 'transactions'
                _id = data._id
            }

            if (this.transaction.recurrent && this.editData.recurrent) {
                payload._recId = this.transaction.recurrentTransactionId
                payload = Object.assign(payload, { _invoiceId, _installment, _id, sub_id, collection })

                this.openConfirmationEditModal(payload)
            } else {
                let queryParams = translation ? { markedAsTranslation: true } : undefined
                this.patchTransaction({ collection, data, _id, sub_id, queryParams })
            }
        },

        async saveLinkedTransaction(transaction) {
            try {
                const { name, category, payment, originalName, value, user, date, transactionType } = transaction
                const bankAccess = this.findById('payments', payment._id || payment).bankAccess

                const data = {
                    transaction: { name, category, payment, transactionType },
                    originalName: originalName || name,
                    user,
                    value: value,
                    bankAccess,
                    day: formatDate.getDay(date),
                }

                await _request.post({
                    collection: 'linkedtransactions',
                    data,
                })
            } catch (e) {
                throw e
            }
        },

        patchTransaction(payload) {
            this.DISABLED()

            _request
                .patch(payload)
                .then(async (transaction) => {
                    this.updateReferencedData(transaction)

                    if (this.saveOnCalendar) {
                        await this.saveLinkedTransaction(transaction)
                        this.$emit('show-snack-bar', {
                            message: 'Item alterado com sucesso e incluído no calendário',
                        })
                    } else {
                        this.$emit('show-snack-bar', {
                            message: 'Item alterado com sucesso',
                        })
                    }

                    this.closeModal()
                })
                .catch((e) => {
                    console.error(e)
                    this.confirmationData.title = 'Editar movimentação'
                    this.confirmationData.text = errorHandler(e) || `Não foi possível editar sua ${this.type}`
                    this.confirmationData.error = true
                    this.confirmation = true
                })
                .finally(() => {
                    this.DISABLED()
                })
        },

        patchExternalTransaction(translate) {
            JSON.parse(JSON.stringify(this.transaction))
            delete this.transaction['externalId']

            this.confirmationTranslate.show = false
            this.editTransaction(translate)
        },

        updateReferencedData(transaction) {
            if (!transaction.externalId) {
                _request.getById({ collection: 'payments', _id: transaction.payment._id })
                _request.changeMonth(this._date_selected).then(() => this.$root.$emit('attBalancosMensais')) // escutado pela view Transactions
            }
        },

        externalTransactionCheckout() {
            if (!!this.transaction.externalId) {
                const category = this.transaction.category._id || this.transaction.category
                const name = this.transaction.name

                return category !== this.editData.category._id || name !== this.editData.name
            }

            return false
        },

        confirm() {
            this.confirmation = false
            this.closeModal()
        },

        createAnother() {
            this.confirmation = this.recurrent = this.split = false
            this.transaction = { paid: false, date: date.inputFormatDate(new Date()) }
            this.$refs.categories.selected = this.$refs.payment.selected = null
            Object.assign(this.showForm, initialForm)
        },

        updateRecurrent() {
            this.transaction.recurrent = true
            this.split = false
            delete this.transaction['lastInstallment']
        },

        updateInstallment() {
            this.transaction.recurrent = true
            this.recurrent = false
            delete this.transaction['recurrentType']
        },

        updateCategory(value) {
            this.transaction.category = value
        },

        updatePayment(value) {
            if (value) {
                if (this.isCreditCard(value)) {
                    this.manageReversal()
                    this.transaction.paid = true
                    this.disabledPaidButton = true
                } else {
                    this.showForm.categories = this.showForm.recurrencyOptions = true
                    this.disabledPaidButton = false
                }
            }

            this.transaction.payment = value
        },

        manageReversal() {
            const isRevenue = this.type === 'receita'
            this.showForm.categories = this.showForm.recurrencyOptions = !isRevenue

            if (isRevenue) {
                delete this.transaction['recurrentType']
                delete this.transaction['lastInstallment']
                this.transaction.recurrent = false
            }
        },

        checkValue() {
            let value = Number(util.sanitizeMoney(this.transaction.value || '0'))

            if (this.getTransactionType() === 'expenses') value *= -1

            return value
        },

        isCreditCard(payment) {
            if (payment) {
                let id = payment._id || payment
                return this.findById('payments', id).type === 'credit'
            }
            return false
        },

        showRecurrency() {
            this.recurrent = !this.recurrent
        },

        recurrentType() {
            if (this.transaction.recurrentType) {
                return this.periods.find((p) => p.value == this.transaction.recurrentType).title
            }
            return ''
        },

        showInstallment() {
            this.split = !this.split
        },

        closeModal() {
            this.SET_MISC_DATA({ attr: 'hideCategoriesModal', value: false })
            this.$emit('modalClosedEvent')
        },

        getTransactionType() {
            let groupeType = ''
            let userId = this.editData.user ? this.editData.user._id : this.user._id

            switch (this.type) {
                case 'despesa':
                    groupeType = 'expenses'
                    this.categories = this.newGroupedCategories(true, true, userId)
                    break
                case 'receita':
                    groupeType = 'revenues'
                    this.categories = this.filteredCategories(groupeType, userId)
                    break
                case 'investimento':
                    groupeType = 'investiments'
                    this.categories = this.filteredCategories(groupeType, userId)
                    break
                default:
                    break
            }

            this.pagamentos = this.groupedPayments('all', this.hasExternalId, userId)

            if (this.editData._id && this.type === 'despesa' && !this.isCreditCard(this.editData.payment._id))
                this.pagamentos = this.pagamentos.slice(0, 2)

            return groupeType
        },

        checkForm: function (e) {
            let error = Array(6).fill(false)
            let errorCount = 0
            const payment = this.findById('payments', this.transaction.payment) || {}
            const value = Math.abs(this.checkValue())

            if (!value) {
                error[0] = true
                errorCount++
                this.errorMsg = 'VALOR INVÁLIDO'
            }

            if (!this.transaction.name) {
                error[1] = true
                errorCount++
            }
            if (!this.transaction.category) {
                // Verifica se é um estorno no cartão
                if (!(payment.type === 'credit' && this.type === 'receita')) {
                    error[2] = true
                    errorCount++
                }
            }

            if (!this.transaction.date) {
                error[3] = true
                errorCount++
            }

            if (!this.transaction.payment) {
                error[4] = true
                errorCount++
                this.paymentErrorMsg = undefined
            }

            this.error = error

            if (!errorCount && !this.editData._id) this.createTransaction()

            if (!errorCount && this.editData._id) {
                if (this.externalTransactionCheckout()) {
                    this.confirmationTranslate.show = true
                    return
                }

                this.editTransaction()
            }
        },

        addEventListener() {
            this.$root.$on('closeTransactionsModal', () => {
                this.closeModal()
            })
        },
    },

    beforeUnmount() {
        this.$root.$off('closeTransactionsModal')
        this.$root.$off('patch_payment')
    },

    created() {
        this.addEventListener()
    },

    mounted() {
        if (this.editData._id) {
            this.type = util.translateTransaction(this.editData.transactionType)
            this.previousValue = this.editData.value
            this.transaction = { ...this.editData }
            this.transaction.value = Math.abs(this.previousValue).toFixed(2)
            this.transaction.payment = this.transaction.payment._id
            this.previousCategory = this.transaction.category
            this.previousPayment = this.findById('payments', this.transaction.payment._id || this.transaction.payment)
            if (this.isCreditCard(this.editData.payment)) this.manageReversal()

            if (this.transaction.recurrent) {
                this.$refs.date.read = true
                this.showForm.recurrencyOptions = false
            }
        } else {
            this.type = this.modalData
            this.transaction.date = date.inputFormatDate(new Date())
        }

        this.getTransactionType()
    },
}
</script>

<style lang="scss" scoped>
@include tab-menu();
@include custom-scroll();

@import '../../assets/scss/transactions-modal.scss';
</style>
