<template>
    <div class="premium-bills">
        <h2>Últimos Pagamentos</h2>

        <div v-if="loading">
            <div v-for="(n, index) in 4" :key="index" class="bills-list">
                <div>
                    <v-skeleton-loader class="gutter" type="text" />
                </div>

                <div>
                    <v-skeleton-loader class="gutter" type="text" />
                </div>

                <div>
                    <v-skeleton-loader :width="randomWidth(400)" type="text" />
                </div>
            </div>
        </div>

        <div v-else class="bills-list" v-for="(bill, index) in bills" :key="index">
            <div>
                <span>{{ parseDate(bill.date) }}</span>
            </div>

            <div :class="[setClass(bill.parseStatus())]">
                <span>{{ bill.value }}</span>
                <ion-icon :name="getIcon(bill.parseStatus())"></ion-icon>
            </div>

            <div>
                <span>{{ bill.getMessage() }}</span>
            </div>

            <div>
                <a :href="bill.invoiceLink" target="_blank">
                    <i class="fas fa-link"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'

import IuguInvoice from '../../common/models/IuguInvoice.model'

import apiService from '../../services/api.service'
import util from '@/common/util'
import { environment } from '../../common/environment'

export default {
    name: 'PremiumBills',

    data() {
        return {
            bills: [],

            loading: true,
        }
    },

    computed: {
        ...mapState(mainStore, ['subscriptions']),

        getSubscription() {
            return this.subscriptions[0]
        },
    },

    watch: {
        getSubscription: {
            immediate: true,
            deep: true,
            async handler(s) {
                if (s) {
                    if (s.subscriptionId) {
                        try {
                            let bills =
                                (await apiService.get(`${environment.API_URL}subscriptions/${s._id}/invoices`)).data ||
                                []

                            bills = bills.map((bill) => {
                                return new IuguInvoice(bill)
                            })

                            //  Verificando se tem pagamento pendente
                            const pendingInvoice = !!bills.find((bill) => bill.parseStatus() === 'pending')
                            this.SET_MISC_DATA({ attr: 'hasPendingInvoices', value: pendingInvoice })

                            this.bills = bills
                        } catch (e) {
                            console.error(e)
                            this.SET_MISC_DATA({ attr: 'hasPendingInvoices', value: false })
                        } finally {
                            this.loading = false
                        }
                    } else {
                        this.loading = false
                        this.SET_MISC_DATA({ attr: 'hasPendingInvoices', value: false })
                    }
                }
            },
        },
    },

    methods: {
        ...mapActions(mainStore, ['SET_MISC_DATA']),

        parseDate: util.sanitizeDate,

        getIcon(status) {
            return status === 'paid' ? 'checkmark-circle' : status === 'pending' ? 'time-outline' : 'alert-circle'
        },

        setClass(status) {
            return status === 'paid' ? 'blue' : status === 'pending' ? 'yellow' : ''
        },

        randomWidth(base) {
            return util.randomWidth(base)
        },
    },
}
</script>

<style lang="scss" scoped>
.premium-bills {
    width: 900px;

    h2 {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0;
        margin-bottom: 20px;
        padding-bottom: 9px;
        border-bottom: 2px solid $blueish-gray;
        color: $blueish-gray;
    }

    .bills-list {
        display: grid;
        grid-template-columns: 0.8fr 0.9fr 2.7fr 0.5fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: 'date value message link';
        height: 60px;
        box-shadow: 0px 0px 7px #0000001a;
        margin-bottom: 20px;

        .gutter {
            padding: 0 10px;
        }

        & > div {
            font-size: 14px;
            color: $darker-blue;
            align-self: center;
            padding: 0 16px;

            &:nth-child(2) {
                font-weight: bold;
                color: #dc5151;

                &.blue {
                    color: #2691d1;
                }

                &.yellow {
                    color: #ffad4e;
                }

                span {
                    position: relative;
                    top: -3px;
                }

                ion-icon {
                    font-size: 22px;
                    margin: 0 10px;
                    position: relative;
                    top: 2px;
                }
            }

            &:last-child {
                text-align: right;
                padding: 12px;
                font-size: 20px;
                position: relative;

                a {
                    padding: 10px;

                    &:hover {
                        background: $light-grey;
                        border-radius: 50%;
                    }
                }

                i {
                    color: $darker-blue;
                }
            }
        }
    }

    .date {
        grid-area: date;
    }

    .value {
        grid-area: value;
    }

    .message {
        grid-area: message;
    }

    .link {
        grid-area: link;
    }
}
</style>
