<template>
    <div class="trasaction-filter-modal">
        <svg width="20px" height="20px" view-box="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="triangulo">
            <polygon fill="white" points=" 10,0 0,20 20,20 " />
        </svg>

        <form class="form-filter flex column space-between">
            <div class="flex space-between">
                <h1>FILTRAR MOVIMENTAÇÕES</h1>
                <button class="close-btn" @click="closeModal">
                    <i class="icon ion-md-close"><ion-icon name="close"></ion-icon></i>
                </button>
            </div>

            <stacked-select label="Categoria" :model="categories" @change="updateCategory"> </stacked-select>

            <stacked-select label="Conta" :model="notMoney" @change="updateAccount"> </stacked-select>

            <stacked-select label="Usuário" :model="30" @change="updateUser"> </stacked-select>

            <stacked-select label="Status" :model="status" @change="updateStatus"> </stacked-select>
        </form>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import StackedSelect from '../../components/inputs/StackedSelect.vue'

export default {
    name: 'TrasactionsFilterModal',

    components: {
        StackedSelect,
    },

    data() {
        return {
            filterTransaction: {},
            status: ['Pago', 'Não Pago'],
        }
    },

    methods: {
        updateCategory(value) {
            this.filterTransaction.category = value
        },
        updateAccount(value) {
            this.filterTransaction.account = value
        },
        updateUser(value) {
            this.filterTransaction.user = value
        },
        updateStatus(value) {
            this.filterTransaction.status = value === 'pago' ? true : false
        },
        closeModal() {
            this.$emit('closeFilterModalEvent')
        },
    },

    computed: {
        ...mapState(mainStore, ['categories', 'notMoney']),
    },
}
</script>

<style lang="scss" scoped>
.trasaction-filter-modal {
    font-size: 14px;
    font-weight: 500;
    padding: 12px 13px 21px 16px;
    border-radius: 8px;
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.11);
    height: 386px;
    width: 352px;
    background-color: #ffffff;
    z-index: 2;

    .form-filter {
        height: 100%;

        .form-filter > div {
            height: 66px;
        }
    }

    .icon {
        color: #9fa5b1;
        font-size: 20px;
        float: right;
    }

    .close-btn {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.triangulo {
    position: absolute;
    top: -10px;
    right: 45px;
    z-index: 1;
}

.column {
    flex-direction: column;
}

.box-shadow {
    width: 20px;
    height: 20px;
}

.space-between {
    justify-content: space-between;
}

.flex {
    display: flex;
}
</style>
