export default {
    isPlanner() {
        const profiles = this.user.profiles || []
        return profiles.includes('planner')
    },

    plannerWithFamily() {
        return this.isPlanner && !!this.user.family
    },
}
