<template>
    <div class="settings view-container">
        <div class="view-content">
            <ActionBar title="Configurações" :isSettings="true" />

            <div class="inner-container flex column">
                <div class="flex tab">
                    <label
                        class="tab-label"
                        @click="changeTab('profile')"
                        :class="[{ active: isActive('perfil') }]"
                        data-cy="change-tab-profile"
                        >Meu Perfil
                    </label>

                    <!-- <label
                        class="tab-label"
                        @click="changeTab('plano')"
                        :class="[{ active: isActive('plano') }]"
                        data-cy="change-tab-plano"
                        >Plano de assinatura
                    </label> -->

                    <label
                        class="tab-label"
                        @click="changeTab('category')"
                        :class="[{ active: isActive('categorias') }]"
                        data-cy="change-tab-category"
                        >Categorias
                    </label>

                    <label
                        class="tab-label"
                        @click="changeTab('preferences')"
                        :class="[{ active: isActive('preferencias') }]"
                        data-cy="change-tab-preferences"
                        >Preferências
                    </label>

                    <label
                        class="tab-label"
                        @click="changeTab('family-account')"
                        :class="[{ active: isActive('family-account') }]"
                        data-cy="change-tab-family"
                        >Conta Família
                    </label>

                    <label
                        class="tab-label"
                        @click="changeTab('bankaccess')"
                        :class="[
                            { active: isActive('bankaccess') },
                            { alert: getBelvoRelatedPluggyConnectors.length > 0 },
                        ]"
                        data-cy="change-tab-bankaccess"
                        >Contas Conectadas
                    </label>
                </div>

                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../store'

import ActionBar from '../components/ActionBar'
import ProfileConfig from './settings/ProfileConfig.vue'
import CategoryConfig from './settings/CategoryConfig'
import PreferencesConfig from './settings/PreferencesConfig'
import PlanDetails from './settings/PlanDetails'
import BankAccessConfig from './settings/BankAccessConfig'

export default {
    name: 'Settings',

    components: {
        ActionBar,
        ProfileConfig,
        CategoryConfig,
        PreferencesConfig,
        BankAccessConfig,
        PlanDetails,
    },

    data() {
        return {
            tab: this.$route.name,
        }
    },

    computed: {
        ...mapState(mainStore, ['isExpired', 'getBelvoRelatedPluggyConnectors']),
    },

    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(newRoute) {
                this.tab = newRoute.name
            },
        },
    },

    methods: {
        changeTab(tab) {
            switch (tab) {
                case 'profile':
                    this.$router.push({ name: 'perfil' })
                    break
                case 'plano':
                    this.$router.push({ name: 'plano' })
                    break
                case 'category':
                    this.$router.push({ name: 'categorias' })
                    break
                case 'preferences':
                    this.$router.push({ name: 'preferencias' })
                    break
                case 'bankaccess':
                    this.$router.push({ name: 'bankaccess' })
                    break
                case 'family-account':
                    this.$router.push({ name: 'family-account' })
                    break
                default:
                    this.$router.push({ name: 'perfil' })
                    break
            }
        },

        isActive(tab) {
            return this.tab === tab
        },
    },
}
</script>

<style lang="scss" scoped>
@include tab-bar();

.view-content {
    .inner-container {
        padding: 16px 0 30px 0;
    }
}

.flex {
    display: flex;
}

.flex-around {
    justify-content: space-around;
}

.flex-between {
    justify-content: space-between;
}

.column {
    flex-direction: column;
}

.grow-1 {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}
.settings .tab-label {
    position: relative;
}
.settings label:not(.active).tab-label.alert:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: -5px;
    right: -6px;
    background-color: red;
    border-radius: 100%;
    z-index: 1000;
}
</style>
