<template>
    <div class="flex column">
        <tr data-cy="category-list" v-for="tr in topTransactions" :key="tr._id">
            <!-- DESCRIPTION -->
            <td class="description row space-between">
                <div class="row" style="vertical-align: middle">
                    <img
                        :src="require('@/assets/img/categories/' + getImgUrl(tr.category))"
                        class="avatar item"
                        :class="[sanitizeCategoryName(tr.category), tr.category.group]"
                    />

                    <div>
                        <span class="decription font-regular">{{ tr.category.name }}</span>
                    </div>
                </div>

                <span class="font-semibd">
                    {{ formatNum(Math.abs(tr.value)) }}
                </span>
            </td>
        </tr>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import util from '../../common/util'

export default {
    name: 'CategoryWasteList',

    props: {
        budget: {
            type: Object,
        },
    },

    data() {
        return {}
    },

    methods: {
        formatNum(number) {
            return util.formatNumber(number)
        },

        sanitize(string) {
            return util.sanitize(string)
        },

        sanitizeCategoryName(category) {
            return util.sanitizeCategoryName(category)
        },

        getImgUrl(category) {
            return util.getImgUrl(category)
        },
    },

    computed: {
        ...mapState(mainStore, ['filteredTransactions']),

        topTransactions() {
            return this.filteredTransactions('expenses')
                .filter((transaction) => {
                    return transaction.category.name !== 'Fatura Cartão' && transaction.paid
                })
                .sort(function (a, b) {
                    return a.value - b.value
                })
                .slice(0, 2)
        },
    },
}
</script>

<style lang="scss" scoped>
@include empty-list();

.column {
    flex-direction: column;
}

.flex {
    display: flex;
}

tr:first-child {
    border-bottom: 1px solid #70707021;
    margin-bottom: 4px;
}

.avatar {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 8px;
}

img:first-child {
    margin-bottom: 4px;
}

a:hover {
    .limited {
        text-decoration: underline;
    }
}

.limited {
    display: inline-block;
    white-space: nowrap;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $darker-blue;
    font-weight: normal;
}

.font-semibd {
    font-weight: 500;
    color: #f2a1ac;
    font-size: 12px;
}

.font-regular {
    font-weight: 400;
}

.row {
    flex-direction: 'row';
    margin: 0 !important;
    align-items: center;
}

.space-between {
    justify-content: space-between;
    vertical-align: middle;
}

.description {
    font-size: 12px;
    color: #1e3351;
}
</style>
