<template>
    <div class="category-chart">
        <div v-if="waiting" style="display: flex; justify-content: center; margin-top: 25px">
            <v-skeleton-loader type="avatar" class="donut" />
        </div>

        <apexcharts
            v-else
            type="donut"
            :width="width"
            :height="height"
            ref="donut"
            :options="options"
            :series="series"
            @dataPointSelection="dataPointSelectionHandler"
        />
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import util from '../../common/util'

export default {
    name: 'CategoryChart',

    components: {
        apexcharts: VueApexCharts,
    },

    props: {
        series: {
            type: Array,
            default: [20],
        },
        _options: Object,
        categoriesID: Array,
        modalEvent: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [String, Number],
            default: 240,
        },
        height: {
            type: [String, Number],
            default: 140,
        },
    },

    data() {
        return {
            options: {
                chart: {
                    fontFamily: 'Metropolis',
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        offsetY: 30,
                        offsetX: -5,
                        donut: {
                            size: '75%',
                            labels: {
                                total: {
                                    formatter: function (w) {
                                        let balance = w.globals.seriesTotals.reduce((prevValue, value) => {
                                            return prevValue + value
                                        }, 0)

                                        let spending = balance - w.globals.seriesTotals[3]
                                        let percentage = ` (${((spending / balance) * 100).toFixed(0)})%`

                                        return util.formatNumber(spending) + percentage
                                    },
                                },
                            },
                        },
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return util.formatNumber(value)
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                            return util.formatNumber(value)
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                colors: ['#A300D6', '#7D02EB', '#5653FE', '#2983FF', '#00B1F2'],
            },
        }
    },

    methods: {
        dataPointSelectionHandler(event, chartContext, config) {
            if (this.modalEvent) {
                let modal = {
                    state: true,
                    category: {
                        name: this._options.labels[config.dataPointIndex],
                        id: this.categoriesID ? this.categoriesID[config.dataPointIndex] : null,
                    },
                }
                if (this.categoriesID.length > 0) this.$emit('openCategoryModalEvent', modal)
            }
        },

        randomWidth(base) {
            return util.randomWidth(base)
        },
    },

    computed: {
        ...mapState(mainStore, ['waiting']),
    },

    watch: {
        _options: {
            deep: true,
            immediate: true,
            handler() {
                this.options = Object.assign({}, this.options, this._options)
            },
        },

        series: {
            deep: true,
            immediate: true,
            handler() {
                this.options = Object.assign({}, this.options, this._options)
            },
        },
    },

    mounted() {
        this.options = Object.assign({}, this.options, this._options)
    },
}
</script>

<style lang="scss" scoped>
.category-chart {
    @include flex-center();
}
</style>
