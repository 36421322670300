<template>
    <div class="flex bars-chart" style="height: 100%">
        <v-skeleton-loader v-if="waiting" :width="'100%'" class="simple-column" type="image" />
        <v-chart v-else :option="option" />
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { UniversalTransition } from 'echarts/features'
import { BarChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import util from '@/common/util'

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent, UniversalTransition])

export default {
    name: 'SimpleColumnChart',

    components: {
        VChart,
    },

    provide: {
        [THEME_KEY]: 'light',
    },

    props: {
        categories: Array,
        series: Array,
        width: Number,
        labels: {
            type: Array,
            default: () => ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
        },
        columnColor: String,
        secondColor: String,
    },

    data() {
        return {
            loading: true,
        }
    },

    computed: {
        ...mapState(mainStore, ['waiting']),

        option() {
            if (!this.waiting) {
                return {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                        },
                        axisLabel: {
                            show: true,
                        },
                        valueFormatter: function (value) {
                            return util.returnCurrencyPrefix().prefix + ' ' + value.toFixed(2)
                        },
                    },
                    textStyle: {
                        fontFamily: "Metropolis",
                        fontSize: 11
                    },
                    grid: {
                        top: '5%',
                        left: '1%',
                        right: '1%',
                        bottom: '3%',
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data:
                                this.width > 600
                                    ? [
                                          'Jan',
                                          'Fev',
                                          'Mar',
                                          'Abr',
                                          'Mai',
                                          'Jun',
                                          'Jul',
                                          'Ago',
                                          'Set',
                                          'Out',
                                          'Nov',
                                          'Dez',
                                      ]
                                    : this.labels,
                            axisTick: {
                                alignWithLabel: true,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                formatter: function (value) {
                                    let valor = Number(Math.abs(value)).toFixed(2)
                                    return value < 0 ? '-' + util.sanitizeChartLabels(valor) : util.sanitizeChartLabels(valor)
                                },
                            }
                        },
                    ],
                    series: [
                        {
                            type: 'bar',
                            barWidth: '52px',
                            data: this.series,
                            itemStyle: {
                                color: function (data) {
                                    return data.value > 0
                                        ? this.columnColor
                                        : data.value < 0
                                        ? this.secondColor || this.columnColor
                                        : '#9fa5b1'
                                }.bind({ columnColor: this.columnColor, secondColor: this.secondColor }),
                            },
                        },
                    ],
                    responsive: true,
                    maintainAspectRatio: false,
                }
            }
        },
    },
}
</script>

<style scoped>
.chart {
    height: 150px;
    width: '100%';
}
</style>
