<template>
    <div class="recurrency-icon" @click="$emit('click')">
        <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.34414 3.85096C9.97926 2.68538 11.9789 2 14.1416 2C19.6644 2 24.1416 6.47715 24.1416 12C24.1416 17.5228 19.6644 22 14.1416 22C9.56567 22 5.70521 18.9256 4.51792 14.7278C4.36761 14.1964 3.81494 13.8874 3.2835 14.0377C2.75207 14.1881 2.4431 14.7407 2.59341 15.2722C4.01783 20.3084 8.64731 24 14.1416 24C20.769 24 26.1416 18.6274 26.1416 12C26.1416 5.37258 20.769 0 14.1416 0C11.5305 0 9.11148 0.835113 7.14096 2.25264L7.10492 2.27857L7.07132 2.30759L6.43772 2.85478L6.40445 2.82428L6.14384 3.10857L5.98799 3.24317L6.00374 3.26141L2.1416 7.47465V5C2.1416 4.44772 1.69389 4 1.1416 4C0.589317 4 0.141602 4.44772 0.141602 5V10V11H1.1416H6.1416C6.69389 11 7.1416 10.5523 7.1416 10C7.1416 9.44772 6.69389 9 6.1416 9H3.4565L7.53933 4.546L8.34414 3.85096Z"
                :class="[transactionType]"
                class="line"
            />
        </svg>

        <div class="number" :class="[transactionType]">{{ recurrencyPeriods[recurrentType] }}</div>
    </div>
</template>

<script>
export default {
    name: 'RecurrencyIcon',

    emits: ['click'],

    props: {
        transactionType: {
            type: String,
        },
        recurrentType: {
            type: String,
        },
    },

    data() {
        return {
            recurrencyPeriods: {
                undefined: 0,
                dayly: 1,
                weekly: 7,
                fortnightly: 15,
                monthly: 30,
                semiannual: 180,
                annual: 365,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.recurrency-icon {
    cursor: pointer;
    color: #b7becc;
    position: relative;

    .number {
        position: absolute;
        font-size: 10px;
        top: 8px;
        width: 100%;
        text-align: center;

        &.expenses {
            color: $expense-red;
        }

        &.revenues {
            color: $revenue-green;
        }
    }

    svg {
        transform: scale(1);
        pointer-events: none;
    }
    .line {
        fill: #b7becc;

        &.expenses {
            fill: $expense-red;
        }

        &.revenues {
            fill: $revenue-green;
        }
    }
}
</style>
