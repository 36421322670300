<template>
    <modal-frame
        :style="styles"
        noHeader
        :width="400"
        @close-modal="$emit('close-modal')"
        :closeBtn="closeBtn"
        :extBtnClasses="extBtnClasses"
    >
        <template #content>
            <div v-if="finalized" class="icon-box">
                <v-icon v-if="success" class="success"> mdi-checkbox-marked-circle </v-icon>
                <v-icon v-if="error" class="failure"> mdi-alert-circle </v-icon>
            </div>

            <p class="text" v-html="message"></p>
        </template>

        <template #btn-container>
            <slot name="buttons"></slot>
        </template>
    </modal-frame>
</template>

<script>
import ModalFrame from '../../../containers/ModalFrame.vue'

export default {
    components: { ModalFrame },
    name: 'ConfirmationModalGeneric',

    props: {
        success: Boolean,
        error: Boolean,
        message: String,
        closeBtn: Boolean,

        styles: {
            type: Object,
            default: () => {
                return {
                    '--justify': 'space-between',
                }
            },
        },

        extBtnClasses: {
            type: Array,
            default: () => ['column'],
        },
    },

    computed: {
        finalized() {
            return this.success || this.error
        },
    },
}
</script>

<style lang="scss" scoped>
.icon-box {
    text-align: center;
    margin-bottom: 16px;
    padding-top: 16px;

    .success,
    .failure {
        font-size: 64px;
    }

    .success {
        color: #2dc785;
    }

    .failure {
        color: $hint-red;
    }
}

.text {
    margin-bottom: 32px;
    color: $darker-blue;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}
</style>
