<template>
    <form @submit.prevent.self="validate" class="edit-objective">
        <stacked-select
            label="Categoria"
            key="category"
            :model="categories"
            :previousSelected="objective.category"
            :required="true"
            @change="(v) => (objective.category = v)"
        />

        <stacked-input label="Qual o seu sonho?" ref="name" key="name" :required="true" v-model="objective.name" />

        <div class="flex">
            <stacked-input
                label="Valor total"
                ref="totalValue"
                key="totalValue"
                :errorMsg="valueErrMsg[0]"
                :errorFontSize="10"
                :required="true"
                v-model="objective.totalValue"
                :isMoney="true"
            />

            <stacked-input
                label="Valor inicial"
                :errorMsg="valueErrMsg[1]"
                ref="initialValue"
                key="initialValue"
                :errorFontSize="10"
                :required="true"
                v-model="objective.initialValue"
                :isMoney="true"
            />
        </div>

        <div class="flex">
            <stacked-input
                label="Inicia em"
                type="date"
                ref="beginDate"
                key="beginDate"
                style="width: 38%"
                :font="14"
                :errorFontSize="10"
                :readonly="disabled"
                :required="true"
                @blur="updatePeriod"
                v-model="objective.beginDate"
            />

            <div class="grouped-inputs flex" style="width: 60%">
                <stacked-input
                    label="Período"
                    type="number"
                    key="period"
                    ref="period"
                    min="1"
                    style="width: 44%"
                    :readonly="disabled"
                    v-model="period.period"
                    @input="updatePeriod"
                />

                <stacked-select
                    label="Escala"
                    style="width: 52%"
                    key="installmentType"
                    :model="['meses', 'anos']"
                    :previousSelected="period.installmentType"
                    :disabled="disabled"
                    :notTransaction="true"
                    @change="(e) => updatePeriod(e, true)"
                />
            </div>
        </div>

        <stacked-select
            label="Depositar em"
            key="payment"
            :isGroupSelect="true"
            :disabled="disabled"
            :model="groupedPayments()"
            :previousSelected="objective.payment"
        />

        <basic-button
            label="Salva Alterações"
            type="submit"
            bgColor="#fa6437"
            width="100%"
            :extraStyles="{ marginTop: '-8px' }"
        />
    </form>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import BasicButton from '../buttons/BasicButton.vue'
import StackedInput from '../inputs/StackedInput.vue'
import StackedSelect from '../inputs/StackedSelect.vue'

import util from '../../common/util'

export default {
    components: { StackedInput, StackedSelect, BasicButton },

    name: 'EditObjective',

    props: {
        data: {
            type: Object,
            required: true,
        },
        dataPeriod: {
            type: Object,
            required: true,
        },
        disabled: Boolean,
        categories: Array,
    },

    data() {
        return {
            objective: {},
            valueErrMsg: [undefined, undefined],
            period: {},
        }
    },

    methods: {
        updatePeriod(value, select) {
            let date = this.$moment(this.objective.beginDate)

            if (select) this.period.installmentType = value
            let addParam = this.period.installmentType === 'anos' ? 'y' : 'M'

            this.objective.finalDate = this.$moment(date)
                .add(Number.parseInt(this.period.period), addParam)
                .toISOString()
        },

        validate() {
            let validator

            Array.of('name', 'initialValue', 'totalValue', 'beginDate').map((key) => {
                if (key === 'initialValue' || key === 'totalValue') {
                    let iValue, tValue, pValue
                    iValue = Number(util.sanitizeMoney(this.objective.initialValue))
                    tValue = Number(util.sanitizeMoney(this.objective.totalValue))
                    pValue = this.objective.paidValue || 0

                    if (this.disabled) {
                        if (pValue + iValue > tValue) {
                            this.valueErrMsg = ['VALOR MUITO BAIXO', 'VALOR MUITO ALTO']
                            this.$refs.initialValue.err = this.$refs.totalValue.err = validator = true
                        } else {
                            this.valueErrMsg = [undefined, undefined]
                            this.$refs[key].err = false
                        }
                    } else {
                        if (iValue > tValue) {
                            this.valueErrMsg = ['VALOR MUITO BAIXO', 'VALOR MUITO ALTO']
                            this.$refs.initialValue.err = this.$refs.totalValue.err = validator = true
                        } else {
                            this.valueErrMsg = [undefined, undefined]
                            this.$refs[key].err = false
                        }
                    }
                } else {
                    if (!this.objective.totalValue) this.$refs.totalValue.err = validator = true
                    else this.$refs.totalValue.err = false
                }
            })

            if (!this.period.period) this.$refs.period.err = validator = true
            else this.$refs.period.err = false

            if (!validator) this.$emit('edit', [this.objective, this.period, this.disabled])
        },
    },
    computed: {
        ...mapState(mainStore, ['groupedPayments']),
    },

    mounted() {
        this.$nextTick().then(() => {
            this.objective = { ...this.data }
            this.period = { ...this.dataPeriod }

            this.objective.beginDate = this.objective.beginDate.slice(0, 10)
        })
    },
}
</script>

<style lang="scss" scoped>
.edit-objective {
    margin-top: 70px;

    & > * {
        margin-top: 30px;

        &:nth-child(3),
        &:nth-child(4) {
            margin-top: 10px;
        }
    }

    .flex {
        display: flex;
        justify-content: space-between;

        & > * {
            width: 48%;
        }
    }

    .grouped-inputs {
        position: relative;

        h3 {
            position: absolute;
            top: 8px;
            left: 8px;
            padding: 0 4px;
            background-color: #f4f0f8;
            font-size: 14px;
            width: max-content;
            font-weight: 500;
            z-index: 2;
        }
    }
}
</style>
