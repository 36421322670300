<template>
    <div id="app">
        <router-view />
        <div id="alert-box"></div>
    </div>
</template>

<script>
export default {
    name: 'App',

    mounted() {
        const style = document.documentElement.style

        const customizations = {
            VUE_APP_REVENUES: '--revenues',
            VUE_APP_EXPENSES: '--expenses',
            VUE_APP_ACTION_BTN: '--action-btn-color',
            VUE_APP_TOGGLE_SLIDER: '--toggle-slider-color',
            VUE_APP_ACTIVE_SUBTITLE: '--active-subtitle-color',
            VUE_APP_CARD_HEADER: '--card-header-color',
            VUE_APP_BALANCE_CARD_COLOR: '--balance-card-color',
            VUE_APP_SIDEBAR_COLOR: '--sidebar-color',
        }

        Object.entries(customizations).forEach((value) => {
            if (process.env[value[0]]) style.setProperty(value[1], process.env[value[0]])
        })
    },
}
</script>

<style lang="scss">
body {
    margin: 0;
}

#app {
    color: rgb(109, 109, 109);
    font-size: 1rem;
}

p {
    font-weight: normal;
}

button {
    border-radius: 5px;
    padding: 12px;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
    border: none;
}

a {
    font-weight: bolder;
    font-style: inherit;
    font-size: inherit;
}

a:hover {
    cursor: pointer;
}

@media (max-width: 1365px) {
}
</style>
