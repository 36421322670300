<template>
    <div class="stacked-input" @click="active" :class="[err ? 'error' : '', labelColor, { disabled: read }]">
        <span class="stacked-label" :class="isRequired && 'required'">{{ label }}</span>

        <input
            v-bind="$attrs"
            :id="id"
            :min="min"
            :step="step"
            :type="type"
            :placeholder="ph"
            v-focus="focus"
            v-model.trim="valor"
            v-model.lazy="valor"
            v-money3="isMoney ? money : undefined"
            @input="updateValue"
            @blur="blurred"
            @click="active"
            :readonly="read"
            :class="{ 'no-label': align }"
            :style="{ fontSize: font + 'px', textAlign }"
        />

        <div v-if="err" class="error-msg-container">
            <span :style="{ fontSize: errorFontSize + 'px' }">{{ errorMsg.toLowerCase() }}</span>
        </div>

        <switching-eye v-if="showEyeButton" :elementId="id" />
    </div>
</template>

<script>
import date from '../../common/formatDate'
import util from '../../common/util'
import SwitchingEye from '../icons/SwitchingEye.vue'

export default {
    components: { SwitchingEye },

    name: 'StackedInput',

    props: {
        type: {
            type: String,
            default: 'text',
        },
        label: String,
        labelColor: {
            type: String,
            default: 'default-blue',
        },
        min: {
            type: String,
            default: () => {
                let date = new Date().getFullYear() - 100
                return date.toString() + '-01-01'
            },
        },
        step: {
            type: String,
            default: '0.01',
        },
        value: {
            required: true,
        },
        ph: String,
        readonly: {
            type: Boolean,
            default: false,
        },
        errorValidation: {
            type: Boolean,
            default: false,
        },
        errorMsg: {
            type: String,
            default: 'Campo Obrigatório',
        },
        money: {
            type: Object,
            default: () => ({
                decimal: ',',
                thousands: '.',
                prefix: util.returnCurrencyPrefix().prefix + ' ',
                precision: 2,
                masked: false,
            }),
        },
        isMoney: Boolean,
        font: {
            type: Number,
            default: 16,
        },
        required: {
            type: Boolean,
            default: false,
        },
        errorFontSize: {
            type: [Number, String],
            default: 12,
        },
        focus: {
            type: Boolean,
            default: false,
        },
        blur: {
            type: Boolean,
            default: true,
        },
        align: Boolean,
        showEyeButton: Boolean,
        textAlign: {
            type: String,
            default: 'left',
        },
        uppercase: {
            type: Boolean,
            default: false,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        reviewValue: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            valor: null,
            menor: null,
            read: false,
            err: false,
            id: `input-${this._uid}`,
        }
    },

    methods: {
        updateValue() {
            if (this.uppercase && this.valor) this.valor = this.valor.toUpperCase()
            if (this.reviewValue) this.checkLimits()
            this.$emit('input', this.valor)
        },

        checkLimits() {
            if (!this.valor) return

            let { max, min } = document.getElementById(this.id).attributes
            const valor = Number(this.valor)
            max = Number(max.value)
            min = Number(min.value)

            if (valor < min) this.valor = 0
            else if (valor > max) this.valor = max
        },

        blurred(e) {
            let value = e.target.value

            if (this.blur) {
                let parentElClassList = document.getElementById(this.id).parentElement.classList
                let labelClassList = document.getElementById(this.id).previousElementSibling.classList

                if (
                    !value ||
                    value === util.returnCurrencyPrefix().prefix + '0' + util.returnCurrencyPrefix().decimal + '00'
                ) {
                    if (this.required) this.err = true
                } else {
                    this.err = false
                }

                parentElClassList.remove('active')
                labelClassList.remove(this.labelColor)
            }

            this.$emit('blur', value)
        },

        active() {
            this.err = false
            const input = document.getElementById(this.id)

            if (input) {
                input.parentElement.classList.add('active')
                input.previousElementSibling.classList.add(this.labelColor)
            }
        },
    },

    watch: {
        errorValidation: {
            immediate: true,
            handler(e) {
                this.err = e
            },
        },

        value: {
            immediate: true,
            handler(v) {
                this.valor = v
            },
        },

        readonly: {
            immediate: true,
            handler(r) {
                this.read = r
            },
        },
    },

    mounted() {
        this.read = this.readonly
        this.valor = this.value

        if (this.type === 'date' && !this.value) {
            this.valor = date.inputFormatDate(new Date())
        }

        if (this.focus) this.active()
    },
}
</script>

<style lang="scss" scoped>
.stacked-input {
    height: 56px;
    border: 1px solid #bebebe;
    border-radius: 4px;
    background-color: #ffffff;
    position: relative;
    margin-top: 16px;

    &.default-blue:hover,
    &:focus,
    &.active {
        border: 2px solid $btn-blue;
        background: white !important;
    }

    &.default-blue:hover > .stacked-label {
        color: $btn-blue !important;
    }

    &.disabled {
        border: 1px solid #b7b8b9;
        pointer-events: none;

        .stacked-label,
        input {
            color: #b7b8b9;
        }
    }
}

.error {
    border: 2px solid $hint-red;
    background: $hint-bg-red;

    .stacked-label {
        color: $hint-red !important;
    }
}

.error-msg-container {
    margin-top: 6px;
    font-size: 13px;
    color: $hint-red;
    text-align: left;
    padding-left: 4px;
    text-transform: capitalize;
}

.stacked-label {
    position: absolute;
    top: 8px;
    left: 10px;
    background-color: transparent;
    font-size: 13px;
    width: max-content;
    font-weight: 500;
    pointer-events: none;
    color: $soft-gray;

    &.default-blue {
        color: $btn-blue;
    }

    &::first-letter {
        text-transform: capitalize;
    }
}

.stacked-input input {
    width: 100%;
    border: 0;
    color: #1e3351;
    padding: 30px 10px 8px;

    &.right {
        text-align: right;
        padding-right: 36px;
    }

    &::placeholder {
        @include input-placeholder();
        font-family: 'Metropolis' !important;
    }

    &.no-label {
        padding: 16px 15px;
    }
}

input {
    height: 100%;

    &[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    &[type='date'] {
        width: 100%;
        padding-right: 8px;

        &::-webkit-calendar-picker-indicator {
            position: relative;
            z-index: 2;
            margin: 0;
        }
    }

    &[readonly='readonly'] {
        color: #9e9e9e !important;
        cursor: not-allowed;
    }
}

.required:after {
    content: ' *';
    color: red;
}
</style>
