<template>
    <div class="basic-button" :class="{ disabled: disabled || loading }" @click="$emit('click')" :style="styles">
        <v-btn
            class="button ma-2"
            :class="[size, { 'no-events': loading }, { reverse }]"
            elevation="0"
            :type="type"
            :loading="loading"
            :disabled="disabled"
            >{{ label }}
            <slot></slot>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'BasicButton',

    emits: ['click'],

    props: {
        label: String,
        type: {
            type: String,
            default: 'button',
        },
        loading: Boolean,
        disabled: Boolean,
        bgColor: {
            type: String,
            default: process.env.VUE_APP_ACTION_BTN,
        },
        labelColor: {
            type: String,
            default: 'white',
        },
        reverse: Boolean,
        size: {
            type: String,
            default: 'medium',
        },
        width: {
            type: [String, Number],
            default: '158px',
        },
        height: {
            type: [String, Number],
            default: '56px',
        },
        textTransform: {
            type: String,
            default: 'uppercase',
        },
        fontWeight: {
            type: [Number, String],
            default: '500',
        },
        extraStyles: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        styles() {
            return {
                '--bg-color': this.bgColor,
                '--txt-color': this.labelColor,
                '--width': typeof this.width === 'number' ? this.width + 'px' : this.width,
                '--height': typeof this.height === 'number' ? this.height + 'px' : this.height,
                '--text-transform': this.textTransform,
                '--font-weight': this.fontWeight,
                ...this.extraStyles,
            }
        },
    },
}
</script>

<style lang="scss">
.basic-button {
    width: var(--width);
    height: var(--height);

    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
    }

    .button {
        text-transform: var(--text-transform) !important;
        letter-spacing: unset !important;
        box-shadow: none;
        background-color: var(--bg-color) !important;
        font-weight: 500 !important;
        min-width: var(--width) !important;
        color: var(--txt-color) !important;
        height: var(--height) !important;
        font-weight: var(--font-weight) !important;
        margin-left: 0 !important;
        margin-right: 0 !important;

        &.reverse {
            background-color: transparent !important;
            border: 2px solid var(--bg-color) !important;
            color: var(--bg-color) !important;
        }

        &.x-small {
            font-size: 12px !important;
        }

        &.small {
            font-size: 13px !important;
        }

        &.medium {
            font-size: 14px !important;
        }

        &.large {
            font-size: 18px !important;
        }

        &.no-events {
            pointer-events: none;
            opacity: 0.7;
        }

        &:disabled {
            opacity: 0.4;
        }

        a {
            color: var(--txt-color);
        }
    }
}
</style>
