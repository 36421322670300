export default {
    newTasks(state) {
        const allTasks = [
            ...this.tasksAccepted.map((t) => t._id),
            ...this.tasksDone.map((t) => t._id),
            ...this.tasksRejected.map((t) => t._id),
        ]

        return state.tasks.filter((tasks) => !allTasks.includes(tasks._id))
    },

    userTasks(state) {
        let tasks = []

        const tDone = this.tasksDone.map((t) => t._id)
        const tRejected = this.tasksRejected.map((t) => t._id)
        const tAccepted = this.tasksAccepted.map((t) => t._id)

        state.tasks.forEach((task) => {
            if (tDone.includes(task._id)) tasks.push({ ...task, icon: 'success' })
            else if (tRejected.includes(task._id)) tasks.push({ ...task, icon: 'failure' })
            else if (tAccepted.includes(task._id)) tasks.push({ ...task, icon: 'waiting' })
        })

        return tasks
    },

    tasksDone(state) {
        return state.userconfigs?.tasksDone || []
    },

    tasksAccepted(state) {
        return state.userconfigs?.tasksAccepted || []
    },

    tasksRejected(state) {
        return state.userconfigs?.tasksRejected || []
    },
}
