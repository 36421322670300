<template>
    <div v-show="speech().length" class="chat-window" :class="{ closed: chat }">
        <div class="flex-column">
            <header>
                <button type="button" class="switcher" @click="toggleWindow">&blacktriangledown;</button>
            </header>

            <article id="otto-chat" class="chat-content">
                <div v-if="speech().length" class="otto-conversa">
                    <div class="otto-bubble-1 flex-bubble row">
                        <img :src="$variables.chatIcon" class="chat-otto-img" />

                        <!-- 
                        <svg width="15px" height="10px" class="svg-path-otto">
                            <polygon points="0,0 15,0 15,10" fill="white" class="otto-path" />
                        </svg> 
                        -->

                        <p class="otto-talk" v-html="speech()[0].text"></p>
                    </div>

                    <div class="otto-bubble-2 flex-bubble column">
                        <div
                            class="otto-talk"
                            v-for="(item, index) in speech().slice(1, speech().length)"
                            :key="index"
                            v-html="item.text"
                        ></div>
                    </div>

                    <!-- <div class="otto-bubble-1 flex-bubble row">
                        <img :src="$varibles.chatIcon" class="chat-otto-img"/>

                        <svg width="15px" height="10px" fill="#128c7e" class="svg-path-otto">
                            <polygon points=" 0,0 15,0 15,10 " fill="#128c7e" class="otto-path" />
                        </svg>

                        <div class="otto-talk whatsapp">
                            Dúvidas, sugestões? Contate-nos diretamente via

                            <a href="https://bit.ly/3fluJCd" target="_blank">whatsapp</a>
                        </div>
                    </div> -->
                </div>
            </article>

            <!--
            <footer>
              <div class="textbox-container">
                <input class="chat-field" type="text" :placeholder="`Pergunte ao ${$variables.WithArtigo("a")}`"/>
                <i class="fas fa-paper-plane"></i>
              </div>
            </footer>
            -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import speechs from '@/common/chat/default-speechs'

export default {
    name: 'ChatWindow',

    props: {
        options: {
            type: Object,
            default: () => {},
        },
    },

    methods: {
        ...mapActions(mainStore, ['closed']),

        toggleWindow() {
            this.closed({ field: 'chat' })
        },

        scrollChat(id) {
            const element = document.getElementById(id)
            if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },

        speech() {
            let route = this.$route.name

            if (route === 'payment_transactions') {
                const payment = this.findById('payments', this.$route.params.payment)

                if (payment) {
                    if (payment.type === 'credit') route += '_credit'
                }
            }

            if (speechs[route]) {
                return speechs[route](this.options) || []
            }

            return []
        },
    },

    computed: {
        ...mapState(mainStore, ['user', 'chat', 'findById']),
    },

    /* watch: {
        $route: {
            immediate: true,
            handler() {
                this.closed({ field: 'chat', value: false })
            },
        },
    }, */

    mounted() {},
}
</script>

<style lang="scss" scoped>
@include custom-scroll();

.chat-window {
    position: fixed;
    right: 24px;
    bottom: 0;
    width: 355px;
    height: 480px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @include defTransition();
    z-index: 990;

    .flex-column {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        height: 100%;
    }

    header {
        padding: 12px 24px;
        text-align: right;

        .switcher {
            min-width: 0px;
            margin: 0;
            padding: 0;
            background-color: transparent;
            color: $light-grey;
            font-size: 22px;
            @include defTransition();
        }
    }

    .chat-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 8px 16px 16px;
        overflow-y: scroll;
        padding: 0;
    }

    .chat-field {
        width: calc(100% - 8px);
        margin: 4px;
        padding: 14px 16px 12px;
        border: none;
        color: #c6c6c6;
    }
}

.chat-window.closed {
    height: 40px;
}
.closed .chat-content,
.closed footer {
    display: none;
}
.closed .switcher {
    transform: rotateZ(180deg);
}

.flex-bubble {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.row {
        flex-direction: row;
        margin: 0;
    }
}

.otto-bubble-1 .otto-talk:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid white;
    border-left: 10px solid transparent;
    position: absolute;
    left: -7px;
    top: 0;
}

.otto-bubble-2 {
    margin-left: 42px;
}

.user-bubble {
    display: flex;
    float: right;
    margin-bottom: 4px;
}

.user-path,
.otto-path {
    position: relative;
}

.user-path {
    left: 5px;
    fill: #7bcbff;
}

.otto-path {
    right: 5px;
}

.chat-otto-img,
.chat-user-img {
    position: relative;
    top: 0;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 1px 1px 2px #00000030;
    border: 1px solid $btn-blue;
}

.chat-otto-img {
    margin-right: 12px;
}

.chat-user-img {
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.chat-user-text {
    text-align: left;
    color: white;
}

.user-talk {
    right: -3px;
    background-color: #7bcbff;
    color: #ffffff;
}

.otto-talk {
    left: -2px;
    background-color: #ffffff;
    text-align: left;
    margin-bottom: 5px;
    color: #5b6772;

    &.whatsapp {
        background: #128c7e;
        color: white;

        & a {
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            text-decoration: underline;

            &:hover {
                color: gainsboro;
            }
        }
    }
}

.user-talk,
.otto-talk {
    position: relative;
    max-width: 260px;
    line-height: 18px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

::placeholder {
    color: #c6c6c6;
}

.textbox-container {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    margin: 3px;
    padding-right: 14px;
}

.fas {
    color: #7bcbff;
}

#diagnostico {
    display: none;
}
</style>
