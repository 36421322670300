import coverDividas from './Entenda_suas_dividas/Cover-Dividas.png'
import coverGuia from './Guia_Completo_Equilibrio_Financeiro/Cover-Equilibrio-Financeiro.png'

export default [
    {
        title: 'Entenda suas dívidas',
        id: 'entenda_dividas',
        imgUrl: coverDividas,
    },
    {
        title: 'Guia completo para o Equilibrio Financeiro',
        id: 'guia_completo_equilibrio_financeiro',
        imgUrl: coverGuia,
    },
]
