import state from './states'
import actions from './actions'
import getters from './getters'

import { defineStore } from 'pinia'

export const mainStore = defineStore('store', {
    state,
    getters,
    actions,
})
