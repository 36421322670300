<template>
    <div class="range-input">
        <div class="label">
            <h2>{{ label }}</h2>
            <span :style="color">{{ percent + '%' }}</span>
        </div>

        <div class="input-wrapper">
            <div v-if="!noRange">
                <input
                    type="range"
                    min="0"
                    step="1"
                    :style="color"
                    :max="max"
                    v-model="percentage"
                    @change="onChange"
                />

                <div class="empty-bar" :style="calcPercentage"></div>
            </div>

            <slot v-else name="progress-bar"></slot>

            <div>
                <input
                    class="spend-input"
                    type="text"
                    v-money3="money"
                    v-model.number="value"
                    v-model.number.lazy="value"
                    @keypress.enter="handleKeypress"
                    @blur="updatePercentage"
                />

                <basic-button label="OK" :width="40" :height="40" @click="changeEvent" :disabled="!changed" />
            </div>
        </div>
    </div>
</template>

<script>
import BasicButton from '../buttons/BasicButton.vue'

import util from '@/common/util'

export default {
    name: 'RangeInput',

    components: {
        BasicButton,
    },

    props: {
        budgetID: String, // ID do budget da categoria
        maxValue: Number, // valor máximo do range input
        currentIncome: {
            type: Number,
        }, // valor máximo reservado para categoria em relação ao valor máximo reservado para o grupo
        label: String,
        pValue: Number, // porcentagem
        hasError: {
            type: Boolean,
            default: false,
        },
        noRange: Boolean,
        notHasRevenues: Boolean,
    },

    data() {
        return {
            value: 0,
            max: 0,
            percentage: 0,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: util.returnCurrencyPrefix().prefix + ' ',
                precision: 2,
                masked: false,
            },
            changed: false,
        }
    },

    computed: {
        calcPercentage() {
            return {
                '--percentage': this.percentage >= this.max ? 0 + '%' : 100 - (this.percentage / this.max) * 100 + '%',
            }
        },

        color() {
            return {
                '--color': !this.hasError ? '#028FFF' : process.env.VUE_APP_EXPENSES,
            }
        },

        percent() {
            if (isNaN(this.percentage)) return 0
            if (typeof this.percentage === 'number') return this.percentage.toFixed(0)
            return this.percentage
        },
    },

    methods: {
        handleKeypress(event) {
            if (!this.changed) this.updatePercentage(event)
            else this.changeEvent()
        },

        onChange(event) {
            this.percentage = Number(event.target.value)
            this.changed = true
        },

        updatePercentage(event) {
            let value = util.sanitizeMoney(event.target.value)

            if (!this.noRange) this.percentage = Number((value / this.currentIncome) * 100)
            this.changed = true
        },

        changeEvent() {
            this.$emit('change', {
                _id: this.budgetID,
                budgetValue: this.percentage,
                value: Number(util.sanitizeMoney(this.value)),
            })

            this.changed = false
        },
    },

    watch: {
        maxValue() {
            this.max = this.maxValue
        },

        pValue() {
            this.percentage = this.pValue
        },

        percentage(p) {
            if (!this.noRange) this.value = (this.currentIncome * p * 0.01).toFixed(2)
        },

        currentIncome: {
            handler(cI) {
                this.percentage = this.pValue
                this.value = (this.currentIncome * this.pValue * 0.01).toFixed(2)
            },
        },
    },

    mounted() {
        this.value = (this.currentIncome * this.pValue * 0.01).toFixed(2)
        this.max = this.maxValue
        this.percentage = this.pValue
        this.relPercentage = this.relativePValue
    },
}
</script>

<style lang="scss" scoped>
.range-input {
    width: 100%;
    height: 10px;
    margin-bottom: 20px;

    .input-wrapper {
        display: flex;
        justify-content: space-between;

        & > div:first-child {
            width: 100%;
            position: relative;

            .empty-bar {
                overflow: hidden;
                height: 11px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #d9d9d9;
                position: absolute;
                right: 0;
                top: 4px;
                width: var(--percentage);
            }
        }

        & > div:last-child {
            position: relative;

            .basic-button {
                position: absolute;
                right: -13px;
                top: -32px;
                margin: 0 4px;
            }
        }

        .spend-input {
            text-align: right;
            box-sizing: border-box;
            border: 1px solid #bebebe;
            border-radius: 4px;
            padding: 10px;
            width: 180px;
            color: #696969;
            font-size: 18px;
            position: relative;
            bottom: 25px;
            left: 10px;
            padding-right: 48px;
        }
    }

    .label {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 185px);
        padding-bottom: 5px;

        h2 {
            font-size: 16px;
        }

        span {
            font-size: 14px;
            font-weight: 600;
            color: var(--color);
        }
    }
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: right;
    border: none;
}

input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    position: relative;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type='range']:focus {
    outline: none;
}

input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type='range']::-webkit-slider-thumb {
    height: 17.37px;
    width: 17.35px;
    margin-top: -4px;
    border-radius: 9.99px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.17);
    cursor: pointer;
    background-color: var(--color);
    position: relative;
    z-index: 1;
}

input[type='range']::-moz-range-thumb {
    height: 17.37px;
    width: 17.35px;
    margin-top: -4px;
    border-radius: 9.99px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.17);
    cursor: pointer;
    background-color: var(--color);
}

input[type='range']::-ms-thumb {
    height: 17.37px;
    width: 17.35px;
    margin-top: -4px;
    border-radius: 9.99px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.17);
    cursor: pointer;
    background-color: var(--color);
}

input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color);
}

input[type='range']::-moz-range-track {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color);
}

input[type='range']::-ms-track {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color);
}
</style>
