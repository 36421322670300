<template>
    <div class="objectives-card" :class="{ view: view }">
        <div class="content" :class="{ view: view }">
            <i v-if="objective.done" class="fas fa-check-circle"></i>

            <h3 v-if="!view">{{ objective.name }}</h3>

            <radial-bar
                :class="{ view: view }"
                :height="view ? '200' : '140'"
                :series="[(pValue / objective.totalValue) * 100]"
            />

            <div>
                <div :class="{ view: view }" style="margin-bottom: 10px">
                    <p class="title" :class="{ view: view }">Voce já alcançou</p>
                    <span class="value">{{ formatNum(pValue) }}</span>
                </div>

                <div :class="{ view: view }">
                    <p class="title" :class="{ view: view }">do total de</p>
                    <span class="value" style="font-size: 16px">{{ formatNum(objective.totalValue) }}</span>
                </div>
            </div>

            <div v-if="!view" class="footer">
                <a @click="() => (storyModal = true)">
                    <div class="button-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path
                                class="a"
                                d="M1784,2664a8,8,0,1,0,8,8A8,8,0,0,0,1784,2664Zm0,14.609a6.609,6.609,0,1,1,6.609-6.609A6.609,6.609,0,0,1,1784,2678.609Zm2.656-4.937a.7.7,0,0,1-.984.984l-2.163-2.164a.7.7,0,0,1-.2-.492v-4.146a.7.7,0,1,1,1.391,0v3.857Z"
                                transform="translate(-1776 -2664)"
                                fill="#5b6772"
                            />
                        </svg>
                        <span>Histórico</span>
                    </div>
                </a>

                <a @click="openDepositModal(undefined)" :class="{ disabled: objective.done }">
                    <div class="button-content">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.999"
                            height="16.941"
                            viewBox="0 0 15.999 16.941"
                        >
                            <path
                                class="a"
                                d="M7.913,15.567q-.278,0-.556.01c-.272.012-.543.03-.823.049A26.059,26.059,0,0,0,.5,16.941l-.475-1.33A26.12,26.12,0,0,1,3.235,14.7a7.972,7.972,0,0,1-1.824-1.923A8.285,8.285,0,0,1,0,8.138,8.153,8.153,0,0,1,2.343,2.384a7.9,7.9,0,0,1,11.314,0,8.193,8.193,0,0,1-1,12.335,26.088,26.088,0,0,1,3.142.891l-.476,1.33A24.813,24.813,0,0,0,7.913,15.567ZM1.391,8.138A6.864,6.864,0,0,0,2.56,11.982a6.3,6.3,0,0,0,2.744,2.3c.392-.016.751-.04,1.1-.065h.005q.421-.035.85-.052c.243-.012.488-.019.743-.019a24.213,24.213,0,0,1,2.684.13A7.1,7.1,0,0,0,13.5,11.836,6.725,6.725,0,0,0,8,1.416,6.673,6.673,0,0,0,1.391,8.138Zm6.18,3.735v-.562a3.413,3.413,0,0,1-1.182-.257,2.123,2.123,0,0,1-.9-.662,1.349,1.349,0,0,1-.29-.8.64.64,0,0,1,.654-.643.541.541,0,0,1,.383.124,1.343,1.343,0,0,1,.255.347,1.745,1.745,0,0,0,.582.642A1.724,1.724,0,0,0,8,10.276a1.317,1.317,0,0,0,.778-.215.606.606,0,0,0,.3-.491.674.674,0,0,0-.316-.619,3.883,3.883,0,0,0-1.04-.371A8.525,8.525,0,0,1,6.4,8.147a2.194,2.194,0,0,1-.82-.6,1.387,1.387,0,0,1-.307-.913,1.624,1.624,0,0,1,.284-.913,1.944,1.944,0,0,1,.836-.686,3.021,3.021,0,0,1,1.179-.254V4.025A.377.377,0,0,1,8,3.705a.354.354,0,0,1,.4.32v.8a3.617,3.617,0,0,1,.431.085,2.706,2.706,0,0,1,.816.347,1.687,1.687,0,0,1,.5.483.958.958,0,0,1,.171.518.6.6,0,0,1-.183.453.714.714,0,0,1-.518.177A.633.633,0,0,1,9.2,6.748a2.768,2.768,0,0,1-.391-.424,1.438,1.438,0,0,0-.423-.377,1.312,1.312,0,0,0-.661-.141,1.271,1.271,0,0,0-.712.185.541.541,0,0,0-.283.463.5.5,0,0,0,.208.415,1.738,1.738,0,0,0,.563.268c.235.07.56.157.972.259a5.117,5.117,0,0,1,1.2.436,2.013,2.013,0,0,1,.707.6,1.344,1.344,0,0,1,.24.787,1.994,1.994,0,0,1-.319,1.139,1.951,1.951,0,0,1-.941.719,3.6,3.6,0,0,1-.964.219v.58a.37.37,0,0,1-.4.292A.4.4,0,0,1,7.572,11.873Z"
                                fill="#5b6772"
                            />
                        </svg>
                        <span>Depositar</span>
                    </div>
                </a>

                <a @click="openDepositModal('redeem')">
                    <div class="button-content">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.12"
                            height="16.119"
                            viewBox="0 0 16.12 16.119"
                        >
                            <path
                                class="a"
                                d="M.537,16.119A.537.537,0,0,1,0,15.582V10.746a.537.537,0,0,1,.158-.38A4.165,4.165,0,0,1,2.687,9.134a6.485,6.485,0,0,1,2.845,1.075H8.06a1.614,1.614,0,0,1,1.612,1.612,1.693,1.693,0,0,1-.023.279l4.24-1.767a1.612,1.612,0,0,1,2.232,1.488.538.538,0,0,1-.267.464L9.405,16.046a.537.537,0,0,1-.271.073Zm.537-5.137v4.063H8.989l5.986-3.491a.538.538,0,0,0-.673-.229L9.342,13.391a.536.536,0,0,1-.208.041h-4.3a.537.537,0,1,1,0-1.075H8.06a.537.537,0,0,0,0-1.075H5.373a.539.539,0,0,1-.3-.09,5.9,5.9,0,0,0-2.389-.985A2.926,2.926,0,0,0,1.075,10.982ZM3.761,4.3a4.3,4.3,0,1,1,4.3,4.3A4.3,4.3,0,0,1,3.761,4.3Zm1.075,0A3.224,3.224,0,1,0,8.06,1.074,3.228,3.228,0,0,0,4.836,4.3ZM7.678,6.829a.527.527,0,0,1-.064-.086.3.3,0,0,1-.049-.091.344.344,0,0,1-.032-.1l-.005-.037a1.406,1.406,0,0,1-1.022-.965.537.537,0,0,1,1.031-.3.325.325,0,0,0,.309.231h.43a.323.323,0,0,0,0-.645h-.43a1.4,1.4,0,0,1-.317-2.758.567.567,0,0,1,.151-.31.562.562,0,0,1,.763,0,.567.567,0,0,1,.151.31A1.4,1.4,0,0,1,9.6,3a.537.537,0,1,1-1.019.34.322.322,0,0,0-.306-.221h-.43a.323.323,0,0,0,0,.645h.43a1.4,1.4,0,0,1,.317,2.757l-.005.036a.345.345,0,0,1-.032.1.322.322,0,0,1-.049.091c-.022.032-.043.059-.065.086a.563.563,0,0,1-.381.156A.557.557,0,0,1,7.678,6.829Z"
                                fill="#5b6772"
                            />
                        </svg>

                        <span>Resgatar</span>
                    </div>
                </a>

                <a @click="$emit('delete')">
                    <div class="button-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.121" height="16" viewBox="0 0 15.121 16">
                            <path
                                class="a"
                                d="M1126.257,1338.167l-.536,10.166a.7.7,0,0,1-.7.667H1116.1a.7.7,0,0,1-.7-.667l-.538-10.166a.7.7,0,0,1,1.407-.074l.5,9.495h7.58l.5-9.495a.7.7,0,0,1,1.407.074Zm1.864-2.354a.7.7,0,0,1-.7.7H1113.7a.7.7,0,0,1,0-1.406h3.868v-1.583a.506.506,0,0,1,.566-.527h4.846a.506.506,0,0,1,.566.527v1.583h3.868A.7.7,0,0,1,1128.121,1335.813Zm-9.319-.7h3.516v-.879H1118.8Zm.3,11.428h.014a.643.643,0,0,0,.6-.666l-.176-7.6a.621.621,0,0,0-.629-.61.614.614,0,0,0-.6.626l.176,7.618A.647.647,0,0,0,1119.1,1346.538Zm2.9,0a.631.631,0,0,0,.615-.617l.176-7.592a.619.619,0,0,0-.6-.633.612.612,0,0,0-.629.6l-.176,7.6a.624.624,0,0,0,.6.645Z"
                                transform="translate(-1113 -1333)"
                            />
                        </svg>
                        <span>Excluir</span>
                    </div>
                </a>
            </div>
        </div>

        <!-- MODAL DE HISTÓRICO -->
        <objective-extract v-if="storyModal" :transactions="objective.transactions" @close-modal="storyModal = false" />

        <!-- MODAL DE DEPÓSITO/RESGATE -->
        <deposit-objective
            v-if="depositModal"
            :name="objective.name"
            :installment="objective.installment"
            :mode="depositModalMode"
            :investedValue="pValue"
            @deposit="(obj) => makeDeposit(obj)"
            @close-modal="depositModal = false"
        />
    </div>
</template>

<script>
import { mapActions } from 'pinia'
import { mainStore } from '../../store'

import RadialBar from '../charts/RadialBar.vue'
import ObjectiveExtract from '../modals/objectives/ObjectiveExtract.vue'
import DepositObjective from '../modals/objectives/DepositObjective.vue'

import request from '../../common/request'
import util from '../../common/util'

export default {
    name: 'ObjectivesCard',

    components: {
        RadialBar,
        ObjectiveExtract,
        DepositObjective,
    },

    props: {
        objective: Object,
        view: Boolean,
    },

    data() {
        return {
            storyModal: false,
            depositModal: false,
            depositModalMode: undefined,
        }
    },

    computed: {
        pValue() {
            return this.objective.paidValue + this.objective.initialValue
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        formatNum(number) {
            return util.formatNumber(number)
        },

        openDepositModal(mode) {
            this.depositModalMode = mode
            this.depositModal = true
        },

        makeDeposit(deposit) {
            let transaction = { ...deposit }
            transaction.date = new Date(transaction.date)

            this.DISABLED()

            request
                .post({
                    collection: 'objectives',
                    subroute: `/${this.objective._id}/transaction`,
                    data: transaction,
                    mutation: 'CHANGE_DATA',
                })
                .then(() => (this.depositModal = false))
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => this.DISABLED())
        },
    },
}
</script>

<style lang="scss" scoped>
.objectives-card {
    width: 280px;
    height: 330px;
    margin: 0 24px 50px 0;
    border-radius: 4px;
    position: relative;
    box-shadow: 0px 0px 10px #0000002b;

    &.view {
        box-shadow: none;
        width: unset;
        height: 120px;
        margin: 45px 25px 30px;
    }

    &:nth-child(3n) {
        margin-right: 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        text-align: center;
        padding-top: 25px;
        font-size: 14px;

        &.view {
            flex-direction: row;
        }

        h3 {
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 0.125px;
        }

        .fas.fa-check-circle {
            position: absolute;
            font-size: 25px;
            right: -15px;
            top: 15px;
            color: $objective-green;
        }

        .radial-bar.view {
            top: -45px;
            padding: 0;
        }

        & > * {
            padding: 0 30px;
            max-width: 100%;

            & > .view {
                text-align: right;
            }
        }

        .title {
            color: #34495e;

            &.view {
                width: max-content;
                float: right;
            }
        }

        .value {
            line-height: 22px;
            font-size: 18px;
            font-weight: 600;
            color: $objective-green;
        }

        .footer {
            display: flex;
            padding: 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            a {
                color: white;
                background-color: white;
                font-weight: 500;
                width: 25%;
                font-size: 13px;
                text-transform: capitalize;
                transition: all 0.3s ease-in-out;
                color: $hard-grey;

                .button-content {
                    display: flex;
                    flex-direction: column;
                    height: 63px;
                    justify-content: center;
                    align-items: center;
                }

                &:first-child {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 0;
                }

                &:last-child {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 4px;
                }

                &:hover {
                    background-color: $objective-orange;
                    color: white;

                    .a {
                        fill: white;
                    }
                }

                &.disabled {
                    pointer-events: none;
                    background-color: $lighter-grey;
                }

                svg {
                    margin-bottom: 9px;
                    height: 16px;

                    .a {
                        fill: $hard-grey;
                    }
                }
            }
        }
    }
}
</style>
