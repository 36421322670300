<template>
    <div class="action-menu" :class="{ 'is-open': action, 'no-events': waiting }">
        <button class="handler" @click="toggleMenu()">
            <span class="handler_arrow">&blacktriangledown;</span>
        </button>
        <ul class="menu-items">
            <!-- NOVA TRANSAÇAO -->
            <li>
                <button
                    type="button"
                    class="action-btn"
                    :class="{ disable: waiting }"
                    @click="openModal('TransactionsModal', { modalData: 'despesa' })"
                    data-cy="open-transactions-modal"
                >
                    <div class="btn-bg revenue-bg">
                        <svg
                            width="14px"
                            height="14px"
                            viewBox="0 0 15 15"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="fast-actions" transform="translate(-52.000000, -40.000000)" fill="#FFFFFF">
                                    <polygon
                                        id="ic_add_white-path"
                                        points="66.8071818 48.5985005 60.7581688 48.5985005 60.7581688 54.6475135 58.7418312 54.6475135 58.7418312 48.5985005 52.6928182 48.5985005 52.6928182 46.5821629 58.7418312 46.5821629 58.7418312 40.5331499 60.7581688 40.5331499 60.7581688 46.5821629 66.8071818 46.5821629"
                                    ></polygon>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p>
                        Nova<br />
                        Transação
                    </p>
                </button>
            </li>

            <!-- NOVO OBJETIVO  -->
            <li>
                <button
                    type="button"
                    class="action-btn"
                    :class="{ disable: waiting }"
                    @click="openModal('ObjectivesModal', { title: 'Novo Objetivo' })"
                    data-cy="open-objectives-modal"
                >
                    <div class="btn-bg objective-bg">
                        <svg
                            width="14px"
                            height="14px"
                            viewBox="0 0 14 13"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="fast-actions" transform="translate(-354.000000, -41.000000)" fill="#FFFFFF">
                                    <g id="objective" transform="translate(317.600000, 31.867117)">
                                        <g id="Group-32" transform="translate(27.250000, 0.000000)">
                                            <path
                                                d="M18.0003188,16.230401 C17.452949,17.1573693 16.7842494,17.7786201 16.0227026,17.8896119 C16.0525437,17.8870152 16.0830643,17.883128 16.1150244,17.877578 C16.1150244,20.2953119 16.8789485,21.5473629 18.4067967,21.6337309 L18.4620426,21.6337309 L18.4620426,21.6363333 C18.4623577,21.6363452 18.4626729,21.636357 18.4629881,21.6363688 L18.4620426,21.6363688 L18.4620426,22.2556232 L12.9965007,22.2556232 L12.9965007,21.6363688 L12.9955551,21.6363688 C12.9958703,21.6363476 12.9961855,21.6363264 12.9965007,21.6363051 L12.9965007,21.6337309 L13.0316807,21.6337309 C14.5977549,21.510289 15.380792,20.2582381 15.380792,17.877578 C15.4043811,17.8772484 15.4270829,17.8771383 15.4489783,17.8772083 C14.6958455,17.7377913 14.0331062,17.1258796 13.488194,16.2245558 C13.39934,16.1886392 13.2574452,16.1114799 13.0625097,15.9930778 C10.7297616,14.877915 9.61160544,13.2759374 9.91451629,11.8108054 C10.1437804,10.7018912 10.9821211,10.3432557 11.8938362,10.4790934 C11.8624878,10.0610243 11.846414,9.6430602 11.846414,9.23053352 C13.7522027,9.14114522 17.3108467,9.23053352 19.6121292,9.23053352 C19.6121292,9.64926574 19.5955683,10.0749659 19.563282,10.5016333 C20.4708531,10.3701817 21.3035445,10.7303368 21.5319123,11.8349158 C21.8348231,13.3000477 20.716667,14.9020253 18.3839189,16.0171881 C18.2173034,16.1183889 18.0894367,16.1894598 18.0003188,16.230401 Z M18.4789012,15.2737356 C20.3250893,14.2922422 21.1454424,13.0340945 20.9235216,11.9606984 C20.7760797,11.2475452 20.1286168,11.0338261 19.5053709,11.1218277 C19.3394669,12.6157924 18.9853132,14.0859157 18.4789012,15.2737356 Z M12.9973993,15.2654348 C12.4797904,14.0771939 12.1186134,12.6047316 11.9515048,11.0992211 C11.3250644,11.0073425 10.6711729,11.2194492 10.522907,11.936588 C10.2997928,13.0157562 11.1301922,14.2816683 12.9973993,15.2654348 Z"
                                                id="Combined-Shape"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p>
                        Novo<br />
                        Objetivo
                    </p>
                </button>
            </li>

            <!-- NOVA FORMA DE PAGAMENTO -->
            <li>
                <button
                    type="button"
                    class="action-btn"
                    :class="{ disable: waiting }"
                    @click="openModal('PaymentsModal', { modalData: 'cartão de crédito' })"
                    data-cy="open-payments-modal"
                >
                    <div class="btn-bg payment-bg">
                        <svg
                            width="16px"
                            height="10px"
                            viewBox="0 0 15 10"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="fast-actions" transform="translate(-454.000000, -43.000000)" fill="#FFFFFF">
                                    <g id="payments" transform="translate(417.800000, 31.867117)">
                                        <g id="Group-31" transform="translate(28.000000, 0.000000)">
                                            <path
                                                d="M9.46519557,11.2877584 L21.981233,11.2877584 C22.5335178,11.2877584 22.981233,11.7354737 22.981233,12.2877584 L22.981233,19.1586702 C22.981233,19.7109549 22.5335178,20.1586702 21.981233,20.1586702 L9.46519557,20.1586702 C8.91291082,20.1586702 8.46519557,19.7109549 8.46519557,19.1586702 L8.46519557,12.2877584 C8.46519557,11.7354737 8.91291082,11.2877584 9.46519557,11.2877584 Z M8.6451405,13.707098 L8.6451405,15.5508459 L22.8012881,15.5508459 L22.8012881,13.707098 L8.6451405,13.707098 Z"
                                                id="Combined-Shape"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p>
                        Novo<br />
                        Meio de Pgto
                    </p>
                </button>
            </li>

            <!-- NOVA INTEGRAÇÃO CONTA  -->
            <li>
                <button
                    type="button"
                    class="action-btn"
                    :class="{ disable: waiting }"
                    @click="getBelvoRelatedPluggyConnectors.length > 0 ? (update.show = true) : pluggyWidget()"
                    data-cy="open-bank-integration-modal"
                >
                    <div class="btn-bg account-connect-bg">
                        <img width="18" height="18" src="../assets/img/chain.svg" alt="chain" srcset="" />
                    </div>
                    <p>
                        Conectar<br />
                        Nova Conta
                    </p>
                </button>
            </li>

            <!-- NOVA MOV BANCARIA  -->
            <li>
                <button
                    type="button"
                    class="action-btn"
                    :class="{ disable: waiting }"
                    @click="
                        ;[
                            filteredPayments('debit').length > 0
                                ? openModal('BankTransitionModal', { modalData: 'deposito' })
                                : null,
                        ]
                    "
                    data-cy="open-bank-transaction-modal"
                >
                    <div
                        class="btn-bg bank-bg"
                        :style="{ opacity: [filteredPayments('debit', true).length === 0 ? '0.5' : '1'] }"
                    >
                        <svg
                            width="18px"
                            height="13px"
                            viewBox="0 0 18 13"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g
                                    id="fast-actions"
                                    transform="translate(-554.000000, -41.000000)"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                >
                                    <g id="bank" transform="translate(520.000000, 31.867117)">
                                        <g id="Group-35" transform="translate(27.250000, 0.000000)">
                                            <g id="Group-34" transform="translate(6.718653, 9.174442)">
                                                <polygon
                                                    id="Path-6-Copy-2"
                                                    transform="translate(7.838407, 9.743790) rotate(-180.000000) translate(-7.838407, -9.743790) "
                                                    points="9.4607044 9.15363655 9.4607044 6.85692207 15.3543818 12.6306582 0.32243212 12.6306582 0.32243212 10.9934651 11.3406978 10.9934651"
                                                ></polygon>
                                                <polygon
                                                    id="Path-6"
                                                    points="11.4961048 2.82111122 11.4961048 0.524396745 17.3897822 6.29813285 2.35783251 6.29813285 2.35783251 4.66093981 13.3760982 4.66093981"
                                                ></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <p>
                        Nova<br />
                        Mov. Bancária
                    </p>
                </button>
            </li>
        </ul>

        <div v-show="showSuccessDialog" class="overlay">
            <v-slide-y-reverse-transition :duration="200">
                <confirmation-create-modal
                    v-bind="confirmation"
                    v-if="showSuccessDialog"
                    @confirmEvent="() => (showSuccessDialog = false)"
                >
                    <basic-button
                        :bgColor="confirmation.error ? '#ff113a' : '#028FFF'"
                        width="100%"
                        label="Fechar"
                        @click="() => (showSuccessDialog = false)"
                    />
                </confirmation-create-modal>
            </v-slide-y-reverse-transition>
        </div>

        <div v-show="update.show" class="overlay">
            <v-slide-y-reverse-transition :duration="200">
                <confirmation-create-modal
                    v-bind="update"
                    v-if="update.show"
                    @confirmEvent="() => (update.show = false)"
                >
                    <basic-button
                        width="100%"
                        label="Ver minhas contas"
                        @click="
                            () => {
                                bankAccessTab()
                                update.show = false
                            }
                        "
                    />
                    <basic-button
                        :reverse="true"
                        width="100%"
                        label="Depois vejo isso"
                        @click="() => (update.show = false)"
                    />
                </confirmation-create-modal>
            </v-slide-y-reverse-transition>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import BasicButton from '../components/buttons/BasicButton.vue'
import ModalTransition from '../components/transitions/ModalTransition.vue'
import ConfirmationCreateModal from '../components/modals/confirmation-modals/ConfirmationCreateModal.vue'

import { environment } from '../common/environment'
import request from '@/common/request'
import apiService from '../services/api.service'

export default {
    components: { ModalTransition, ConfirmationCreateModal, BasicButton },

    name: 'ActionMenu',

    props: {
        keepOpen: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            showSuccessDialog: false,
            loading: false,
            confirmation: {
                title: '',
                error: false,
                text: '',
            },
            update: {
                show: false,
                title: 'Contas conectadas',
                error: true,
                text: `Você possui conta(s) de bancos que necessita(m) atualizar. Antes de conectar uma nova conta, você precisa atualizar ou excluir as contas atualmente integradas.`,
            },
        }
    },

    computed: {
        ...mapState(mainStore, [
            'action',
            'waiting',
            'bankaccesses',
            'filteredPayments',
            'getAvailablePluggyConnectors',
            'getBelvoRelatedPluggyConnectors',
        ]),
    },

    methods: {
        ...mapActions(mainStore, ['closed']),

        bankAccessTab() {
            this.$router.push({ name: 'bankaccess' })
        },

        toggleMenu(option) {
            this.closed({ field: 'action', value: option })
        },

        openModal(modal, modalData) {
            this.$emit('openModalEvent', {
                state: modal,
                modalData,
            })
        },

        async getPluggyAccessToken(linkId) {
            const response = await apiService.post(environment.PLUGGY_ACCESS_TOKEN, { linkId })
            return response.data
        },

        async pluggyWidget() {
            const itemId = undefined
            const { accessToken } = await this.getPluggyAccessToken(itemId)
            const pluggyTokenPayload = {
                connectToken: accessToken,
                updateItem: itemId,
                connectorIds: this.getAvailablePluggyConnectors,
                onSuccess: (itemData) => this.pluggySuccessCallbackFunction(itemData),
                onError: (error) => this.pluggyErrorCallbackFunction(error),
            }
            const pluggyConnect = new PluggyConnect(pluggyTokenPayload)
            pluggyConnect.init()
        },

        async pluggyErrorCallbackFunction(error) {
            console.error('Whoops! Pluggy Connect error... ', error)
            const { data } = error
            const { item } = data
            const { connector, executionStatus, id } = item
            if (executionStatus === 'USER_AUTHORIZATION_PENDING') {
                try {
                    await request.post({
                        collection: 'bankaccesses',
                        data: {
                            linkId: id,
                            institution: connector.name,
                            type: 'pluggy',
                            connector: connector.id,
                            status: 'pending',
                            active: false,
                        },
                    })

                    this.confirmation.text = `Sua conta foi adicionada com sucesso.\n
                            Agora iremos dar inicio ao processo de sincronização dos dados.\n
                            Esse é um processo que pode demorar alguns minutos.`
                    this.confirmation.error = false
                    this.showSuccessDialog = true
                } catch (error) {
                    console.error(error)
                    this.confirmation.text = `Não foi possível salvar seus dados no momento. Por favor entre em contato com nosso suporte técnico.`
                    this.confirmation.error = true
                    this.showSuccessDialog = true
                } finally {
                    this.loading = false
                }
            }
        },

        async pluggySuccessCallbackFunction(itemData) {
            console.log(itemData)
            this.loading = true
            this.confirmation.title = 'Nova Conexão'

            const { item } = itemData

            try {
                await request.post({
                    collection: 'bankaccesses',
                    data: {
                        linkId: item.id,
                        institution: item.connector.name,
                        type: 'pluggy',
                        connector: item.connector.id,
                    },
                })

                this.confirmation.text = `Sua conta foi adicionada com sucesso.\n
                        Agora iremos dar inicio ao processo de sincronização dos dados.\n
                        Esse é um processo que pode demorar alguns minutos.`
                this.confirmation.error = false
                this.showSuccessDialog = true
            } catch (error) {
                console.error(error)
                this.confirmation.text = `Não foi possível salvar seus dados no momento. Por favor entre em contato com nosso suporte técnico.`
                this.confirmation.error = true
                this.showSuccessDialog = true
            } finally {
                this.loading = false
            }
        },
    },

    watch: {
        keepOpen: {
            immediate: true,
            handler(kO) {
                this.toggleMenu(kO)
            },
        },
    },

    mounted() {
        this.isOpen = this.keepOpen
    },
}
</script>

<style lang="scss" scoped>
.action-menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: -102px;
    width: 500px;
    margin: 0 auto;
    padding: 16px;
    background-color: rgba(255, 253, 253, 0.8);
    border: solid 1px #ddd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @include defTransition();
    z-index: 990;

    &.is-open {
        bottom: 0;
        animation: openUp ease-in-out 0.3s;
    }

    &.no-events {
        pointer-events: none;
    }

    .handler {
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        width: 36px;
        margin: 0 auto;
        height: 20px;
        padding: 0px 11px 0;
        background-color: rgba(255, 253, 253, 0.5);
        border-top: solid 1px #ddd;
        border-left: solid 1px #ddd;
        border-right: solid 1px #ddd;
        border-bottom: solid 1px white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: #bac0cc;
        font-size: 20px;
        text-align: center;

        .handler_arrow {
            display: block;
            transform: rotateZ(180deg);
            @include defTransition();
        }
    }

    .menu-items {
        @include flex-around();

        li {
            width: 88px;
            text-align: center;
        }

        .action-btn {
            padding: 0;
            background-color: transparent;
            border: none;
            box-shadow: unset;
            text-transform: inherit !important;
            cursor: pointer;

            &.disable {
                cursor: auto;

                .btn-bg {
                    background-color: gainsboro;
                }
            }

            &:hover {
                filter: saturate(1.5);
            }

            .btn-bg {
                width: 30px;
                height: 30px;
                margin: 0 auto;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            p {
                margin-top: 8px;
                color: $dark-grey;
                font-size: 13px;
                line-height: 1.3;
            }
        }
    }
}
.is-open .handler_arrow {
    display: block;
    transform: rotateZ(0deg) !important;
}

@keyframes openUp {
    0% {
        bottom: -102px;
    }
    100% {
        bottom: 0;
    }
}
</style>
