<template>
    <div class="card-badge" :style="badgeStyles.styles">
        <div v-show="hasPendingInvoices === undefined" class="loader"></div>
        <ion-icon v-if="badgeStyles.icon" name="alert-circle"></ion-icon>
        <p v-html="getPlan"></p>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../../../store'

import { freePlans } from '../../../../common/constants'

export default {
    name: 'CardBadge',

    props: {
        subscription: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState(mainStore, ['hasPendingInvoices', 'daysLeft', 'notExpired']),

        loading() {
            return this.hasPendingInvoices === undefined
        },

        getPlan() {
            if (this.subscription) {
                if (
                    this.subscription.plan === 'otto-company' ||
                    this.subscription.plan === 'otto-family-member' ||
                    this.loading
                ) {
                    return ''
                }

                if (this.hasPendingInvoices) {
                    return 'Aguardando confirmação'
                }

                if (this.daysLeft < 0) {
                    return freePlans.includes(this.subscription.plan) && !this.subscription.subscriptionId
                        ? 'Período de avaliação expirado'
                        : 'Assinatura expirada'
                }

                if (this.daysLeft > 15 && !freePlans.includes(this.subscription.plan)) {
                    return
                }

                let plan = ''

                switch (this.subscription.plan) {
                    case 'otto_basic_annual':
                    case 'otto_annual_new':
                    case 'otto_annual':
                        plan = `Plano anual`
                        break
                    case 'otto_basic_monthly':
                    case 'otto_monthly_new':
                    case 'otto_monthly':
                        plan = `Plano mensal`
                        break
                    case 'otto_family_monthly':
                        plan = `Plano família mensal`
                        break
                    case 'free':
                        return 'Plano free'
                    default:
                        return `Período de avaliação | <strong>dia ${Math.abs(
                            this.$moment(this.subscription.expireAt).diff(this.$moment(), 'd') - 7
                        )} de 7 </strong>`
                }

                return `${plan ? plan + ' \|\ ' : ''}<strong>${this.diffExpireDate(
                    this.subscription.expireAt
                )}</strong>`
            }

            return ''
        },

        badgeStyles() {
            let payload = {
                icon: false,
                styles: {
                    '--badge-bg': '#2793fc',
                    '--badge-color': 'inherit',
                    '--badge-padding': '4px 0',
                    '--badge-height': 'unset',
                },
            }

            if (this.subscription && !this.loading) {
                payload.styles['--badge-color'] = 'white'

                if (this.hasPendingInvoices) {
                    payload.icon = true
                    payload.styles['--badge-bg'] = '#FFAD4E'
                } else if (
                    this.subscription.plan === 'otto-company' ||
                    this.subscription.plan === 'otto-family-member'
                ) {
                    payload.styles['--badge-padding'] = '2px 0'
                    payload.styles['--badge-bg'] = '#2793fc'
                    payload.styles['--badge-height'] = '2px'
                } else if (this.daysLeft <= 0) {
                    payload.styles['--badge-bg'] = '#FF113A'
                } else if (this.daysLeft <= 5) {
                    payload.icon = true
                    payload.styles['--badge-bg'] = '#DC5151'
                } else if (this.daysLeft <= 15) {
                    payload.icon = true
                    payload.styles['--badge-bg'] = '#FFAD4E'
                } else {
                    if (!freePlans.includes(this.subscription.plan)) {
                        payload.styles['--badge-padding'] = '2px 0'
                        payload.styles['--badge-bg'] = '#2793fc'
                        payload.styles['--badge-height'] = '2px'
                    } else {
                        payload.icon = true
                        payload.styles['--badge-bg'] = '#FFAD4E'
                    }
                }
            }

            return payload
        },
    },

    methods: {
        diffExpireDate(expireDate) {
            const diff = this.$moment(expireDate).diff(new Date(), 'days')

            if (diff === 0) return 'Expira hoje'
            return `resta ${diff} dia${diff > 1 ? 's' : ''}`
        },
    },
}
</script>

<style lang="scss" scoped>
.card-badge {
    background: var(--badge-bg);
    color: var(--badge-color);
    width: 100%;
    text-align: center;
    padding: var(--badge-padding);
    border-radius: 6px;
    font-size: 15px;
    font-weight: 500;
    height: var(--badge-height);
    @include flex-between-center();
    justify-content: center;

    ion-icon {
        font-size: 20px;
        position: relative;
        top: -1px;
    }

    p {
        margin: 0 0 0 5px;
        display: inline-block;
        font-size: 14px;
    }

    .loader {
        color: white;
        font-size: 8px;
        margin: 5px auto;
    }
}
</style>
