function findMatchedCategory(categoryName, userId, state) {
    return state.categories.find((category) => {
        let finded = category.name === categoryName

        if (userId) return finded && category.user === userId
        return finded
    })
}

const filterGroups = (filters) => {
    const _filters = Object.entries(filters)
    const [compositeFilters, normalFilters] = [[], []]

    _filters.forEach((filter) => {
        const value = filter[1]

        if (typeof value === 'object') {
            if (Object.keys(value)[0].charAt(0) === '$') {
                compositeFilters.push(filter)
            }
        } else normalFilters.push(filter)
    })

    return [compositeFilters, normalFilters]
}

const matchCategoryPlanning = (category, userId, state) => {
    switch (category.name) {
        case 'Alimentação':
        case 'Assinaturas':
        case 'Viagem':
            return findMatchedCategory('Lazer', userId, state)
        case 'Impostos':
            return findMatchedCategory('Moradia', userId, state)
        case 'Seguros':
            return findMatchedCategory('Transportes', userId, state)
        default:
            return category
    }
}

/**
 * Verifica se uma categoria pertence a um ou mais grupos
 * @param {*} group - Grupo(s) que as categorias serão filtradas
 * @param {*} cGroup - Grupo da categoria
 * @returns {boolean}
 */
const sameGroup = (group, cGroup) => {
    if (Array.isArray(group)) return group.includes(cGroup)
    return cGroup === group
}

export default {
    filterGroups,
    matchCategoryPlanning,
    sameGroup,
}
