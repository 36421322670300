<template>
    <div class="show-invoice" @click="$emit('click')">
        <svg xmlns="http://www.w3.org/2000/svg" width="12.381" height="16.022" viewBox="0 0 12.381 16.022">
            <path
                class="a"
                d="M246.381,1112.728a.728.728,0,0,0-.728-.728H234.728a.728.728,0,0,0-.728.728v14.566a.728.728,0,0,0,.728.728h10.924a.728.728,0,0,0,.728-.728Zm-1.457,13.838h-9.468v-13.109h9.468Zm-7.744-4.825a.637.637,0,0,1,.637-.637h2.152a.637.637,0,0,1,0,1.275h-2.147a.637.637,0,0,1-.643-.637Zm.1-2.913a.637.637,0,0,1,.637-.637h4.335a.637.637,0,1,1,0,1.274h-4.335a.637.637,0,0,1-.641-.637Zm0-2.731a.637.637,0,0,1,.637-.637h4.335a.637.637,0,0,1,0,1.275h-4.335a.637.637,0,0,1-.641-.637Zm5.4,5.242a.643.643,0,1,1-.9,0A.661.661,0,0,1,242.679,1121.339Z"
                transform="translate(-234 -1112)"
            />
        </svg>

        <small>Ver fatura</small>
    </div>
</template>

<script>
export default {
    name: 'EditIcon',

    emits: ['click'],
}
</script>

<style lang="scss" scoped>
.show-invoice {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
        background-color: #ecf0fd;
        border-radius: 50%;

        .a {
            fill: $darker-blue;
        }
    }

    .a {
        fill: #b7b8b9;
    }

    small {
        @include easy-tooltip();
        line-height: 40px;
        right: -15px;
        width: max-content;
    }
}
</style>
