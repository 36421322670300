export default {
    allTrailData: (state) => (trailId) => {
        let allTrails = []

        state.trails
            .find((trail) => trail._id === trailId)
            .items.forEach((item) => {
                allTrails.push(...item.documents)
            })

        return allTrails
    },
}
