import service from '../../services/api.service'
import auth from '../../services/auth.service'
import helper from '../helpers/actions.helper'

export default {
    FETCH_DATA({ payload }) {
        return new Promise((resolve, reject) => {
            service
                .get(payload.url)
                .then((response) => {
                    this.SET_DATA({
                        data: response.data.items || response.data,
                        attr: payload.attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    this.ERRO()
                    reject(err)
                    setTimeout(() => this.UNERR(), 5000)
                })
        })
    },

    GET_BY_ID({ payload }) {
        return new Promise((resolve, reject) => {
            service
                .get(payload.url)
                .then((response) => {
                    if (payload.attr === 'users') {
                        this.SET_DATA({
                            data: response.data,
                            attr: 'user',
                        })
                    } else {
                        this.ATT_SETED_DATA({
                            data: response.data,
                            attr: payload.attr,
                        })
                    }

                    resolve(response.data)
                })
                .catch((err) => {
                    this.ERRO()
                    reject(err)
                    setTimeout(() => this.UNERR(), 5000)
                })
        })
    },

    POST_DATA({ payload }) {
        return new Promise((resolve, reject) => {
            let data = payload.data
            if (!payload.noUserId) data.user = payload.thisUserId || auth.getOnLocalStorage('id')
            const mutation = payload.mutation || 'PUSH_DATA'

            service
                .post(payload.url, data)
                .then((response) => {
                    this[mutation]({
                        data: response.data,
                        attr: payload.attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    this.ERRO()
                    reject(err)
                    setTimeout(() => this.UNERR(), 5000)
                })
        })
    },

    POST_DATA_ELEMENT({ payload }) {
        return new Promise((resolve, reject) => {
            const { mutation, mutation_params } = payload

            service
                .post(payload.url)
                .then((response) => {
                    this[mutation]({
                        params: mutation_params,
                        attr: payload.attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    this.ERRO()
                    reject(err)
                    setTimeout(() => this.UNERR(), 5000)
                })
        })
    },

    PATCH_DATA({ payload }) {
        return new Promise((resolve, reject) => {
            let dados = helper.clean(payload.data, { applyForAll: payload.applyForAll })
            const attr = payload.attr === 'users' ? 'user' : payload.attr

            service
                .patch(payload.url, dados)
                .then((response) => {
                    this.CHANGE_DATA({
                        data: response.data,
                        applyForAll: payload.applyForAll,
                        attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    this.ERRO()
                    reject(err)
                    setTimeout(() => this.UNERR(), 5000)
                })
        })
    },

    PUT_DATA({ payload }) {
        return new Promise((resolve, reject) => {
            const attr = payload.attr === 'users' ? 'user' : payload.attr

            service
                .put(payload.url, payload.data)
                .then((response) => {
                    this.CHANGE_DATA({
                        data: response.data,
                        applyForAll: payload.applyForAll,
                        attr: attr,
                    })

                    resolve(response.data)
                })
                .catch((err) => {
                    this.ERRO()
                    reject(err)
                    setTimeout(() => this.UNERR(), 5000)
                })
        })
    },

    DELETE_DATA({ payload }) {
        return new Promise((resolve, reject) => {
            const mutation_params = payload.mutation_params

            service
                .del(payload.url)
                .then((status) => {
                    this.REMOVE_DATA({
                        params: mutation_params,
                        applyForAll: payload.applyForAll,
                        attr: payload.attr,
                    })

                    resolve(status)
                })
                .catch((err) => {
                    this.ERRO()
                    reject(err)
                    setTimeout(() => this.UNERR(), 5000)
                })
        })
    },
}
