import moment from 'moment'
import util from '../../common/util'

export default {
    /** Retorna true se assinatura estiver expirada ou inválida */
    isExpired(state) {
        const { activeTest, active } = this

        if (activeTest) return false
        return !active
    },

    active(state) {
        const { status } = state.subscriptions[0] || {}
        return status && this.notExpired
    },

    activeTest(state) {
        const { plan } = state.subscriptions[0] || {}
        return plan === 'no_plan' && this.notExpired
    },

    notExpired(state) {
        const { expireAt } = state.subscriptions[0] || {}
        return expireAt ? util.exactDate(expireAt).isSameOrAfter(util.exactDate(), 'days') : false
    },

    /** informa a quantidade de dias restantes da assinatura */
    daysLeft(state) {
        let subscription = state.subscriptions[0]
        let diff = moment(subscription.expireAt || new Date()).diff(new Date(), 'days')

        return diff
    },

    isOttoFamily(state) {
        const family = state.user?.family || {}
        return !!Object.keys(family).length
    },
}
