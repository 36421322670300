<template>
    <div class="objectives-modal">
        <div class="content">
            <div class="left-col">
                <h3>{{ titulo }}</h3>

                <h4 v-show="!edit || mode === 'view'">
                    {{ subtitle[categories.findIndex((cat) => cat._id === objective.category)] }}
                </h4>

                <div style="height: 280px"></div>

                <p v-if="(pos > 4 && mode !== 'deposit') || mode === 'view'">
                    Para alcançar seu sonho, você precisa guardar no mínimo
                    <strong>{{ objective.installment }}</strong> durante
                    <strong>{{ period }} {{ installmentType }}</strong
                    >.
                </p>

                <p v-if="mode === 'deposit'">
                    Você alcançará seu objetivo em
                    <strong>{{ parseDate(objective.finalDate) }}</strong>
                </p>
            </div>

            <div class="right-col">
                <div class="header">
                    <button
                        data-cy="close-objectives-modal"
                        class="close"
                        type="button"
                        @click.stop="$emit('modalClosedEvent')"
                    >
                        <ion-icon name="close"></ion-icon>
                    </button>
                </div>

                <objectives-card v-if="mode === 'view' && !edit && pos < 8" :objective="data" :view="true" />

                <div v-if="registering || pos > 7" class="bubble">
                    <img :src="$variables.chatIcon" />

                    <v-scale-transition origin="top left">
                        <div v-if="showBubble" v-html="speech" class="otto-talk"></div>
                    </v-scale-transition>
                </div>

                <!-- <deposit-objective-payment
                    v-if="mode === 'deposit' && pos < 9"
                    :objective="data"
                    :prazo="`${period} ${installmentType}`"
                    @deposit="makeDeposit"
                /> -->

                <ul v-if="pos < 8 && !edit" class="objective-preview">
                    <li v-for="(el, index) in list" :key="index">
                        <span>{{ el.title }}</span>
                        <strong>{{ el.value }}</strong>
                    </li>
                </ul>

                <edit-objective
                    v-if="edit"
                    :data="objective"
                    :dataPeriod="{ period, installmentType }"
                    :disabled="pos !== 7"
                    :categories="categories"
                    @edit="validateEdition"
                />

                <form @submit.prevent="() => {}">
                    <div v-if="!pos && registering" class="categories-list">
                        <div
                            data-cy="show-categories-list"
                            @click="updateSelect(objective.category)"
                            class="cell selected"
                        >
                            <span>{{ objective.category }}</span>
                            <div class="arrow" :class="{ active: openMenu }"></div>
                        </div>

                        <v-expand-transition>
                            <div v-show="openMenu" class="list">
                                <div
                                    v-for="(cat, index) in categories"
                                    :key="index"
                                    @click="updateSelect(cat._id)"
                                    class="cell"
                                    v-show="cat._id !== objective.category"
                                >
                                    <span>{{ cat.name }}</span>
                                </div>
                            </div>
                        </v-expand-transition>
                    </div>

                    <stacked-input
                        v-else-if="pos === 1"
                        :focus="pos === 1"
                        @keypress.prevent.enter="validateField('name')"
                        ref="name"
                        key="name"
                        :align="true"
                        v-model="objective.name"
                        name="name"
                    />

                    <stacked-input
                        v-else-if="pos === 2"
                        :focus="pos === 2"
                        @keypress.prevent.enter="validateField('totalValue')"
                        ref="totalValue"
                        key="totalValue"
                        :align="true"
                        v-model="objective.totalValue"
                        :isMoney="true"
                        name="total-value"
                    />

                    <div class="installment-form" v-else-if="pos === 3">
                        <stacked-input
                            style="margin-right: 20px"
                            :focus="pos === 3"
                            @keypress.prevent.enter="validateField('period')"
                            step="1"
                            min="1"
                            type="number"
                            :align="true"
                            errorMsg="VALOR INVÁLIDO"
                            ref="period"
                            key="period"
                            v-model="period"
                            name="period"
                        />

                        <div class="categories-list installment">
                            <div @click="updateSelect(installmentType, true)" class="cell selected">
                                <span>{{ installmentType }}</span>
                                <div class="arrow" :class="{ active: openMenu }"></div>
                            </div>

                            <v-expand-transition>
                                <div v-show="openMenu" class="list no-overflow">
                                    <div
                                        v-for="(type, index) in ['meses', 'anos']"
                                        :key="index"
                                        @click="updateSelect(type, true)"
                                        class="cell"
                                        v-show="type !== installmentType"
                                    >
                                        <span>{{ type }}</span>
                                    </div>
                                </div>
                            </v-expand-transition>
                        </div>
                    </div>

                    <stacked-input
                        v-else-if="pos === 4"
                        :focus="pos === 4"
                        @keypress.prevent.enter="validateField('initialValue')"
                        ref="initialValue"
                        key="initialValue"
                        :align="true"
                        :errorMsg="valueErrMsg"
                        v-model="objective.initialValue"
                        :isMoney="true"
                        name="initial-value"
                    />

                    <stacked-select
                        v-else-if="pos === 5 && registering"
                        :focus="pos === 5"
                        label=""
                        ref="payment"
                        key="payment"
                        :align="true"
                        :model="groupedPayments()"
                        :isGroupSelect="true"
                        :required="true"
                        @change="(v) => (objective.payment = v)"
                        data-cy="select-payment"
                    />

                    <div
                        v-if="(pos || mode === 'view') && !edit"
                        class="back"
                        :class="{ bottom: pos === 6 || mode === 'view' }"
                    >
                        <basic-button
                            v-show="pos < 7 && !mode"
                            v-bind="btnProps"
                            @click="validateStep(pos)"
                            data-cy="validate-step"
                        >
                            PRÓXIMA
                        </basic-button>

                        <basic-button
                            v-show="mode === 'view' && pos < 8"
                            v-bind="btnProps"
                            :reverse="true"
                            @click="deleteObjective()"
                        >
                            Excluir
                        </basic-button>

                        <basic-button
                            v-show="pos === 7 || (mode === 'view' && pos < 7)"
                            v-bind="btnProps"
                            :reverse="!mode"
                            @click="
                                () => {
                                    edit = true
                                    titulo = 'Editar Objetivo'
                                }
                            "
                        >
                            Editar
                        </basic-button>

                        <basic-button
                            v-show="pos === 7"
                            v-bind="btnProps"
                            class="right"
                            @click="registerObjective()"
                            data-cy="register-objective"
                        >
                            Salvar
                        </basic-button>

                        <basic-button
                            v-show="pos > 8"
                            v-bind="btnProps"
                            :reverse="true"
                            @click.stop="$emit('modalClosedEvent')"
                            data-cy="close-modal-objective"
                        >
                            Fechar
                        </basic-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../../store'

import ObjectivesCard from '../../cards/ObjectivesCard.vue'
import DepositObjectivePayment from '../../forms/DepositObjectivePayment.vue'
import EditObjective from '../../forms/EditObjective.vue'
import StackedInput from '../../inputs/StackedInput.vue'
import StackedSelect from '../../inputs/StackedSelect.vue'
import BasicButton from '../../buttons/BasicButton.vue'

import request from '../../../common/request'
import util from '../../../common/util'

export default {
    name: 'ObjectivesModal',

    components: { StackedInput, ObjectivesCard, StackedSelect, EditObjective, DepositObjectivePayment, BasicButton },

    props: {
        title: String,
        mode: String,
        data: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            objective: {
                payment: '',
            },
            showBubble: false,
            edit: false,
            editionValidation: {},
            titulo: '',
            period: 12,
            installmentType: 'meses',
            valueErrMsg: 'INFORMAÇÃO OBRIGATÓRIA',
            categories: [
                { name: 'Aposentar', _id: 'aposentar' },
                { name: 'Comprar um carro', _id: 'comprar-carro-novo' },
                { name: 'Comprar uma casa', _id: 'comprar-casa-propria' },
                { name: 'Casar', _id: 'casar' },
                { name: 'Eletrônicos', _id: 'comprar-eletronicos' },
                { name: 'Empreender', _id: 'empreender' },
                { name: 'Estética', _id: 'estetica' },
                { name: 'Formação profissional', _id: 'formacao-profissional' },
                { name: 'Fazer uma reserva de emergência', _id: 'reserva-emergencia' },
                { name: 'Viajar', _id: 'viajar' },
                { name: 'Viver de rendimentos', _id: 'viver-de-rendimento' },
                { name: 'Outros', _id: 'outros' },
            ],
            subtitle: [
                'Minha aposentadoria',
                'Meu carro novo',
                'Minha casa própria',
                'Meu casamento',
                'Eletrônicos',
                'Meu empreendimento',
                'Estética',
                'Minha formação profissional',
                'Reserva de emergência',
                'Minha viagem',
                'Viver de rendimentos',
                'Outros',
            ],
            openMenu: false,
            pos: 0,

            btnProps: {
                bgColor: '#fa7d20',
                width: '100%',
                extraStyles: {
                    marginTop: '-8px',
                    marginBottom: '-8px',
                },
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['user', '_date_selected', 'groupedPayments', 'findById']),

        speech() {
            let texts = [
                `Olá ${this.user.name}. Me diz, qual sonho você deseja realizar?`,
                'Legal! Pra acompanhar melhor, dê um nome ao seu sonho.',
                'E pra alcançar esse sonho, você precisa de quanto?',
                'Em quanto tempo você espera realizar seu sonho?',
                'Você já tem um valor inicial para começar a investir no seu sonho?',
                'Por fim, informe uma conta aonde deseja depositar as parcelas deste objetivo.',
                ' ',
                'Pronto! Basta revisar as informações e salvar seu novo objetivo.',
                `Para se manter na meta, você precisa depositar, pelo menos, <strong>${util.formatNumber(
                    this.objective.installment,
                )}</strong>.`,
                'Seu objetivo foi salvo com sucesso!',
                'Seu depósito foi salvo com sucesso!',
                'Ocorreu um probleminha... Tente novamente.',
            ]

            return texts[this.pos]
        },

        moneyPrefix() {
            return util.returnCurrencyPrefix().prefix
        },

        registering() {
            return !this.mode && !this.edit
        },

        list() {
            const emptyValue = util.formatNumber(0)

            let array = [
                { title: 'Valor total', value: this.objective.totalValue || emptyValue },
                { title: 'Prazo', value: `${Number(this.period)} ${this.installmentType}` },
                { title: 'Valor inicial', value: this.objective.initialValue || emptyValue },
                { title: 'Parcelas mensais', value: this.objective.installment || emptyValue },
                { title: 'Conta', value: this.findPaymentName(this.objective.payment) },
            ]

            if (this.mode === 'view') return array.slice(1, 5)
            else {
                let end = this.pos - 2 < 0 ? 0 : this.pos - 2

                return array.slice(0, end)
            }
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        parseDate: util.sanitizeDate,

        updateSelect(value, installment) {
            this.openMenu = !this.openMenu

            if (!this.openMenu) {
                if (installment) {
                    this.installmentType = value
                } else {
                    this.objective.category = value
                    if (value) this.pos++
                }
            }
        },

        updateInstallment() {
            let [tValue, iValue] = [
                this.toNumber(this.objective.totalValue),
                this.toNumber(this.objective.initialValue),
            ]

            if (Number.isNaN(iValue)) {
                iValue = this.objective.initialValue = 0
            }

            let installments = this.$moment(this.objective.finalDate).diff(
                this.$moment(this.objective.beginDate),
                'months',
            )

            this.objective.installment = util.formatNumber(((tValue - iValue) / installments).toFixed(2))
        },

        toNumber(string) {
            return Number(util.sanitizeMoney(string))
        },

        findPaymentName(id) {
            let p = this.findById('payments', id)

            if (p) return p.name
            else return null
        },

        registerObjective(update) {
            let promise

            Array.of('initialValue', 'totalValue', 'installment').map((key) => {
                this.objective[key] = this.toNumber(this.objective[key])
            })

            this.DISABLED()

            if (!update) {
                promise = request.post({ collection: 'objectives', data: this.objective })
            } else {
                promise = request.patch({ collection: 'objectives', _id: this.objective._id, data: this.objective })
            }

            promise
                .then(() => this.$emit('modalClosedEvent'))
                .catch(() => (this.pos = 11))
                .finally(() => this.DISABLED())
        },

        deleteObjective() {
            this.$emit('delete', {
                data: {
                    _id: this.objective._id,
                    collection: 'objectives',
                },
                text: 'Tem certeza que deseja excluir esse objetivo',
            })
        },

        validateEdition(payload) {
            this.objective = { ...payload[0] }
            this.period = payload[1].period
            this.installmentType = payload[1].installmentType
            this.updateInstallment()

            if (payload[2]) this.registerObjective(true)

            this.edit = false
            this.titulo = ''
        },

        validateStep(step) {
            switch (step) {
                case 1:
                    this.validateField('name')
                    break
                case 2:
                    this.validateField('totalValue')
                    break
                case 3:
                    this.validateField('period')
                    break
                case 4:
                    this.validateField('initialValue')
                    break
                case 5:
                    this.validateField('payment')
            }
        },

        validateField(field) {
            switch (field) {
                case 'totalValue':
                    if (!this.toNumber(this.objective[field])) this.$refs[field].err = true
                    else {
                        this.$refs[field].err = false
                        this.pos++
                    }
                    break
                case 'initialValue':
                    let tValue, iValue
                    tValue = this.toNumber(this.objective.totalValue)
                    iValue = this.toNumber(this.objective.initialValue)

                    if (iValue > tValue) {
                        this.valueErrMsg = 'VALOR MUITO ALTO'
                        this.$refs[field].err = true
                    } else {
                        this.valueErrMsg = 'CAMPO OBRIGATÓRIO'
                        this.$refs[field].err = false
                        this.pos++
                    }
                    break
                case 'period':
                    let i = Number(this.period)
                    if (this.period > 0 && Number.isInteger(i)) {
                        this.$refs[field].err = false
                        this.pos++
                    } else {
                        this.$refs[field].err = true
                    }
                    break
                case 'payment':
                    this.pos += 2
                    break
                default:
                    if (!this.objective[field]) this.$refs[field].err = true
                    else {
                        this.$refs[field].err = true
                        this.pos++
                    }
                    break
            }
        },
    },

    watch: {
        mode: {
            immediate: true,
            handler(m) {
                if (m === 'deposit') this.pos = 8
            },
        },

        pos: {
            immediate: true,
            handler(p) {
                this.showBubble = false

                if (p === 4) {
                    let date = this.$moment()
                    let addParam = this.installmentType === 'anos' || this.installmentType === 'ano' ? 'y' : 'M'

                    this.objective.beginDate = date.toISOString()
                    this.objective.finalDate = this.$moment(date).add(this.period, addParam).toISOString()
                } else if (p === 5) {
                    this.updateInstallment()
                }

                setTimeout(() => {
                    this.showBubble = true
                }, 300)
            },
        },
    },

    mounted() {
        if (!this.mode === 'view')
            setTimeout(() => {
                this.showBubble = true
            }, 300)

        this.titulo = this.title

        let { __v, createdAt, updatedAt, _links, ...objective } = this.data
        this.objective = objective

        if (this.objective._id) {
            let start = this.$moment(this.objective.beginDate)
            let end = this.$moment(this.objective.finalDate)

            let diff = end.diff(start, 'months')

            if (diff % 12 > 0) {
                this.period = diff
                this.installmentType = `meses`
            } else {
                this.period = diff / 12
                this.installmentType = `anos`
            }
        }

        if (this.mode === 'view') {
            Array.of('totalValue', 'initialValue', 'installment').map((key) => {
                this.objective[key] = util.formatNumber(this.objective[key])
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@include custom-scroll();
@include otto-talk();

.objectives-modal {
    background: #f4f0f8;
    border-radius: 8px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .content {
        display: flex;
        width: 750px;
        height: 570px;

        .left-col,
        .right-col {
            height: 100%;
        }

        .left-col {
            width: 45%;
            background: $otto-purple;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            font-size: 18px;
            position: relative;

            h3 {
                width: 100%;
                text-align: center;
                color: white;
                text-transform: uppercase;
                font-weight: 600;
                line-height: 60px;
                padding-top: 10px;
            }

            h4 {
                width: 100%;
                text-align: center;
                padding: 0 50px;
                margin: 20px 0;
                font-weight: bold;
                color: white;
            }

            p {
                color: white;
                width: 100%;
                font-size: 16px;
                padding: 0 30px;
                text-align: center;
                line-height: 22px;
                position: absolute;
                bottom: 35px;
            }
        }

        .right-col {
            width: 55%;
            position: relative;

            .header {
                position: relative;

                .close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 14px 16px;
                    font-size: 22px;
                    color: $dark-grey;
                }
            }

            .bubble {
                display: flex;
                margin: 60px 0 20px;
                padding: 0 60px 0 20px;

                img {
                    width: 30px;
                    height: 30px;
                    position: relative;
                }
            }

            .objective-preview {
                li {
                    display: grid;
                    margin: 0 30px 10px;
                    padding: 0 15px 20px;
                    border-bottom: 1px solid $light-grey;

                    span {
                        margin-bottom: 10px;
                        font-size: 13px;
                        font-weight: 500;
                        color: $medium-grey;
                    }

                    &:last-child {
                        border: none;
                    }
                }
            }

            form {
                padding: 0 35px 25px;

                .categories-list {
                    border: 1px solid $medium-grey;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    background: white;

                    .cell {
                        padding: 18px;
                        color: $light-black;
                        border-bottom: 1px solid $lighter-grey;
                        cursor: pointer;

                        span {
                            height: 19px;
                            text-align: center;
                        }

                        &.selected {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid $medium-grey;
                        }

                        &:hover:not(.selected) {
                            background: #9fa5b141;
                        }
                    }

                    .list {
                        max-height: 224px;
                        overflow: scroll;
                        overflow-x: hidden;

                        &.no-overflow {
                            overflow: hidden;
                        }
                    }
                }

                .installment-form {
                    position: relative;

                    & > div:first-child {
                        width: 50%;
                    }

                    & > div:last-child {
                        width: 155px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 2;
                    }
                }

                .back {
                    margin-top: 20px;
                    display: flex;

                    &.bottom {
                        position: absolute;
                        width: 85%;
                        bottom: 20px;
                    }
                }
            }
        }
    }

    .basic-button {
        &.right {
            margin-left: 10px;
        }
    }
}
</style>
