<template>
    <login-container>
        <transition name="fade">
            <form id="recover" @submit.prevent="forgotPassword" class="screen-container" v-show="showRecovery">
                <div v-if="disabled" class="loader"></div>

                <span v-else-if="!errorMessage" class="guide-message">
                    {{ ottoMsg }}
                </span>

                <span v-else class="guide-message error-message">
                    {{ errorMessage }}
                </span>

                <stacked-input
                    label="Seu e-mail de cadastro"
                    v-model="email"
                    :errorValidation="errors.email.error"
                    :errorMsg="errors.email.message"
                    name="email-recover"
                />

                <basic-button data-cy="submit-recover" type="submit" width="100%" label="Recuperar Conta" />

                <a data-cy="toggle-login" type="button" @click="$router.push('/login')">Voltar ao login</a>
            </form>
        </transition>

        <transition name="fade">
            <form
                id="new-password"
                @submit.prevent="updatePassword"
                class="screen-container"
                v-show="showUpdatePassword"
            >
                <div v-if="disabled" class="loader"></div>
                <span v-else-if="!errorMessage" class="guide-message">
                    {{ ottoMsg }}
                </span>
                <span v-else class="guide-message error-message">
                    {{ errorMessage }}
                </span>

                <div v-if="!onUpdatePasswordFeedback" class="pass-container">
                    <stacked-input
                        label="Sua senha"
                        type="password"
                        ref="password"
                        :showEyeButton="true"
                        v-model="password"
                        :required="true"
                        :errorValidation="errors.password.error"
                        :errorMsg="errors.password.message"
                    />
                </div>

                <basic-button v-if="!onUpdatePasswordFeedback" type="submit" width="100%" label="Atualizar Senha" />

                <basic-button
                    v-if="onUpdatePasswordFeedback"
                    @click="$router.push('/login')"
                    width="100%"
                    label="Voltar ao login"
                />
            </form>
        </transition>
    </login-container>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../store'

import BasicButton from '../components/buttons/BasicButton.vue'
import StackedInput from '../components/inputs/StackedInput.vue'
import LoginContainer from '../containers/LoginContainer.vue'

import authService from '../services/auth.service'
import validation from '../common/validation.js'

export default {
    components: { LoginContainer, StackedInput, BasicButton },

    name: 'AccountRecovery',

    data() {
        return {
            ottoMsg: 'Enviaremos um link para o seu e-mail de cadastro',
            showUpdatePassword: false,
            onUpdatePasswordFeedback: false,
            showRecovery: false,

            email: '',
            password: '',

            errorMessage: '',
            errors: {
                email: {
                    error: false,
                    message: undefined,
                },
                password: {
                    error: false,
                    message: undefined,
                },
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['disabled']),
    },

    mounted() {
        setTimeout(() => {
            if (!this.showUpdatePassword) this.showRecovery = true
        }, 300)
    },

    methods: {
        toggleUpdatePassword() {
            this.errorMessage = null
            this.showRecovery = false

            setTimeout(() => {
                this.ottoMsg = 'Informe sua nova senha'
                this.showUpdatePassword = true
            }, 300)
        },

        forgotPassword() {
            const email = this.email
            const validEmail = validation.validateEmail(email)

            this.errors.email.error = false

            if (validEmail) {
                authService.accountRecovery({ email }).then((response) => {
                    const status = response.status || response
                    this.errorMessage = this.ottoMsg = ''

                    switch (status) {
                        case 204:
                            this.ottoMsg = 'Um email com os passos para a recuperação foram enviados para você'
                            break
                        default:
                            this.errorMessage = 'Erro ao tentar recuperar a senha. Por favor tente novamente mais tarde'
                    }
                })
            } else {
                this.errorMessage = 'Por favor insira um email válido!'
                this.errors.email.error = true
            }
        },

        updatePassword() {
            this.onUpdatePasswordFeedback = false

            Array.of('password').map((id) => {
                this.errors[id].error = false
                this.errors[id].message = undefined
            })

            if (!this.password) {
                this.errorMessage = 'Por favor insira uma senha!'
                this.$refs.password.err = true
                this.errors.password.message = ''
            } else if (this.password.length < 8) {
                this.errorMessage = 'Senha precisa no min. 8 caractéres'
                this.$refs.password.err = true
                this.errors.password.message = ''
            } else {
                authService.updatePassword(this.password, this.$route.params.token).then((response) => {
                    const status = response.status || response

                    switch (status) {
                        case 204:
                            this.errorMessage = ''
                            this.ottoMsg = 'Senha atualizada com sucesso!'
                            this.onUpdatePasswordFeedback = true
                            break
                        case 400:
                            this.onUpdatePasswordFeedback = true
                            this.errorMessage = response.message
                            break
                        default:
                            this.errorMessage = 'Erro ao tentar atualizar a senha, tente novamente'
                    }
                })
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            switch (to.name) {
                case 'account-recovery':
                    vm.toggleUpdatePassword()
                    break
                default:
            }
        })
    },
}
</script>

<style lang="scss" scoped>
.loader {
    font-size: 20px;
    margin: 20px 0;
}

.screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 400px;
    position: relative;
    padding: 24px 40px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.guide-message {
    font-weight: 400;
    margin-bottom: 15px;
    color: #34495e;
    font-weight: 500;
    text-align: center;
    padding: 0 5px;
    line-height: 1.35rem;
}

.error-message {
    color: firebrick;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    padding: 0 10px;
}

p {
    font-weight: 500;
}

button {
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    padding: 20px 35px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bolder;
    min-width: 100%;
    border: none;
    margin: 0 0 25px 0;
}

button:hover {
    cursor: pointer;
}

.terms {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;

    & input[type='checkbox'] {
        width: 20px !important;
        margin-right: 10px;
        position: relative;
        top: 3px;
    }

    & label {
        width: 100%;
        font-weight: normal;
        font-size: 13px;
        margin-top: 5px;
    }
}

.stacked-input {
    width: 100%;
}

.basic-button {
    margin-top: 1em;
    margin-bottom: 1.5em;
}

a {
    font-style: inherit;
    font-size: inherit;
    margin-bottom: 30px;
    cursor: pointer;
    color: $action-btn-color !important;
    font-weight: 500;
}

.pass-container {
    position: relative;
    width: 100%;
}

@media screen and (max-width: 480px) {
    .screen-container {
        width: 100%;
    }

    .content {
        padding: 0 3%;
    }

    .logo {
        margin: 30px auto 15px;
    }
}
</style>
