<template>
    <div class="download-icon" @click="$emit('click')">
        <svg xmlns="http://www.w3.org/2000/svg" width="19.583" height="20.045" viewBox="0 0 19.583 20.045">
            <path
                class="a"
                d="M2018.637,2007.592a4.18,4.18,0,0,1-4.427,3.87c-1.306,0-1.983-.011-1.983-.011a.886.886,0,1,1,0-1.772h0s.683.026,1.97.026a2.456,2.456,0,0,0,2.69-2.233,2.586,2.586,0,0,0-2.451-2.69.87.87,0,0,1-.725-1.06,1.653,1.653,0,0,0-2.8-1.722.87.87,0,0,1-1.378-.218c-1.217-2.294-3.112-2.416-4.364-1.824a2.808,2.808,0,0,0-1.415,3.759.871.871,0,0,1-.546,1.167c-2.512.775-2.464,2.453-2.394,2.949a2.1,2.1,0,0,0,2.02,1.861h2.649a.857.857,0,0,1,0,1.715h-2.645a3.806,3.806,0,0,1-3.737-3.325,4.306,4.306,0,0,1,2.773-4.571,4.532,4.532,0,0,1,2.555-5.145,4.847,4.847,0,0,1,6.118,1.741,3.43,3.43,0,0,1,3.369-.039,3.326,3.326,0,0,1,1.628,3.167A4.228,4.228,0,0,1,2018.637,2007.592Zm-6.8,5.27-2.117,2.12-.024-7.9a.886.886,0,0,0-.871-.886h0a.884.884,0,0,0-.871.89l.024,7.874-2.1-2.1a.872.872,0,1,0-1.232,1.234l3.6,3.6a.871.871,0,0,0,1.232,0l3.6-3.6a.871.871,0,0,0-1.232-1.232Z"
                transform="translate(-1999.062 -1997.906)"
            />
        </svg>

        <small>Baixar planilha</small>
    </div>
</template>

<script>
export default {
    name: 'DownloadIcon',

    emits: ['click'],
}
</script>

<style lang="scss" scoped>
.download-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
        background-color: #ecf0fd;
        border-radius: 50%;

        .a {
            fill: $darker-blue;
        }
    }

    .a {
        fill: #b7b8b9;
    }

    small {
        @include easy-tooltip();
        width: 42px;
        line-height: 10px;
        right: -5px;
        height: 40px;
        padding-top: 17px;
        z-index: 2;
    }
}
</style>
