<template>
    <div class="task-card-header" :style="styles">
        <div class="img-container">
            <img :src="require(`../../../../assets/icons/${icon}.svg`)" :alt="icon" />
        </div>

        <div class="title">{{ title }}</div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
    color: {
        type: String,
        default: '#128FFF',
    },
    title: {
        type: String,
        required: true,
    },
})

const styles = computed(() => {
    return {
        '--bg-img-color': props.color,
    }
})
</script>

<style lang="scss" scoped>
.task-card-header {
    width: 100%;
    padding: 18px 16px;
    height: 20%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $lighter-grey;

    & > .img-container {
        background-color: var(--bg-img-color);
        height: 32px;
        width: 32px;
        @include flex-center();
        border-radius: 50%;

        img {
            width: 16px;
            height: 16px;
        }
    }

    & > .title {
        font-weight: 600;
        font-size: 16px;
        margin-left: 12px;
        color: $black;
    }
}
</style>
