<template>
    <div class="form-table">
        <div id="form-list" :style="{ overflowY: rows > 5 ? 'scroll' : 'hidden' }">
            <div class="header-grid">
                <div v-for="(header, index) in headers" :key="index" class="header">{{ header }}</div>
            </div>

            <form-row
                v-for="n in rows"
                :key="n"
                :transaction-type="step > 1 ? 'expenses' : 'revenues'"
                :category-type="categoryTypes[step - 1]"
                @update-transaction="(data) => $emit('update-transaction', { ...data, id: `step-${step}-${n}` })"
            />
        </div>

        <div class="new-line-btn">
            <button @click="rows++">
                <v-icon class="icon" size="14" dark> fas fa-plus </v-icon>
                nova linha
            </button>
        </div>
    </div>
</template>

<script>
import FormRow from './FormRow.vue'

export default {
    name: 'FormTable',

    components: {
        FormRow,
    },

    props: {
        step: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            rows: 5,
            categoryTypes: [undefined, 'essential', 'noessential', 'debts', 'investiment'],
        }
    },

    computed: {
        headers() {
            const array = ['data', 'categoria', 'valor', 'descrição', 'rep', 'parc']
            if (this.step > 1) array.push('pago')
            else array.push('receb')

            return array
        },
    },

    watch: {
        rows: {
            handler() {
                let scrollableDiv = document.getElementById('form-list')
                scrollableDiv.scrollTo(0, scrollableDiv.scrollHeight)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.form-table {
    #form-list {
        height: 290px;
        overflow-x: hidden;
        .header-grid {
            display: grid;
            grid-template-columns: 140px 2fr 150px 2fr 50px 50px 50px;
            column-gap: 8px;
            margin: 16px 0 8px;

            .header {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                color: $dark-grey;
                white-space: nowrap;
            }
        }
    }

    .new-line-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        button {
            background-color: #a0d2ff;
            color: white;
            font-size: 12px;
            font-weight: 400 !important;
            border-radius: 40px;
            padding: 12px 16px;

            .icon {
                margin-right: 12px;
            }
        }
    }
}
</style>
