<template>
    <div class="category-config">
        <div class="flex">
            <div class="flex category-config-box column">
                <div>
                    <h1>Categorias Personalizadas</h1>

                    <form @submit.prevent.self="checkForm" class="contend-form-box form-margin-bottom">
                        <div class="flex form-box space-between">
                            <div class="form-width">
                                <stacked-input
                                    class="grow-1"
                                    label="Nome da subcategoria"
                                    :errorValidation="error[0]"
                                    :errorMsg="msg"
                                    v-model="subcategory.name"
                                    name="subcategory"
                                />
                            </div>

                            <stacked-select
                                class="grow-1 space"
                                label="Categoria-mãe"
                                :errorValidation="error[1]"
                                :model="selectSetup.categories"
                                :isGroupSelect="selectSetup.isGroupSelect"
                                :previousSelected="previousCategory"
                                @change="updateParentSubcategory"
                                data-cy="select-parent-subcategory"
                            />
                        </div>

                        <div class="flex center toggle-switch-container">
                            <p class="toggle-label">Esta é uma categoria essencial</p>

                            <toggle-switch :toggle="subcategory.essential" @toggleEvent="toggleSwitch" />
                        </div>

                        <basic-button
                            data-cy="submit-new-category"
                            :label="buttonLabel"
                            type="submit"
                            :loading="disabled"
                            :width="240"
                        />
                    </form>
                </div>

                <div>
                    <h1>Suas categorias</h1>

                    <settings-list
                        :list="subcategories"
                        @deleteElementEvent="deleteSubcategory"
                        @edit-item="readyToEdit"
                    />
                </div>
            </div>
        </div>

        <confirmation-modal
            v-if="data"
            :data="data"
            text="Tem certeza que deseja excluir essa categoria"
            @closeModalEvent="closeConfirmationModal"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'

import BasicButton from '../../components/buttons/BasicButton.vue'
import StackedInput from '../../components/inputs/StackedInput.vue'
import StackedSelect from '../../components/inputs/StackedSelect.vue'
import ToggleSwitch from '../../components/inputs/ToggleSwitch.vue'
import SettingsList from '../../components/lists/SettingsList.vue'
import ConfirmationModal from '../../components/modals/confirmation-modals/ConfirmationModal.vue'

import request from '../../common/request'

export default {
    name: 'CategoryConfig',

    components: {
        StackedInput,
        StackedSelect,
        ToggleSwitch,
        SettingsList,
        ConfirmationModal,
        BasicButton,
    },

    data() {
        return {
            subcategory: {
                essential: false,
            },
            previousCategory: {},
            isEdit: false,
            group: '',
            error: [],
            msg: 'CAMPO OBRIGATÓRIO',
            data: null,
        }
    },

    computed: {
        ...mapState(mainStore, ['disabled', 'primaryCategories', 'subcategories', 'findById']),

        buttonLabel() {
            if (!this.isEdit) return 'Criar nova categoria'
            return 'Editar categoria'
        },

        selectSetup() {
            return {
                isGroupSelect: !this.isEdit,
                categories: this.primaryCategories(this.group),
            }
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        updateParentSubcategory(value) {
            this.subcategory.category = value
        },

        toggleSwitch(value) {
            this.subcategory.essential = value
        },

        createSubcategory() {
            let categoryId = this.subcategory.category._id || this.subcategory.category

            this.subcategory.group = this.findById('categories', categoryId).group
            this.DISABLED()

            request
                .post({ collection: 'categories', data: this.subcategory })
                .then(() => (this.subcategory = { essential: false }))
                .finally(() => this.DISABLED())
        },

        readyToEdit(data) {
            Object.assign(this.subcategory, data)
            this.previousCategory = data.category
            this.isEdit = true
            this.group = data.group
        },

        editSubcategory() {
            const { name, essential, category } = this.subcategory

            this.DISABLED()

            request
                .patch({ collection: 'categories', data: { name, essential, category }, _id: this.subcategory._id })
                .then(() => {
                    this.subcategory = { essential: false }
                    this.previousCategory = {}
                    this.isEdit = false
                    this.group = ''
                })
                .finally(() => this.DISABLED())
        },

        deleteSubcategory(params) {
            let payload = {
                data: {
                    _id: params.id,
                    _categoryId: params.categoryID._id,
                    collection: 'categories',
                },
                text: 'Tem certeza que deseja excluir essa categoria',
            }

            this.$root.$emit('confirmationDelete', payload)
        },

        closeConfirmationModal() {
            this.data = null
        },

        checkForm: function (e) {
            e.preventDefault()

            let error = Array(2).fill(false)
            let errorCount = 0

            if (!this.subcategory.name) {
                error[0] = true
                errorCount++
            } else if (this.subcategory.name === 'Outras rendas') {
                error[0] = true
                this.msg = 'NOME INVÁLIDO'
                errorCount++
            }
            if (!this.subcategory.category) {
                error[1] = true
                errorCount++
            }

            this.error = error

            if (!errorCount) {
                if (!this.isEdit) this.createSubcategory()
                else this.editSubcategory()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.category-config {
    padding-top: 41px;

    .category-config-box {
        width: 780px;

        h1 {
            @include section-title();
        }

        .category-type {
            margin-top: 15px;

            .radio-label {
                margin-left: 5px;
                margin-right: auto;
            }
        }

        .contend-form-box {
            padding: 0 0 27px 0;
            font-size: 14px;
            color: #999999;

            .form-box {
                margin-right: 70px;
            }

            .form-margin-top {
                margin-top: 33px;
            }

            .form-width {
                width: calc(50% - 11px);
            }

            .stacked-input {
                margin-top: 16px;
            }
        }

        .toggle-switch-container {
            margin: 33px 0 0 10px;

            .toggle-label {
                margin-right: 24px;
                color: #646464;
                font-size: 16px;
                line-height: 19px;
            }
        }

        .form-margin-bottom {
            margin-bottom: 15px;
        }

        .space {
            margin-left: 22px;
        }

        .basic-button {
            margin-top: 40px;
            position: relative;
        }
    }
}

.flex {
    display: flex;
}

.space-around {
    justify-content: space-around;
}

.column {
    flex-direction: column;
}

.center {
    align-items: center;
}

.grow-1 {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}
</style>
