import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import AccountRecovery from '../views/AccountRecovery.vue'
import AccountActivation from '../views/AccountActivation.vue'
import Dashboard from '../views/Dashboard.vue'
import Transactions from '../views/Transactions'
import Wallet from '../views/Wallet.vue'
import Planning from '../views/Planning.vue'
import Settings from '../views/Settings.vue'
import Objectives from '../views/Objectives.vue'
import ProfileConfig from '../views/settings/ProfileConfig.vue'
import CategoryConfig from '../views/settings/CategoryConfig.vue'
import PreferencesConfig from '../views/settings/PreferencesConfig.vue'
import BankAccessConfig from '../views/settings/BankAccessConfig.vue'
import FamilyAccount from '../views/settings/FamilyAccount.vue'
import PageModel from '../views/PageModel.vue'
import Empresas from '../views/Empresas.vue'
import PaymentsCalendar from '../views/PaymentsCalendar.vue'
import Report from '../views/Report.vue'
import Advisors from '../views/Advisors.vue'
import TermsOfUse from '../views/TermsOfUse.vue'
import Educational from '../views/educational/Main.vue'
import LearningTrails from '../views/educational/LearningTrails.vue'
import Ebooks from '../views/Ebooks.vue'
import Tasks from '../views/Tasks.vue'

import { mainStore } from '../store'

import auth from '../services/auth.service'
import request from '../common/request'
import _date from '../common/formatDate'
import authService from '../services/auth.service'
import variables from '../variables'
import moment from 'moment'

const beforeActiveRoutes = ['account-activation']

const ifNotAuthenticated = async (to, from, next) => {
    const store = mainStore()

    if (!auth.getOnLocalStorage('token')) {
        next()
        return
    } else {
        if (store.user.active) {
            next('/painel')
            return
        }
        next('/account-activation')
    }
}

const ifAuthenticated = async (to, from, next) => {
    const store = mainStore()

    if (auth.getOnLocalStorage('token')) {
        const notActive = !store.user.active && !beforeActiveRoutes.includes(to.name)

        if (notActive) {
            next('/account-activation')
            return
        }

        next()
        return
    }
    next('/login')
}

const ifAuthenticatedOrNot = (to, from, next) => {
    const store = mainStore()

    const urlSearchParams = new URLSearchParams(window.location.search)
    const token = urlSearchParams.get('token')

    if (auth.getOnLocalStorage('token') && !token) {
        next()
        return
    } else if (token) {
        store.LOGOUT()
        authService.clearLocalStorage()
        authService.saveOnLocalStorage('token', token)
        next()
        return
    }
    next('/login')
}

const getRoutes = () => {
    let index
    let routes = [
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/cadastro',
            name: 'cadastro',
            component: SignUp,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/account-recovery',
            name: 'account-recovery-request',
            component: AccountRecovery,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/account-recovery/:token',
            name: 'account-recovery',
            component: AccountRecovery,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/account-activation',
            name: 'account-activation',
            component: AccountActivation,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/empresas/activation',
            name: 'activation-corporate-plan',
            component: Empresas,
        },
        {
            path: '/termos-de-uso',
            name: 'terms-of-use',
            component: TermsOfUse,
        },
        {
            path: '/ebooks/:id',
            name: 'ebooks',
            component: Ebooks,
        },
        {
            path: '/',
            name: 'container',
            component: PageModel,
            redirect: '/painel',
            children: [
                {
                    path: '/painel',
                    name: 'dashboard',
                    component: Dashboard,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/movimentacoes',
                    name: 'transactions',
                    component: Transactions,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/movimentacoes/:payment',
                    name: 'payment_transactions',
                    component: Transactions,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/planejamento',
                    name: 'planning',
                    component: Planning,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/carteiras',
                    name: 'wallet',
                    component: Wallet,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/calendario',
                    name: 'calendar',
                    component: PaymentsCalendar,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/configuracoes',
                    redirect: '/configuracoes/perfil',
                    name: 'settings',
                    component: Settings,
                    children: [
                        {
                            path: '/configuracoes/perfil',
                            name: 'perfil',
                            component: ProfileConfig,
                            beforeEnter: ifAuthenticated,
                        },
                        {
                            path: '/configuracoes/categorias',
                            name: 'categorias',
                            component: CategoryConfig,
                            beforeEnter: ifAuthenticated,
                        },
                        {
                            path: '/configuracoes/preferencias',
                            name: 'preferencias',
                            component: PreferencesConfig,
                            beforeEnter: ifAuthenticated,
                        },
                        {
                            path: '/configuracoes/conta-familia',
                            name: 'family-account',
                            component: FamilyAccount,
                            beforeEnter: ifAuthenticated,
                        },
                        {
                            path: '/configuracoes/contas-automaticas',
                            name: 'bankaccess',
                            component: BankAccessConfig,
                            beforeEnter: ifAuthenticated,
                        },
                        {
                            path: '/configuracoes/contas-automaticas/:bankAccessId',
                            name: 'bankaccess_sync',
                            component: BankAccessConfig,
                            beforeEnter: ifAuthenticated,
                        },
                    ],
                },
                {
                    path: '/objetivos',
                    name: 'objectives',
                    component: Objectives,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/relatorio',
                    name: 'relatorio',
                    component: Report,
                    beforeEnter: ifAuthenticated,
                },
            ],
        },
    ]

    if (!variables.hideScreenEducational) {
        index = routes.findIndex((route) => route.name === 'container')
        routes[index].children.push(
            ...[
                {
                    path: '/educacional',
                    name: 'educational',
                    component: Educational,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: '/educacional/trilhas-de-aprendizado/:id',
                    name: 'learningTrails',
                    component: LearningTrails,
                    beforeEnter: ifAuthenticated,
                },
            ],
        )
    }

    if (!variables.hideScreenSpecialists) {
        index = routes.findIndex((route) => route.name === 'container')
        routes[index].children.push({
            path: '/especialistas',
            name: 'especialistas',
            component: Advisors,
            beforeEnter: ifAuthenticated,
        })
    }

    if (!variables.hideScreenTasks) {
        index = routes.findIndex((route) => route.name === 'container')
        routes[index].children.push({
            path: '/tarefas',
            name: 'tarefas',
            component: Tasks,
            beforeEnter: ifAuthenticated,
        })
    }

    return routes
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: getRoutes(),
})

router.beforeEach(async (to, from, next) => {
    const store = mainStore()

    const user = store.user
    const id = localStorage.getItem('id')

    verifyUsage()

    store.toggleSidebar(false)

    if (!user._id && id) {
        await request
            .getById({
                collection: 'users',
                _id: id,
            })
            .then((user) => localStorage.setItem('currency', user.currency || 'BRL'))
            .catch((error) => {
                console.error(error)
                auth.logout()
            })
    }

    if (!store.user.skipTutorial && to.name === 'dashboard' && from.name !== 'planning') next('/planejamento')
    next()
})

router.afterEach(async (to, from) => {
    const store = mainStore()

    const id = localStorage.getItem('id')

    if (!store.fetched && id) {
        const user = store.user
        store.WAITING(true)
        setDate()

        let extraQueries = {}
        if (store.plannerWithFamily) extraQueries.user = store.mainUserId

        if (user._id) {
            try {
                if (!beforeActiveRoutes.includes(to.name)) {
                    await request.fetch(['categories', 'payments'])

                    await request.fetch({
                        collection: 'invoices',
                        queryParams: store._date,
                    })

                    const paymentId = getPaymentId(to)

                    await request.fetch([
                        { collection: 'budgets', queryParams: store._date },
                        { collection: 'transactions', queryParams: store._date },
                        { collection: 'objectives' },
                        { collection: 'bankaccesses' },
                        { collection: 'families' },
                        { collection: 'linkedtransactions' },
                        { collection: 'profiles' },
                        { collection: 'educational', subroute: '/trails', attr: 'trails' },
                        { collection: 'userconfigs' },
                        { collection: 'tasks' },
                    ])

                    const family = store.isOttoFamily

                    await request.fetch([
                        {
                            collection: 'balancesChart',
                            queryParams: { ...store._date, paymentId, family, ...extraQueries },
                        },
                        { collection: 'balances', queryParams: { ...store._date, family, ...extraQueries } },
                    ])

                    store.FETCHED()
                }
            } catch (e) {
                console.error(e)
            }
        } else {
            auth.logout()
        }
    } else if (to.name !== 'login') {
        await resetStore(to, from)
    }

    if (store.waitingTime) store.WAITING()
})

async function resetStore(to, from) {
    const store = mainStore()

    const reset = ![
        'login',
        'account-recovery',
        'subscribe',
        'updateSubscription',
        'changeSubscriptionPlan',
        'changeSubscriptionPayment',
    ].includes(to.name)
    const notTransactionPage = to.name !== 'transactions' && to.name !== 'payment_transactions'
    const family = store.isOttoFamily

    let extraQueries = {}
    if (store.plannerWithFamily) extraQueries.user = store.mainUserId

    if (!_date.compareDates(store._date.inicio, store._date_selected.inicio)) {
        try {
            store.DISABLED()
            store.WAITING(true)

            if (to.name === 'transactions') {
                await request.fetch([
                    { collection: 'balances', queryParams: { ...store._date_selected, family, ...extraQueries } },
                    { collection: 'balancesChart', queryParams: { ...store._date_selected, family, ...extraQueries } },
                ])
            } else if (to.name === 'payment_transactions') {
                const paymentId = getPaymentId(to)

                await request.fetch([
                    { collection: 'balances', queryParams: { ...store._date_selected, paymentId } },
                    { collection: 'balancesChart', queryParams: { ...store._date_selected, paymentId } },
                ])
            } else if (reset) {
                await request.changeMonth(store._date, undefined, false, family, extraQueries.user)
                store.setSelectedDate(store._date)
            }
        } catch (e) {
            console.error(e)
        }

        store.DISABLED()
    } else {
        if (family && notTransactionPage && reset) {
            await request.fetch([
                { collection: 'balances', queryParams: { ...store._date_selected, family, ...extraQueries } },
                { collection: 'balancesChart', queryParams: { ...store._date_selected, family, ...extraQueries } },
            ])
        }
    }
}

function setDate() {
    const store = mainStore()
    let date = new Date()

    // salvando o mês atual
    let dataAtual = {
        inicio: _date.convertDate(date.getFullYear(), date.getMonth(), 1),
        fim: _date.convertDate(date.getFullYear(), date.getMonth() + 1, 0),
    }

    store.setAtualDate(dataAtual)
}

function getPaymentId(to) {
    const store = mainStore()

    return to.name === 'payment_transactions' && store.findById('payments', to.params.payment) !== 'credit'
        ? to.params.payment
        : undefined
}

function verifyUsage() {
    let lastReload = localStorage.getItem('last_reload')

    const reload = () => {
        localStorage.setItem('last_reload', new Date().toISOString())
        location.reload()
    }

    if (!lastReload) reload()
    else if (moment().diff(moment(lastReload), 'days')) reload()
}

export default router
