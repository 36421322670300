<template>
    <div class="flex column category-budget-list">
        <div class="flex-bubble row">
            <img :src="$variables.chatIcon" class="chat-otto-img" />

            <svg width="15px" height="10px" class="svg-path-otto">
                <polygon points="0,0 15,0 15,10" fill="white" class="otto-path" />
            </svg>

            <p class="otto-talk">
                Pronto! Quando esta categoria estiver próxima de atingir o limite estabelecido, você será avisado. Novas
                categorias podem ser adicionadas usando o botão ao lado.
            </p>
        </div>

        <div v-for="(spendingCard, index) in spendingCardData" :key="index">
            <spending-card
                v-if="budget[spendingCard.field].length > 0"
                :categoriesList="budget[spendingCard.field]"
                :percentage="totalPercentage"
                :revenues="revenuesValue"
                :cardType="spendingCard.cardType"
                :title="spendingCard.title"
                @toggleEdition="editBudget"
                :creation="true"
            />
        </div>

        <div v-if="budget.investiments.length">
            <spending-card
                :categoriesList="budget.investiments"
                :percentage="totalPercentage"
                :revenues="revenuesValue"
                :edit="isEdit"
                :investiments="true"
                title="Investimentos"
                @toggleEdition="editBudget"
                :creation="true"
            />
        </div>

        <category-budget-edition-modal
            v-if="modalVisible"
            @closeModalEvent="closeModal"
            :data="onEditCategory"
            :addedCategories="budget"
            :revenues="revenuesValue"
        />
    </div>
</template>

<script>
import SpendingCard from '../cards/SpendingCard.vue'
import CategoryBudgetEditionModal from '../modals/CategoryBudgetEditionModal.vue'

export default {
    name: 'CategoryBudgetList',

    components: {
        SpendingCard,
        CategoryBudgetEditionModal,
    },

    props: {
        budget: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            totalPercentage: 0,
            budgets: {},
            modalVisible: false,
            onEditCategory: {},

            spendingCardData: [
                {
                    field: 'revenuesCategories',
                    cardType: 'revenues',
                    title: 'Receitas',
                },
                {
                    field: 'essentialCategories',
                    title: 'Despesas Fixas',
                    cardType: 'essential',
                    subtitle: 'Aquelas cujo o valor não costuma variar a cadas mês',
                },
                {
                    field: 'noessentialCategories',
                    cardType: 'noessential',
                    title: 'Despesas Variáveis',
                    subtitle: 'Aquelas cujo o valor pode variar mensalmente',
                },
                {
                    field: 'debtsCategories',
                    cardType: 'debts',
                    title: 'Dívidas e parcelas',
                    subtitle: 'Parcelas de financiamento, de compras no cartão e outras dívidas',
                },
            ],
        }
    },

    methods: {
        editBudget(payload) {
            if (!this.budget.revenuesValue) payload.budgetValue = (payload.value / (this.expensesValue / 1)) * 100

            this.onEditCategory = payload
            this.modalVisible = true
        },

        closeModal(payload) {
            this.onEditCategory = payload
            this.$emit('editCategoryEvent', this.onEditCategory)
            this.totalPercentage = this.totalSpending
            this.modalVisible = false
        },
    },

    computed: {
        totalSpending() {
            let essential = this.budgets.essentialCategories
            let noessential = this.budgets.noessentialCategories
            let debts = this.budgets.debtsCategories
            let investiments = this.budgets.investiments

            return [...essential, ...noessential, ...debts, ...investiments].reduce((prevValue, category) => {
                return prevValue + category.budgetValue
            }, 0)
        },

        expensesValue() {
            let essential = this.budgets.essentialCategories
            let noessential = this.budgets.noessentialCategories
            let debts = this.budgets.debtsCategories
            let investiments = this.budgets.investiments

            return [...essential, ...noessential, ...debts, ...investiments].reduce((prevValue, category) => {
                return prevValue + category.value
            }, 0)
        },

        revenuesValue() {
            return this.budget.revenuesValue || this.expensesValue
        },
    },

    watch: {
        budget: {
            deep: true,
            immediate: true,
            handler() {
                this.budgets = structuredClone(this.budget)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.category-budget-list {
    max-width: 100%;
}

.column {
    flex-direction: column;
}

.flex {
    display: flex;
    flex-grow: 1;
}

.flex-bubble {
    display: flex;
    margin-bottom: 8px;
    &.column {
        flex-direction: column;
    }

    &.row {
        flex-direction: row;
        margin: 0;
    }
}
.otto-talk {
    left: -3px;
    background-color: #ffffff;
    text-align: left;
    margin-bottom: 8px;
    color: #5b6772;
}

.user-talk,
.otto-talk {
    position: relative;
    line-height: 18px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 30px;
    width: 75%;
}
.chat-otto-img,
.chat-user-img {
    position: relative;
    top: 200;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 1px 1px 2px #00000030;
    border: 1px solid $btn-blue;
}
</style>
