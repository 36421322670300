<template>
    <modal-frame
        :title="title"
        width="360px"
        header-color="#e8e7f8"
        close-btn-color="#9fa5b1"
        ext-btn-classes="flex"
        @close-modal="$emit('close-modal')"
    >
        <template #content>
            <stacked-input label="Título da tarefa" v-model="formData.title" :required="true" />
            <stacked-input label="Descrição breve" v-model="formData.description" :required="true" />
        </template>

        <template #btn-container>
            <basic-button
                :label="btnLabel"
                bgColor="#F05656"
                size="small"
                height="40px"
                width="100%"
                :loading="disabled"
                :disabled="btnDisabled || disableEdit || disabled"
                @click="saveTask"
            />

            <basic-button
                v-if="props.task._id"
                style="margin-left: 8px"
                label="concluir tarefa"
                bgColor="#F05656"
                :reverse="true"
                size="small"
                height="40px"
                width="100%"
                :loading="disabled"
                :disabled="btnDisabled || disabled"
                @click="finishTask"
            />
        </template>
    </modal-frame>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { mainStore } from '../../../store/index'

import ModalFrame from '../../../containers/ModalFrame.vue'
import StackedInput from '../../inputs/StackedInput.vue'
import BasicButton from '../../buttons/BasicButton.vue'

import request from '../../../common/request'

const store = mainStore()

const props = defineProps({
    task: {
        type: Object,
        default: () => ({}),
    },
})

const emit = defineEmits(['close-modal'])

const formData = ref({
    title: '',
    description: '',
})

const disabled = computed(() => store.disabled)
const btnDisabled = computed(() => !formData.value.title || !formData.value.description)

const title = computed(() => (props.task._id ? 'Editar tarefa' : 'Nova tarefa'))
const btnLabel = computed(() => (props.task._id ? 'Editar' : 'Salvar nova tarefa'))

const disableEdit = computed(() => !props.task.user && props.task._id)

function finishTask() {
    let { tasksAccepted, tasksDone } = store.userconfigs
    tasksAccepted.splice(
        tasksAccepted.findIndex((t) => t._id === props.task._id),
        1,
    )
    tasksDone.push(props.task._id)

    store.DISABLED()

    request
        .patch({ collection: 'userconfigs', _id: store.userconfigs._id, data: { tasksAccepted, tasksDone } })
        .catch((error) => {
            store.SET_MISC_DATA({
                attr: 'snackBarData',
                value: { message: 'Falha ao tentar concluir tarefa', bgColor: 'fail' },
            })
            console.error(error)
        })
        .finally(() => {
            store.DISABLED()
            store.SET_MISC_DATA({ attr: 'snackBarData', value: { message: 'Tarefa concluída com sucesso' } })
            emit('close-modal')
        })
}

function editTask() {
    store.DISABLED()

    request
        .patch({ collection: 'tasks', data: formData.value, _id: props.task._id })
        .then(() => request.fetch('userconfigs'))
        .catch((error) => {
            store.SET_MISC_DATA({
                attr: 'snackBarData',
                value: { message: 'Falha ao tentar editar tarefa', bgColor: 'fail' },
            })
            console.error(error)
        })
        .finally(() => {
            store.DISABLED()
            store.SET_MISC_DATA({ attr: 'snackBarData', value: { message: 'Tarefa editada com sucesso' } })
            emit('close-modal')
        })
}

function saveTask() {
    if (props.task._id) {
        editTask()
        return
    }

    store.DISABLED()

    request
        .post({ collection: 'tasks', data: { ...formData.value, user: store.user._id } })
        .catch((error) => {
            store.SET_MISC_DATA({
                attr: 'snackBarData',
                value: { message: 'Falha ao tentar criar tarefa', bgColor: 'fail' },
            })
            console.error(error)
        })
        .finally(() => {
            store.DISABLED()
            store.SET_MISC_DATA({ attr: 'snackBarData', value: { message: 'Tarefa criada com sucesso' } })
            emit('close-modal')
        })
}

onMounted(() => {
    if (props.task._id) {
        const { title, description } = props.task
        formData.value = { title, description }
    }
})
</script>

<style lang="scss" scoped>
.stacked-input {
    margin-top: 8px;

    &:first-child {
        margin-top: unset;
    }
}
</style>
