<template>
    <div class="spending-card flex column">
        <!-- LOADER -->
        <div v-if="waiting.is" style="height: 55px" class="title flex center space-between">
            <v-skeleton-loader :width="300" type="text" />
            <v-skeleton-loader style="margin-right: 30px" :width="100" type="text" />
        </div>

        <div v-else class="title flex center space-between">
            <div>
                <span class="title-type" style="font-weight: 500">{{ title }}</span>
                <span class="title-text"> {{ subtitle }}</span>
            </div>

            <div class="flex spended-value">
                <div class="title-type" style="white-space: nowrap">
                    {{ formatNum(incomes) }} ({{ budgetValueSomatory.toFixed() }}%)
                </div>

                <view-icon
                    v-if="!edit && !investiments"
                    style="margin-left: 8px"
                    @click="selectedCategory = { id: groupCategories, name: title, group: true, class: this.cardType }"
                />

                <a @click="show = !show" class="_arrow"
                    ><ion-icon :name="show ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon
                ></a>
            </div>
        </div>

        <!-- LOADER -->
        <div v-if="waiting.is" class="contend show">
            <div class="hide-border"></div>

            <div v-for="(n, index) in waiting.size" :key="index">
                <div class="_border"></div>

                <div class="flex center space-between spending">
                    <v-skeleton-loader type="avatar" class="list_avatar" />

                    <div class="flex item center space-between">
                        <div class="flex column sub-item center space-between">
                            <div class="flex info-progress-bar space-between">
                                <v-skeleton-loader max-width="150" :width="randomWidth(80)" type="text" />
                                <v-skeleton-loader max-width="110" :width="randomWidth(70)" type="text" />
                            </div>

                            <div style="width: 100%">
                                <div class="progress-bar">
                                    <v-skeleton-loader type="text" class="progress" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <v-skeleton-loader :width="100" type="text" />
                    </div>
                </div>
            </div>
        </div>

        <!-- CONTENT -->
        <div v-else class="contend" :class="{ show }">
            <div class="hide-border"></div>

            <div v-for="(cat, index) in categories" :key="cat._id">
                <div class="_border"></div>

                <div class="flex spending center space-between">
                    <div>
                        <img
                            :src="require(`@/assets/img/categories/${getImgUrl(cat.category)}`)"
                            class="avatar"
                            :class="[categoryDefaultName(cat.category), cat.category.group]"
                        />
                    </div>

                    <div class="flex item center space-between">
                        <div class="edit-progress-bar flex" v-if="edit">
                            <range-input
                                :label="cat.category.name"
                                :budgetID="cat._id"
                                v-model:hasError="err[index]"
                                :pValue="budgetValue(cat)"
                                :maxValue="100"
                                :currentIncome="receitas"
                                :notHasRevenues="notHasRevenues"
                                :noRange="cat.category.group === 'revenues'"
                                @change="updateCategoryBudget"
                            >
                                <template #progress-bar>
                                    <div class="progress-bar-border">
                                        <div
                                            class="progress-bar"
                                            :class="{
                                                active: compareValues(cat.category, budgetValue(cat)),
                                            }"
                                            :style="{
                                                width: calcPercentage(cat.value, incomes) + '%',
                                            }"
                                        ></div>
                                    </div>
                                </template>
                            </range-input>

                            <delete-icon v-show="!investiments" @click="() => deleteRow(cat)" />
                        </div>

                        <div class="flex column sub-item center space-between" v-else>
                            <div class="flex info-progress-bar space-between" :class="[edit ? 'info-edit' : '']">
                                <h1 class="info-title" :class="{ limited: smallScreen }">{{ cat.category.name }}</h1>

                                <span
                                    class="info-value"
                                    :class="{ active: compareValues(cat.category, budgetValue(cat)) }"
                                    >{{ formatNum(spended(cat.category)) }}
                                </span>
                            </div>

                            <div class="progress-bar-border" :class="[edit ? 'edit' : '']">
                                <div
                                    class="progress-bar"
                                    :class="{ active: compareValues(cat.category, budgetValue(cat)) }"
                                    :style="{
                                        width:
                                            calcPercentage(
                                                balancesPerCategory(cat.category._id, cat.category.group),
                                                calcBudgetValue(budgetValue(cat)),
                                            ) + '%',
                                    }"
                                ></div>
                            </div>
                        </div>

                        <div class="spend" :class="[edit ? 'edit' : '']">
                            {{ formatNum(calcBudgetValue(budgetValue(cat), cat.value)) }}
                        </div>

                        <div class="flex">
                            <a v-if="creation" @click="editBudget(cat)" style="padding: 0px"> <edit-icon /> </a>

                            <view-icon
                                v-else-if="!edit && !investiments"
                                @click="selectedCategory = { id: cat.category._id, name: cat.category.name }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlay" v-show="!hideCategoriesModal" v-if="!!selectedCategory">
            <modal-transition
                :transform="{
                    translateX: 0,
                    translateY: 100,
                }"
            >
                <categories-details-modal
                    v-if="!!selectedCategory"
                    :category="selectedCategory"
                    :type="cardType === 'revenues' ? cardType : 'expenses'"
                    :isPlanning="true"
                    :userId="selectedUser"
                    :showNav="false"
                    @closeCategoryModalEvent="selectedCategory = null"
                />
            </modal-transition>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import RangeInput from '../../components/inputs/RangeInput.vue'
import EditIcon from '../icons/EditIcon.vue'
import DeleteIcon from '../icons/DeleteIcon.vue'
import ViewIcon from '../icons/ViewIcon.vue'
import CategoriesDetailsModal from '../modals/CategoriesDetailsModal.vue'
import ModalTransition from '../transitions/ModalTransition.vue'

import util from '@/common/util'

export default {
    name: 'SpendingCard',

    components: {
        RangeInput,
        EditIcon,
        DeleteIcon,
        ViewIcon,
        CategoriesDetailsModal,
        ModalTransition,
    },

    props: {
        categoriesList: Array,
        revenues: Number,
        percentage: Number,
        length: Number,
        title: String,
        subtitle: String,
        edit: {
            type: Boolean,
            default: false,
        },
        waiting: {
            type: Object,
            default: () => {
                return {
                    is: false,
                }
            },
        },
        cardType: {
            type: String,
        },
        investiments: {
            type: Boolean,
            default: false,
        },
        creation: {
            type: Boolean,
            default: false,
        },
        notHasRevenues: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            receitas: this.revenues,
            categories: [],
            err: null,
            show: false,
            selectedCategory: null,
        }
    },

    methods: {
        formatNum: util.formatNumber,

        calcPercentage(spend, value) {
            if (value === 0 && spend !== 0) return 100
            else if (this.investiments && spend < 0) return 0
            return (Math.abs(spend) / value) * 100
        },

        calcBudgetValue(budgetValue, value) {
            return value || Number((this.revenues * budgetValue * 0.01).toFixed(2))
        },

        spended(category) {
            return Math.abs(this.balancesPerCategory(category._id, category.group))
        },

        compareValues(category, budgetValue) {
            let balance = this.balancesPerCategory(category._id, category.group)
            return Math.abs(balance) > this.calcBudgetValue(budgetValue)
        },

        sanitize(string) {
            return util.sanitize(string)
        },

        categoryDefaultName(category) {
            return util.sanitizeCategoryName(category)
        },

        getImgUrl(category) {
            return util.getImgUrl(category)
        },
        randomWidth(base) {
            return util.randomWidth(base)
        },

        editBudget(payload) {
            this.$emit('toggleEdition', payload)
        },

        cancelEdition() {
            this.instanceCategoriesList()
            this.$emit('cancelEdition')
        },

        updateCategoryBudget(payload) {
            let otherPayload = {
                categoryBudget: payload,
                mode: 'edit',
            }

            const index = this.categories.findIndex((categoryBudget) => {
                return categoryBudget._id === payload._id
            })

            this.categories[index].value = payload.value
            if (this.cardType === 'revenues')
                this.categories[index].budgetValue = otherPayload.categoryBudget.budgetValue =
                    (payload.value / this.incomes || 1) * 100
            else this.categories[index].budgetValue = otherPayload.categoryBudget.budgetValue

            otherPayload.categoryBudget.category = this.categories[index].category
            otherPayload.type = this.cardType

            switch (this.cardType) {
                case 'essential':
                    otherPayload.essentialSpending = this.budgetValueSomatory
                    break
                case 'noessential':
                    otherPayload.noessentialSpending = this.budgetValueSomatory
                    break
                case 'debts':
                    otherPayload.debtsSpending = this.budgetValueSomatory
                    break
                case 'investiment':
                    otherPayload.investimentSpending = payload.budgetValue
            }

            this.$emit('updateBudgetEvent', otherPayload)
        },

        deleteRow(categoryBudget) {
            this.$emit('delete-row', {
                _id: categoryBudget._id,
                category: categoryBudget.category._id,
                type: this.cardType,
                mode: 'delete',
            })
        },

        // Prevenir warning do Vuex
        instanceCategoriesList() {
            this.categories = []
            this.categoriesList.forEach((category) => {
                this.categories.push({ ...category })
            })

            this.categories.sort((a, b) =>
                a.category.name > b.category.name ? 1 : a.category.name < b.category.name ? -1 : 0,
            )
            this.err = Array(this.categoriesList.length).fill(false)
        },

        budgetValue(planningCategory) {
            return (planningCategory.value / (this.revenues || 1)) * 100
        },
    },

    computed: {
        ...mapState(mainStore, [
            'balancesPerCategory',
            'getDefaultCategoryById',
            'user',
            'hideCategoriesModal',
            'smallScreen',
            'selectedUser',
        ]),

        budgetValueSomatory() {
            const result = this.incomes / this.revenues
            return result === Infinity || isNaN(result) ? 0 : result * 100
        },

        incomes() {
            return this.categories.reduce((prevValue, budgetCat) => {
                return prevValue + budgetCat.value
            }, 0)
        },

        groupCategories() {
            return this.categoriesList.map((cat) => cat.category._id)
        },
    },

    watch: {
        revenues(r) {
            this.receitas = r
        },

        categoriesList: {
            deep: true,
            handler() {
                if (this.categoriesList) this.instanceCategoriesList()
            },
        },

        edit() {
            if (this.edit) this.show = true
        },
    },

    mounted() {
        if (this.categoriesList) this.instanceCategoriesList()
    },
}
</script>

<style lang="scss" scoped>
.spending-card {
    max-width: 100%;
    margin-bottom: 16px;
}

.spend {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: $blueish-gray;
}

._border {
    box-sizing: border-box;
    border: 1px solid #eef1f6;
}

.range-input {
    position: relative;
    margin-right: 20px;
}

.edit-progress-bar {
    width: 100%;
    position: relative;
    align-items: center;
}

.hide-border {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 5px;
    background-color: white;
}

.spending {
    padding: 20px 0;
}

.info-progress-bar {
    width: 100%;
    margin-bottom: 8px;

    .info-title {
        font-size: 16px;
        line-height: 18px;
        color: #4a4a4a;
    }
    .info-value {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: right;
        color: $light-blue;

        &.active {
            color: $expense-red;
        }
    }
}

.info-edit {
    padding-right: 56px;
}

.range-input-container {
    width: 100%;
    padding-right: 56px;
}

.progress-bar-border {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.15);
    position: relative;

    .progress-bar {
        max-width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: $btn-blue;
        animation: fill ease-in-out 1s;
        transition: width 0.5s ease;

        &.active {
            background-color: $expense-red;
        }

        @keyframes fill {
            0% {
                max-width: 0;
            }
            100% {
                max-width: 100%;
            }
        }
    }
}

._arrow {
    padding-left: 8px;

    .show {
        transform: rotate(180deg);
    }
}

.contend {
    padding: 0 24px 0 23px;
    border-radius: 0 0 2px 2px;
    background-color: #ffffff;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
    font-size: 16px;
    line-height: 19px;
    position: relative;
    height: 0;
    overflow: hidden;

    &.show {
        height: 100%;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.title {
    padding: 16px 16px 16px 20px;
    border-radius: 2px 2px 0 0;
    background-color: $list-blue;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    color: $blueish-gray;

    &.error {
        background-color: $expense-red;
        color: white;
    }

    .title-type {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }

    .title-text {
        font-size: 10px;
        line-height: 13px;
        margin-left: 8px;
    }

    .ion-md-close {
        color: #bbbcbf;
    }

    .icon.error {
        color: white;
    }
}

.item {
    width: 100%;
    padding-left: 16px;

    .sub-item {
        width: 80%;
        margin-right: 16px;
    }
}

.edit {
    display: none;
}

.center {
    align-items: center;
}

.flex-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.row {
    flex-direction: row;
}

.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

a {
    color: #848484;
    font-size: 25px;
    padding-left: 20px;
}

span {
    font-size: 13px;
    line-height: 14px;
}

img {
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
    vertical-align: middle;
}

@media screen and (max-width: 700px) {
    .spending-card {
        & > .title {
            flex-direction: column;

            .spended-value {
                margin-top: 10px;
                width: 100%;
                justify-content: space-between;
            }

            .title-type,
            .title-text {
                margin: 0;
            }
        }
    }

    .item {
        .info-progress-bar {
            .info-title.limited {
                max-width: 70%;
            }
        }

        .spend {
            width: 0;
        }
    }
}
</style>
import { categoriesList } from '../../common/lists' import { map } from 'core-js/core/array'
