<template>
    <form @submit.prevent.self="validate" class="deposit-modal">
        <ul>
            <li>
                <h4>Valor Total</h4>
                <span>{{ formatNum(objective.totalValue) }}</span>
            </li>

            <li>
                <h4>Prazo</h4>
                <span>{{ prazo }}</span>
            </li>
        </ul>

        <div class="input-group">
            <stacked-input :readonly="true" type="date" label="Data" color="#F4F0F8" v-model="deposit.date" />

            <stacked-input
                label="Valor parcela"
                color="#F4F0F8"
                ref="value"
                key="value"
                :errorMsg="errorMsg.value"
                v-model="deposit.value"
                name="money"
                :isMoney="true"
            />
        </div>

        <stacked-select
            label="Conta de destino"
            :disabled="true"
            :isGroupSelect="true"
            color="#F4F0F8"
            :model="groupedPayments()"
            :previousSelected="payment"
            @change="(v) => (deposit.payment = v)"
        />

        <basic-button
            data-cy="deposit-payment"
            label="Depositar Parcela"
            type="submit"
            :loading="disabled"
            bgColor="#fa6437"
            width="100%"
            :extraStyles="{ marginTop: '-8px' }"
        />
    </form>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import StackedInput from '../inputs/StackedInput.vue'
import StackedSelect from '../inputs/StackedSelect.vue'
import util from '../../common/util'
import BasicButton from '../buttons/BasicButton.vue'

import formatDate from '../../common/formatDate'

export default {
    components: { StackedInput, StackedSelect, BasicButton },

    name: 'DepositObjectivePayment',

    props: {
        objective: Object,
        prazo: String,
    },

    data() {
        return {
            deposit: {},
            payment: '',
            errorMsg: {
                bankingOperationPayment: undefined,
                value: undefined,
            },
        }
    },

    methods: {
        formatNum(number) {
            return util.formatNumber(number)
        },

        validate() {
            let validator = false

            let restante = this.objective.totalValue - this.objective.paidValue - this.objective.initialValue
            let value = Number(util.sanitizeMoney(this.deposit.value))

            if (!value) {
                this.$refs.value.err = validator = true
            } else if (value > restante) {
                this.$refs.value.err = validator = true
                this.errorMsg.value = 'VALOR MUITO ALTO'
            } else {
                this.$refs.value.err = false
                this.errorMsg.value = undefined
            }

            if (!validator) this.$emit('deposit', this.deposit)
        },
    },

    computed: {
        ...mapState(mainStore, ['disabled', 'groupedPayments', 'findById']),

        disponiblePayments() {
            let list = this.groupedPayments(undefined, true)

            if (list[0].data[0]._id === this.deposit.payment) list[0].data.splice(0, 1)

            let index = list[1].data.findIndex((payment) => payment._id === this.deposit.payment)
            if (index > -1) list[1].data.splice(index, 1)

            return list
        },
    },

    mounted() {
        let { payment, installment } = { ...this.objective }

        this.payment = payment
        this.deposit.value = installment.toFixed(2)
        this.deposit.date = formatDate.inputFormatDate(new Date())
    },
}
</script>

<style lang="scss" scoped>
.deposit-modal {
    & > *:nth-child(1n + 2) {
        margin-top: 25px;
    }

    & > *:last-child {
        margin-top: 110px;
    }

    ul {
        display: grid;
        grid-template-columns: 50% 50%;

        li {
            padding: 10px 0 20px 20px;

            h4 {
                font-size: 13px;
                line-height: 16px;
                color: $medium-grey;
                font-weight: 500;
            }

            span {
                color: $light-black;
            }
        }
    }

    .input-group {
        display: flex;
        justify-content: space-between;

        & > * {
            width: 47%;
        }
    }
}
</style>
