<template>
    <div class="upload-icon" @click="$emit('click')">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.957" height="19.977" viewBox="0 0 22.957 19.977">
            <path
                class="a"
                d="M22.971,2237.3a4.891,4.891,0,0,1-5.174,4.532H15.47a.978.978,0,0,1-1-.983.955.955,0,0,1,1-.94h0s.8-.043,2.312-.043a2.956,2.956,0,0,0,3.183-2.724,3.045,3.045,0,0,0-2.9-3.191,1,1,0,0,1-.831-1.214,2.125,2.125,0,0,0-.791-2.527,2.145,2.145,0,0,0-2.547.479,1,1,0,0,1-1.579-.25c-1.434-2.709-3.672-2.856-5.151-2.158a3.333,3.333,0,0,0-1.676,4.458,1,1,0,0,1-.626,1.34c-2.971.915-2.911,2.913-2.821,3.492a2.5,2.5,0,0,0,2.395,2.218l3.111.03a1.02,1.02,0,0,1,1,1.028,1.043,1.043,0,0,1-1,1.045h0l-3.106-.067a4.518,4.518,0,0,1-4.365-3.962,5.029,5.029,0,0,1,3.263-5.351,5.293,5.293,0,0,1,2.993-6.044,5.679,5.679,0,0,1,7.162,2.048,3.992,3.992,0,0,1,3.946-.06,3.9,3.9,0,0,1,1.9,3.722,4.912,4.912,0,0,1,3.627,5.121Zm-7.945.818a1,1,0,0,0,1.414-1.409l-4.226-4.24a1,1,0,0,0-1.412,0l-4.226,4.24a1,1,0,0,0,1.414,1.409l2.507-2.531.027,9.324a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l-.027-9.3Z"
                transform="translate(-0.028 -2225.937)"
            />
        </svg>

        <small>Carregar OFX</small>
    </div>
</template>

<script>
export default {
    name: 'UploadIcon',

    emits: ['click'],
}
</script>

<style lang="scss" scoped>
.upload-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
        background-color: #ecf0fd;
        border-radius: 50%;

        .a {
            fill: $darker-blue;
        }
    }

    .a {
        fill: #b7b8b9;
    }

    small {
        @include easy-tooltip();
        width: 42px;
        line-height: 10px;
        right: -5px;
        height: 40px;
        padding-top: 17px;
        z-index: 2;
    }
}
</style>
