<template>
    <div class="educational view-container">
        <div class="view-content">
            <action-bar title="Educação Financeira" />

            <p>
                Aqui você encontra desde conceitos básicos sobre finanças pessoais até dicas e conhecimentos avançados,
                para você se tornar um expert no assunto
            </p>

            <trail v-bind="videosTrail" @show-video="playVideo" />

            <trail
                style="margin-top: 36px"
                v-for="(learningTrail, index) in learningTrails"
                v-bind="learningTrail"
                :key="index"
                @open="$router.push('/educacional/trilhas-de-aprendizado/' + learningTrail._id)"
            />

            <trail style="margin-top: 36px" v-bind="blogTrail" />

            <trail style="margin-top: 36px" v-bind="ebookTrail" />
        </div>

        <div v-show="!!selectedVideo" class="overlay" @click="selectedVideo = ''">
            <div class="embed-video">
                <youtube-iframe v-bind="ytIframeData" :video-id="selectedVideo" />
            </div>
        </div>
    </div>
</template>

<script>
import { YoutubeIframe } from '@vue-youtube/component'
import { mapState, mapActions } from 'pinia'
import { mainStore } from '../../store'

import { environment } from '../../common/environment'
import ActionBar from '../../components/ActionBar.vue'
import Trail from '../../components/educational/Trail.vue'

import apiService from '../../services/api.service'

import ebooks from '../../assets/documents/ebooks/data'

export default {
    name: 'Educational',

    components: { ActionBar, Trail, YoutubeIframe },

    data() {
        return {
            selectedVideo: '',

            videosTrail: {
                title: 'Vídeos',
                description: 'Informação clara e descontraída',
                data: [],
                locked: false,
                loading: true,
                showProgressBar: false,
            },

            blogTrail: {
                title: `Blog ${this.$variables.brandWithArtigo('d')}`,
                description: 'Conteúdo selecionado do nosso blog com dicas de como gerir suas finanças',
                showProgressBar: false,
                locked: false,
                loading: true,
                type: 'post',
            },

            ebookTrail: {
                title: 'Ebooks',
                showProgressBar: false,
                locked: false,
                type: 'ebook',
                data: ebooks,
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['smallScreen', 'educationalVideos', 'trails']),

        ytIframeData() {
            if (this.smallScreen)
                return {
                    width: '100%',
                    height: 200,
                }

            return { width: 1024, height: 576 }
        },

        learningTrails() {
            return this.trails.map((trail) => {
                return {
                    ...trail,
                    data: trail.items.map((item) => ({
                        ...item,
                        imgUrl: item.documents[0]?.imgUrl,
                        duration: item.documents[0]?.duration,
                        videoId: item.documents[0]?.videoId,
                    })),
                    loading: false,
                    locked: false,
                    type: 'presentation-video',
                }
            })
        },
    },

    methods: {
        ...mapActions(mainStore, ['SET_DATA']),

        playVideo(videoId) {
            this.selectedVideo = videoId
        },

        loadBlogPosts() {
            apiService
                .get(`${environment.API_URL}educational/blog`)
                .then((res) => {
                    if (res.data) {
                        this.blogTrail.data = res.data.map((post) => {
                            let url = ''
                            if (post?._embedded['wp:featuredmedia']) {
                                if (post?._embedded['wp:featuredmedia'][0]) {
                                    url = post?._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url
                                } else {
                                    url = ''
                                }
                            } else {
                                url = ''
                            }

                            return {
                                id: post.id,
                                imgUrl: url,
                                title: post.title.rendered,
                                link: post.link,
                            }
                        })
                    }
                })
                .catch(console.error)
                .finally(() => (this.blogTrail.loading = false))
        },

        loadContentVideos() {
            apiService
                .get(`${environment.EDUCATIONAL}/videos`)
                .then((response) => {
                    this.SET_DATA({ data: response.data, attr: 'educationalVideos' })
                    this.videosTrail.data = response.data.contendVideo
                })
                .catch(console.error)
                .finally(() => {
                    this.videosTrail.loading = false
                    this.videosTrail.locked = !this.videosTrail.data.length
                })
        },
    },

    mounted() {
        this.loadContentVideos()
        this.loadBlogPosts()
    },
}
</script>

<style lang="scss" scoped>
@import url('./styles.scss');
</style>
