import util from './util'

const byDate = (a, b) => {
    return a.date > b.date ? 1 : a.date < b.date ? -1 : 0
}

const byName = (a, b) => {
    const [aName, bName] = [a.name.normalize('NFD'), b.name.normalize('NFD')]
    return aName > bName ? 1 : aName < bName ? -1 : 0
}

const byValue = (a, b) => {
    const [aValue, bValue] = [Math.abs(a.value), Math.abs(b.value)]
    return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
}

const byPaymentType = (a, b) => {
    const values = { credit: 3, poupanca: 2, contacorrente: 1 }

    const typeA = util.sanitize(a.accountType ? a.accountType : a.type)
    const typeB = util.sanitize(b.accountType ? b.accountType : b.type)

    return values[typeA] > values[typeB] ? 1 : values[typeA] < values[typeB] ? -1 : 0
}

const byDateAndValue = (a, b) => {
    return a.date > b.date ? 1 : a.date < b.date ? -1 : byValue(a, b)
}

export default {
    byDate,
    byName,
    byPaymentType,
    byValue,
    byDateAndValue,
}
