<template>
    <div class="transactions-list">
        <h1>{{ cardTitle }}</h1>

        <ul class="t-list" v-if="waiting">
            <li v-for="(n, index) in 5" style="height: 60px" :key="index">
                <div class="list-item" style="padding: 0">
                    <div class="left-col">
                        <v-skeleton-loader :type="types.head" style="margin: auto" class="head" />

                        <v-skeleton-loader
                            v-if="listType === 'transactions'"
                            :type="types.arms"
                            style="max-width: 100px"
                            :width="randomWidth(50)"
                            class="arms"
                        />

                        <v-skeleton-loader v-else type="text" style="max-width: 150px" :width="randomWidth(100)" />
                    </div>

                    <div v-if="listType === 'transactions'" class="value-payment" style="margin-top: 4px">
                        <v-skeleton-loader
                            :type="types.foot"
                            style="max-width: 80px"
                            :width="randomWidth(40)"
                            class="foot"
                        />
                    </div>
                </div>

                <div class="_border"></div>
            </li>
        </ul>

        <transition-group
            name="list"
            tag="ul"
            v-else-if="!!sortedTransactions.length && !waiting && listType === 'transactions'"
            class="t-list"
        >
            <li v-for="transaction in sortedTransactions" :key="transaction._id">
                <div class="list-item">
                    <div class="left-col">
                        <div class="item-icons">
                            <div
                                class="list-item_icon"
                                :class="[
                                    transaction.paid ? sanitizeCategoryName(transaction.category) : 'unpaid',
                                    transaction.category.group,
                                ]"
                                :style="{
                                    backgroundImage:
                                        'url(' +
                                        require('@/assets/img/categories/' + getImgUrl(transaction.category)) +
                                        ')',
                                }"
                            ></div>

                            <div v-if="isOttoFamily" class="family-user">
                                <avatar :avatar="transaction.user.image" :letter="transaction.user.name.charAt(0)" />
                            </div>
                        </div>

                        <div class="item-category">
                            <span>{{ transaction.name }}</span>
                            <h4>
                                {{ transaction.category.name }}
                            </h4>
                        </div>
                    </div>

                    <div class="value-payment">
                        <h3 class="expenses">{{ formatNum(Math.abs(transaction.value)) }}</h3>
                        <h4>{{ transaction.payment.name }}</h4>
                    </div>
                </div>
            </li>
        </transition-group>

        <transition-group
            name="list"
            tag="ul"
            v-else-if="!!sortedTransactions.length && !waiting && listType !== 'transactions'"
            class="t-list"
        >
            <li v-for="(t, index) in list" :key="index">
                <div class="done-task">
                    <div :class="[t.icon]">
                        <v-icon v-show="t.icon === 'success'"> mdi-checkbox-marked-circle </v-icon>
                        <v-icon v-show="t.icon === 'failure'"> mdi-alert-circle </v-icon>
                        <v-icon v-show="t.icon === 'waiting'"> mdi-clock-time-five </v-icon>
                    </div>

                    <p>{{ t.title }}</p>
                </div>

                <div class="_border"></div>
            </li>
        </transition-group>

        <ul v-else>
            <li class="t-list">
                <div class="list-item">
                    <p class="no-data">{{ emptyText }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import Avatar from '../icons/Avatar.vue'

import sort from '@/common/sort'
import util from '@/common/util'

export default {
    components: { Avatar },

    name: 'DashboardList',

    props: {
        cardTitle: {
            type: String,
            required: true,
        },
        emptyText: {
            type: String,
            default: 'Nenhum pagamento para amanhã',
        },
        transactionType: {
            type: String,
        },
        list: {
            type: Array,
            default: () => [],
        },
        user: {
            type: Object,
            required: true,
        },
        listType: {
            type: String,
            default: 'transactions',
        },
    },

    data() {
        return {
            types: {
                head: 'avatar',
                arms: 'sentences',
                foot: 'sentences',
            },
        }
    },

    methods: {
        formatNum(number) {
            return util.formatNumber(number)
        },

        sanitize(string) {
            return util.sanitize(string)
        },

        sanitizeCategoryName(category) {
            return util.sanitizeCategoryName(category)
        },

        getImgUrl(category) {
            return util.getImgUrl(category)
        },

        randomWidth(base) {
            return util.randomWidth(base)
        },
    },

    computed: {
        ...mapState(mainStore, ['waiting', 'getDefaultCategoryById', 'isOttoFamily']),

        sortedTransactions() {
            return this.list.sort(sort.byDate).slice(0, 5)
        },
    },
}
</script>

<style lang="scss" scoped>
.transactions-list {
    @include rounded-box();
    @include box-shadow();
    overflow: hidden;

    h1 {
        padding: 22px 24px 16px;
        @include card-title();
        border-bottom: 2px solid #eef1f6;
    }

    ._border {
        box-sizing: border-box;
        border-bottom: 1px solid #eef1f6 !important;
    }

    .done-task {
        height: 59.2px;
        display: flex;
        align-items: center;

        p {
            margin-left: 8px;
            font-size: 14px;
            color: $black;
            font-weight: 400;
        }

        .success,
        .failure,
        .waiting {
            font-size: 24px;
            border: none;
            box-shadow: none;
        }

        .success {
            color: #2dc785;
        }

        .failure {
            color: $hint-red;
        }

        .waiting {
            color: $gold;
        }
    }

    .t-list {
        margin: 0 15px 0 15px;

        li:last-child {
            ._border {
                display: none;
            }
        }

        h2 {
            padding: 6px;
            background-color: $lighter-grey;
            @include date-title();
            text-align: center;

            .capital-begin {
                text-transform: capitalize;
            }
        }

        .list-item {
            @include flex-between();
            padding: 12px 0;
            background-color: white;
            max-width: 100%;

            .no-data {
                font-size: 14px;
                color: $title-grey;
                text-align: center;
            }

            .left-col {
                @include flex-between();

                .item-category {
                    display: inline-block;
                    width: 100px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-left: 10px;
                }
            }

            .item-icons {
                margin-right: 8px;
                position: relative;

                .list-item_icon {
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    border-radius: 50%;
                }

                .family-user {
                    position: absolute;
                    left: 15px;
                    top: -1px;
                    display: inline-block;
                    background: transparent;
                    border-radius: 50%;
                    overflow: hidden;
                    z-index: 1;
                    transform: scale(0.8);
                }
            }

            .item-category h3 {
                margin-bottom: 4px;
                @include list-item();
                letter-spacing: initial;
            }

            .item-category h4,
            .value-payment h4 {
                margin-top: 4px;
                @include list-category();
                letter-spacing: initial;
            }

            .expenses {
                color: $expense-red;
            }

            .revenues {
                color: $revenue-green;
            }

            .investments {
                color: $light-blue;
            }

            .value-payment {
                text-align: right;

                h3 {
                    margin-bottom: 4px;
                    @include std-value();
                }
            }
        }
    }
}
</style>
