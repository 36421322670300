<template>
    <div class="tasks view-container">
        <div class="view-content">
            <ActionBar title="Planos de ação" />

            <div class="description">
                <span>{{ `${$variables.brandWithArtigo()}` }}</span>
                sugere ações para você mudar sua vida financeira. Sugeridas por inteligência artificial com base em
                otimização de orçamento de mais de 10.000 brasileiros. Aceite uma ação agora mesmo para você começar a
                ver melhorias.
            </div>

            <div class="cards-container">
                <task-card v-bind="c" v-for="(c, index) in cards" :key="index" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { mainStore } from '../store'

import ActionBar from '../components/ActionBar.vue'
import TaskCard from '../components/cards/task-card/TaskCard.vue'

import variables from '../variables'

const store = mainStore()

const cards = computed(() => {
    const components = [
        {
            headerProps: {
                title: 'Novas tarefas adicionadas',
                icon: 'pin',
            },
            components: {
                list: true,
                footer: true,
                header: true,
            },
            tasks: store.newTasks.reverse(),
        },
        {
            headerProps: {
                title: 'Suas conquistas',
                icon: 'trophy',
                color: '#F16F29',
            },
            components: {
                achievements: true,
                header: true,
            },
        },
        {
            headerProps: {
                title: 'Tarefas pendentes',
                icon: 'pin',
                color: '#e6c01f',
            },
            components: {
                doneList: true,
                header: true,
            },
            tasks: store.tasksAccepted.reverse(),
            listType: 'waiting',
        },
        {
            headerProps: {
                title: 'Últimas tarefas cumpridas',
                icon: 'pin',
                color: '#1EBB9C',
            },
            components: {
                doneList: true,
                header: true,
            },
            tasks: store.tasksDone.reverse(),
        },
        {
            headerProps: {
                title: 'Últimas tarefas recusadas',
                icon: 'pin',
                color: '#E80B0B',
            },
            components: {
                doneList: true,
                header: true,
            },
            tasks: store.tasksRejected.reverse(),
            listType: 'failure',
        },
    ]

    if (variables.contactPhone)
        components.push({
            components: {
                help: true,
            },
        })

    return components
})
</script>

<style lang="scss" scoped>
.tasks {
    .description {
        margin-bottom: 32px;

        span {
            text-transform: capitalize;
        }
    }

    .cards-container {
        display: flex;
        flex-wrap: wrap;

        .task-card {
            margin-left: 24px;
            margin-bottom: 24px;

            &:nth-child(4),
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .task-card {
        margin: 0 auto 24px !important;
    }
}
</style>
