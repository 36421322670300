<template>
    <div class="planning-details-list" :style="styles">
        <div class="titles-grid">
            <div class="grid-item" style="height: 48px; cursor: default"></div>

            <div v-for="(title, index) in titles" :key="index" class="grid-item">
                <div :class="['title', { noevents: index > 4 }]" @click="() => showRow(index)">
                    <a v-if="index < 5" class="_arrow"
                        ><ion-icon :name="show[index] ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon
                    ></a>
                    <p>{{ title }}</p>
                </div>

                <div v-if="index < 5" style="width: 100%">
                    <div
                        v-for="category in categoriesLists[index].categories"
                        v-show="show[index]"
                        :key="category.name"
                        class="title category"
                    >
                        <img
                            :src="require('@/assets/img/categories/' + getImgUrl(category))"
                            :alt="category.name"
                            :class="[sanitizeCategoryName(category)]"
                        />
                        <p>{{ category.name === 'Telefone/Internet' ? 'Telefone / Internet' : category.name }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="scroll-grid">
            <div>
                <div class="header-grid">
                    <div v-for="header in headers" :key="header" class="grid-item">{{ header }}</div>
                </div>

                <!-- <div v-if="waiting" class="grid-container loader-container">
                    <div v-for="n in 36" :key="n" class="grid-item loader-item"><v-skeleton-loader type="text" /></div>
                </div> -->

                <div class="grid-container">
                    <div v-for="(item, index) in revenuesItems" :key="`revenues-${index}`" :class="['grid-item']">
                        {{ formatData(item) }}
                    </div>
                </div>

                <div
                    v-for="(category, _index) in categoriesLists[0].categories"
                    v-show="show[0]"
                    :key="category._id"
                    class="categories-grid"
                >
                    <div
                        v-for="(value, index) in category.balance"
                        :key="`${category.name}-${index}`"
                        :class="['grid-item', { first: !_index }]"
                    >
                        {{ formatData(value) }}
                    </div>
                </div>

                <div class="grid-container">
                    <div
                        v-for="(item, index) in essentialItems"
                        :key="`essential-${index}`"
                        :class="['grid-item', { red: item !== 0 }, 'grey']"
                    >
                        {{ formatData(item) }}
                    </div>
                </div>

                <div
                    v-for="(category, _index) in categoriesLists[1].categories"
                    v-show="show[1]"
                    :key="category._id"
                    class="categories-grid"
                >
                    <div
                        v-for="(value, index) in category.balance"
                        :key="`${category.name}-${index}`"
                        :class="['grid-item', { first: !_index }]"
                    >
                        {{ formatData(value) }}
                    </div>
                </div>

                <div class="grid-container">
                    <div
                        v-for="(item, index) in noessentialitems"
                        :key="`noessential-${index}`"
                        :class="['grid-item', { red: item !== 0 }]"
                    >
                        {{ formatData(item) }}
                    </div>
                </div>

                <div
                    v-for="(category, _index) in categoriesLists[2].categories"
                    v-show="show[2]"
                    :key="category._id"
                    class="categories-grid"
                >
                    <div
                        v-for="(value, index) in category.balance"
                        :key="`${category.name}-${index}`"
                        :class="['grid-item', { first: !_index }]"
                    >
                        {{ formatData(value) }}
                    </div>
                </div>

                <div class="grid-container">
                    <div
                        v-for="(item, index) in debtsItems"
                        :key="`debts-${index}`"
                        :class="['grid-item', { red: item !== 0 }, 'grey']"
                    >
                        {{ formatData(item) }}
                    </div>
                </div>

                <div
                    v-for="(category, _index) in categoriesLists[3].categories"
                    v-show="show[3]"
                    :key="category._id"
                    class="categories-grid"
                >
                    <div
                        v-for="(value, index) in category.balance"
                        :key="`${category.name}-${index}`"
                        :class="['grid-item', { first: !_index }]"
                    >
                        {{ formatData(value) }}
                    </div>
                </div>

                <div class="grid-container">
                    <div
                        v-for="(item, index) in investimentsItems"
                        :key="`investiments-${index}`"
                        :class="['grid-item', { red: item !== 0 }]"
                    >
                        {{ formatData(item) }}
                    </div>
                </div>

                <div
                    v-for="(category, _index) in categoriesLists[4].categories"
                    v-show="show[4]"
                    :key="category._id"
                    class="categories-grid"
                >
                    <div
                        v-for="(value, index) in category.balance"
                        :key="`${category.name}-${index}`"
                        :class="['grid-item', { first: !_index }]"
                    >
                        {{ formatData(value) }}
                    </div>
                </div>

                <div class="grid-container">
                    <div
                        v-for="(item, index) in items"
                        :key="index"
                        :style="{ cursor: index === 13 ? 'pointer' : 'default' }"
                        :class="['grid-item bold', { red: item < 0 }, 'grey', { disabled: disabled && index === 13 }]"
                        @click="index === 13 ? editAccumulated(item) : null"
                    >
                        <input
                            v-if="edit && index === 13"
                            type="text"
                            v-model="accumulated"
                            v-model.lazy="accumulated"
                            v-money3="money"
                            @keyup.enter="submitValue"
                        />
                        <span v-else>{{ formatData(item) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import util, { formatNumber } from '../../common/util'

export default {
    name: 'PlanningDetailsList',

    props: {
        headers: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            default: () => [],
        },
        revenuesItems: {
            type: Array,
            default: () => [],
        },
        essentialItems: {
            type: Array,
            default: () => [],
        },
        noessentialitems: {
            type: Array,
            default: () => [],
        },
        debtsItems: {
            type: Array,
            default: () => [],
        },
        investimentsItems: {
            type: Array,
            default: () => [],
        },

        titles: {
            type: Array,
            default: () => [],
        },
        categoriesLists: {
            type: Array,
            default: () => [
                {
                    id: 'revenuesCategories',
                    categories: [],
                },
                {
                    id: 'essentialCategories',
                    categories: [],
                },
                {
                    id: 'noessentialCategories',
                    categories: [],
                },
                {
                    id: 'debtsCategories',
                    categories: [],
                },
                {
                    id: 'investimentCategories',
                    categories: [],
                },
            ],
        },
    },

    data() {
        return {
            show: [],
            edit: false,
            accumulated: 0,

            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
                prefix: util.returnCurrencyPrefix().prefix + ' ',
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['waiting', 'findById', 'disabled']),

        styles() {
            return {
                '--overflow': this.waiting ? 'hidden' : 'scroll',
            }
        },
    },

    methods: {
        formatData(item) {
            if (typeof item === 'number') return formatNumber(item)
            return item
        },

        editAccumulated(value) {
            this.accumulated = Number(value.toFixed(2)) * 100
            this.edit = true
        },

        submitValue() {
            this.$emit('submit-value', this.accumulated)
            this.edit = false
        },

        changeColor(item, index) {
            if (index < 13) return ''
            if ((item !== 0 && index < 48) || (item < 0 && index >= 48)) return 'red'
        },

        showRow(index) {
            const update = !this.show[index]

            this.show = Array(this.titles.length).fill(false)
            this.show[index] = update
        },

        sanitize(string) {
            return util.sanitize(string)
        },

        sanitizeCategoryName(category) {
            return util.sanitizeCategoryName(category)
        },

        getImgUrl(category) {
            return util.getImgUrl(category)
        },
    },

    watch: {
        titles: {
            immediate: true,
            handler() {
                this.show = Array(this.titles.length).fill(false)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.planning-details-list {
    /* @include box-shadow(); */
    display: flex;
    border: 1px solid $lighter-grey;

    .titles-grid {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        min-width: 160px;

        .grid-item {
            font-weight: 400;
            color: $darker-blue;
            min-height: 46px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .title {
                display: flex;
                align-items: center;
                height: 46px;
                cursor: pointer;
                min-width: 160px;
                padding-left: 12px;

                img {
                    width: 25px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-right: 10px;
                }

                &.noevents {
                    pointer-events: none;
                    cursor: default;
                }

                &.category {
                    background-color: white;
                    padding-left: 12px;
                    cursor: default;

                    &:first-child {
                        border-top: 1px solid $lighter-grey;
                    }
                }
            }

            &:nth-child(2n + 1) {
                background-color: $lighter-grey;
            }

            ._arrow {
                margin-right: 8px;

                .show {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .empty-grid {
        display: flex;
        flex-direction: column;
        width: 16px;

        .grid-item {
            width: 16px;
            height: 48px;

            &:first-child {
                background-color: $list-blue;
            }
        }
    }

    .scroll-grid {
        display: flex;
        overflow: var(--overflow);
        overflow-y: hidden;

        .categories-grid {
            display: grid;
            grid-template-columns: repeat(13, minmax(150px, 9.1vw)); /* Thirteen columns with equal width */
            height: fit-content;
            background-color: white;

            .grid-item {
                padding: 16px;
                font-weight: 500;
                color: $darker-blue;
                text-align: right;
                font-size: 14px;
                white-space: nowrap;

                &.first {
                    border-top: 1px solid $lighter-grey;
                }
            }
        }

        .header-grid {
            display: grid;
            grid-template-columns: repeat(13, minmax(150px, 9.1vw)); /* Thirteen columns with equal width */
            height: fit-content;

            .grid-item {
                padding: 16px;
                text-align: right;
                font-weight: 400;
                text-transform: uppercase;
                background-color: $lighter-grey;
                color: $darker-blue;
                text-align: right;
                white-space: nowrap;
            }
        }

        .grid-container {
            display: grid;
            grid-template-columns: repeat(13, minmax(150px, 9.1vw)); /* Thirteen columns with equal width */
            height: max-content;

            &.loader-container {
                grid-template-columns: repeat(6, 150px);
            }

            .grid-item {
                padding: 16px;
                font-weight: 500;
                color: $light-blue;
                text-align: right;
                font-size: 14px;
                white-space: nowrap;

                &.disabled {
                    pointer-events: none;
                    cursor: progress;
                    opacity: 0.5;
                }

                &.loader-item {
                    height: 49.2px;
                }

                &.grey {
                    background-color: $lighter-grey;
                }

                &.grey:nth-child(n + 14) {
                    background-color: white;
                }

                &.bold {
                    font-weight: 600;
                }

                input {
                    text-align: right;
                    width: 100%;
                }
            }
        }

        .red {
            color: $expense-red !important;
        }
    }

    @media (max-width: 1445px) {
        .title {
            justify-content: flex-start !important;
        }
    }
}
</style>
