<template>
    <div class="family-account">
        <div class="flex">
            <div class="flex family-account-box column">
                <div class="info-initial-btn">
                    Ao compartilhar seu orçamento com outras pessoas, suas receitas e despesas serão somadas as receitas
                    e despesas dos demais membros da sua família.
                </div>

                <div v-show="(!adminHasFamily || showFormAddMembers) && !isPlanner">
                    <h1>Sua Família</h1>
                </div>

                <div class="content-margin" style="margin-top: 0">
                    <small class="sucess-msg" :class="{ err }">{{ sucessMsg }}</small>

                    <!-- CRIAR FAMILIA -->

                    <form
                        v-show="!adminHasFamily && !isPlanner"
                        @submit.prevent.self="validateFamilyName"
                        class="form-planilha"
                        novalidate
                    >
                        <stacked-input
                            label="Dê um nome à sua família"
                            ref="inputFamilyName"
                            :errorMsg="errInputFamilyNameNameMsg"
                            v-model="inputFamilyName"
                            name="name"
                            :disabled="userHasFamily"
                        />

                        <basic-button
                            v-if="!userHasFamily"
                            label="Criar"
                            type="submit"
                            :loading="disabled"
                            :width="300"
                            data-cy="submit-create-family"
                        />
                    </form>

                    <!-- CONVIDAR MEMBROS -->

                    <form
                        v-if="showFormAddMembers"
                        @submit.prevent.self="validateEmail"
                        class="content-form-member-family"
                    >
                        <div class="info-btn">
                            Convide membros da sua família copiando o voucher abaixo ou enviando por email.
                        </div>

                        <div class="form-planilha">
                            <stacked-input
                                label="E-mail do convidado"
                                ref="email"
                                :errorMsg="errMsg"
                                v-model="email"
                                name="email"
                            />
                            <div class="flex center toggle-switch-container">
                                <span class="toggle-label">{{ familyVoucher }}</span>
                            </div>
                        </div>

                        <basic-button
                            data-cy="submit-send-invit"
                            label="Enviar convite"
                            type="submit"
                            :width="300"
                            :loading="disabled"
                        />
                    </form>
                </div>

                <div v-show="!adminHasFamily" style="margin-top: 20px">
                    <h1>Ingressar Família</h1>
                </div>

                <!-- form inserir código para ingressar numa familia -->

                <div v-show="!userHasFamily" class="content-margin" style="margin-top: 0">
                    <small class="sucess-msg" :class="{ err }">{{ sucessMsg }}</small>

                    <form @submit.prevent.self="validateJoinVoucher" class="content-form-member-guest" novalidate>
                        <div class="form-planilha">
                            <stacked-input
                                label="O código da família"
                                ref="joinVoucher"
                                :errorMsg="errJoinVoucherMsg"
                                v-model="joinVoucher"
                                name="code"
                            />

                            <basic-button
                                label="Ingressar na família"
                                type="submit"
                                :loading="disabled"
                                :width="300"
                                data-cy="submit-family-code"
                            />
                        </div>
                    </form>
                </div>

                <div v-show="userHasFamily" class="content-margin">
                    <h1>Membros da família {{ familyName }}</h1>

                    <settings-list :isMembersList="true" :list="familyMembersList" @deleteElementEvent="deleteMember" />
                </div>
            </div>
        </div>

        <confirmation-modal-generic
            v-bind="confirmation"
            v-if="confirmation.show"
            text="Deseja mesmo reiniciar a conta"
            @close-modal="closeModalDelete"
        >
            <template #buttons>
                <basic-button
                    :loading="disabled"
                    class="gutter"
                    :label="confirmation.confirmButtonText"
                    width="100%"
                    @click="handleConfirmeModalDelete()"
                />

                <basic-button
                    class="gutter"
                    label="Cancelar"
                    width="100%"
                    :reverse="true"
                    :disabled="disabled"
                    @click="closeModalDelete()"
                />
            </template>
        </confirmation-modal-generic>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'

import StackedInput from '../../components/inputs/StackedInput.vue'
import SettingsList from '../../components/lists/SettingsList.vue'
import ConfirmationModalGeneric from '../../components/modals/confirmation-modals/ConfirmationModalGeneric.vue'
import BasicButton from '../../components/buttons/BasicButton.vue'

import request from '@/common/request'
import validation from '@/common/validation'
import { environment } from '../../common/environment'
import apiService from '../../services/api.service'
import { errorHandler } from '../../common/errorHandler'

export default {
    name: 'FamilyAccount',

    components: {
        StackedInput,
        SettingsList,
        ConfirmationModalGeneric,
        BasicButton,
    },

    data() {
        return {
            preferences: {
                notifications: true,
                notificationTime: '09:00',
                sumBalance: true,
                currency: 'Real',
            },

            confirmation: {
                message: 'Tem certeza que deseja excluir esse membro?',
                success: false,
                error: false,
                show: false,
                confirmButtonText: 'Excluir',
            },

            deletingMember: null,

            errInputFamilyNameNameMsg: '',
            errfamilyCodeMsg: '',
            errJoinVoucherMsg: '',
            editingFamilyName: false,

            joinVoucher: '',
            inputFamilyName: '',

            constUserHasFammily: false,

            errMsg: '',
            sucessMsg: '',
            err: false,
            email: '',
            checkError: false,
        }
    },

    mounted() {
        this.constUserHasFammily = Object.assign(this.userHasFamily)
    },

    computed: {
        ...mapState(mainStore, ['user', 'disabled', 'familyMembers', 'families', 'isPlanner', '_date']),

        showFormAddMembers() {
            return this.userHasFamily && this.isFamilyAdmin
        },

        familyVoucher() {
            return this.families.voucher ? this.families.voucher : ''
        },

        familyName: {
            get() {
                return this.families.name ? this.families.name : this.user.family ? this.user.family.name : ''
            },
            set(val) {
                this.inputFamilyName = val
            },
        },

        isFamilyAdmin() {
            return this.families.user ? this.families.user === this.user._id : false
        },

        memberHasFamily() {
            return !!this.user.family
        },

        adminHasFamily() {
            return !!this.familyMembers(true).length
        },

        userHasFamily() {
            return this.adminHasFamily || this.memberHasFamily
        },

        familyMembersList() {
            if (this.adminHasFamily) return this.familyMembers(true)
            else if (this.memberHasFamily) return this.user.family.members

            return []
        },
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        deleteMember(params) {
            let payload = {
                _id: params.id,
                leavingFamily: params.leavingFamily,
            }

            this.deletingMember = payload

            if (this.deletingMember.leavingFamily) {
                this.confirmation.message = 'Tem certeza que deseja sair da família?'
                this.confirmation.confirmButtonText = 'Sair'
            } else {
                this.confirmation.message = 'Tem certeza que deseja excluir esse membro?'
                this.confirmation.confirmButtonText = 'Excluir'
            }

            this.confirmation.show = true
        },

        validateEmail() {
            if (!this.email) {
                ;(this.$refs.email.err = true), (this.errMsg = 'Informação Obrigatória')
            } else if (!validation.validateEmail(this.email)) {
                this.$refs.email.err = true
                this.errMsg = 'Email inválido'
            } else {
                this.$refs.email.err = false
                this.invitFamilyMember()
            }
        },

        validateFamilyName() {
            if (!this.inputFamilyName) {
                this.$refs.inputFamilyName.err = true
                this.errInputFamilyNameNameMsg = 'Informação Obrigatória'
            } else {
                this.$refs.inputFamilyName.err = false
                this.createFamily()
            }
        },

        createFamily() {
            this.DISABLED()
            request
                .post({
                    collection: 'families',
                    data: { name: this.inputFamilyName, user: this.user._id },
                })
                .finally(() => this.DISABLED())
        },

        validateJoinVoucher() {
            if (!this.joinVoucher) {
                this.$refs.joinVoucher.err = true
                this.errJoinVoucherMsg = 'Informação Obrigatória'
            } else {
                this.$refs.joinVoucher.err = false
                this.joinFamilyByVoucher()
            }
        },

        joinFamilyByVoucher() {
            const joinVoucher = this.joinVoucher
            this.DISABLED()

            apiService
                .post(`${environment.FAMILIES_URL}/voucher`, {
                    voucher: joinVoucher,
                    email: this.user.email,
                    user: this.user._id,
                })
                .then(async () => {
                    this.$refs.joinVoucher.err = false
                    this.joinVoucher = ''
                    await this.getUserData()
                })
                .catch((error) => {
                    this.$refs.joinVoucher.err = true
                    this.errJoinVoucherMsg = errorHandler(error)
                })
                .finally(() => {
                    this.DISABLED()
                })
        },

        handleConfirmeModalDelete() {
            this.deletingMember.leavingFamily ? this.confirmeLeaveFamily() : this.confirmedDeleteMember()
        },

        confirmeLeaveFamily() {
            this.DISABLED()
            const leavingFamilyId = this.user.family._id
            const leavingMemberId = this.deletingMember._id

            apiService
                .post(`${environment.FAMILIES_URL}/${leavingFamilyId}/member?remove=${leavingMemberId}`)
                .then(async (response) => {
                    await this.getUserData()
                })
                .catch((e) => console.error(e))
                .finally(() => {
                    this.closeModalDelete()
                    this.DISABLED()
                })
        },

        confirmedDeleteMember() {
            this.DISABLED()
            const familyId = this.families._id
            const memberId = this.deletingMember._id

            request
                .removeElement({
                    collection: 'families',
                    subroute: `/${familyId}/member?remove=${memberId}`,
                    mutation_params: { _id: memberId },
                    mutation: 'REMOVE_DATA',
                })
                .then((rsp) => {})
                .finally(() => {
                    this.DISABLED()
                    this.closeModalDelete()
                })
        },

        invitFamilyMember() {
            this.DISABLED()
            const familyId = this.families._id

            request
                .post({
                    collection: 'families',
                    subroute: `/${familyId}/member`,
                    data: { email: this.email },
                    mutation: 'CHANGE_DATA',
                    noUserId: true,
                })
                .then((rsp) => {
                    this.$refs.email.err = false
                    this.email = ''
                })
                .catch((err) => {
                    this.$refs.email.err = true
                    this.errMsg = err.response.data.message
                })
                .finally(() => this.DISABLED())
        },

        async getUserData() {
            this.DISABLED()

            try {
                await request.getById({ collection: 'users', _id: this.user._id })
                await request.fetch(['categories', 'payments'])

                await request.fetch({
                    collection: 'invoices',
                    queryParams: this._date,
                })
                await request.fetch([
                    { collection: 'budgets', queryParams: this._date },
                    { collection: 'transactions', queryParams: this._date },
                ])
            } catch (e) {
                throw new Error(e)
            } finally {
                this.DISABLED()
            }
        },

        closeModalDelete() {
            this.confirmation = {
                message: 'Tem certeza que deseja excluir esse membro?',
                success: false,
                error: false,
                show: false,
            }
            this.deletingMember = null
        },
    },
}
</script>

<style lang="scss" scoped>
.family-account {
    padding-top: 41px;

    .family-account-box {
        width: 780px;

        .form-width {
            max-width: 190px;
        }

        .stacked-input {
            margin-top: 0px;
        }

        .info-btn {
            color: #848484;
            font-size: 16px;
            line-height: 22px;
            margin: 20px 0;
        }
        .info-initial-btn {
            color: #848484;
            font-size: 16px;
            line-height: 22px;
            margin: 0px 20px 20px 0;
        }

        .content-form-member-family {
            .basic-button {
                margin-top: 18px;
            }
        }

        .content-margin {
            margin-top: 25px;
            position: relative;

            .form-planilha {
                margin-top: 20px;
                display: flex;

                .stacked-input {
                    display: inline-block;
                    width: 300px;
                    margin-right: 10px;
                }
            }

            .text-box {
                width: 398.98px;
                color: #848484;
                font-size: 16px;
                margin: 16px 0 5px 0;

                .box-title {
                    width: 300px;
                    font-weight: 500;
                }
            }
        }

        h1 {
            @include section-title();
        }

        .toggle-switch-container {
            margin: auto;

            .toggle-label {
                color: #646464;
                background-color: #ece8e8;
                font-size: 18px;
                font-weight: bold;
                line-height: 22px;
                padding: 2px 30px;
                user-select: text;
            }

            .info-btn {
                line-height: 1.5;
            }
        }

        .basic-button {
            &.not-allowed {
                cursor: not-allowed;
            }
        }

        .sucess-msg {
            color: limegreen;
            font-weight: 500;

            &.err {
                color: $hint-red;
            }
        }

        .checkbox-input {
            margin-top: 20px;

            label {
                color: $action-btn-color;
                font-size: 13px;
                margin-left: 10px;
                font-weight: 500;
                position: relative;
                bottom: 1px;
            }
        }
    }
    .gutter {
        margin: 0 0 8px;
    }
}

.flex {
    display: flex;
}

.flex-basis {
    flex-basis: 190px;
}

.space-around {
    justify-content: space-around;
}

.column {
    flex-direction: column;
}

.center {
    align-items: center;
}

.grow-1 {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.space-between {
    justify-content: space-between;
}

.basic-button {
    margin-top: -8px;
}
</style>
