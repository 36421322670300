<template>
    <div class="sidebar">
        <div class="content" :class="{ disabled: waiting }">
            <div class="brand-menu">
                <div class="mobile-menu" @click="toggleMenu()">
                    <div class="hamburger"></div>
                    <div class="hamburger"></div>
                    <div class="hamburger"></div>
                </div>

                <div class="logo">
                    <img class="desktop-logo" :src="$variables.logo" />
                    <img class="mobile-logo" :src="$variables.logoSmartphone" />
                </div>
            </div>

            <div class="actions" :class="{ 'is-showing': showSidebar }">
                <router-link data-cy="link-painel" to="/painel">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/dashboard.svg" alt="dashboard" />
                    </i>
                    <small>Painel</small>
                </router-link>

                <router-link data-cy="link-transactions" to="/movimentacoes">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/movimentacoes.svg" alt="movimentacoes" />
                    </i>
                    <small>Transações</small>
                </router-link>

                <router-link v-if="!isPlanner" data-cy="link-calendar" to="/calendario">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/calendar.svg" alt="calendário" />
                    </i>
                    <small>Calendário</small>
                </router-link>

                <router-link data-cy="link-plan" to="/planejamento">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/planejamento.svg" alt="planejamento" />
                    </i>
                    <small>Planejamento</small>
                </router-link>

                <router-link data-cy="link-wallet" to="/carteiras">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/carteiras.svg" alt="carteiras" />
                    </i>
                    <small>Carteiras</small>
                </router-link>

                <router-link data-cy="link-objectives" to="/objetivos">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/objetivos.svg" alt="objetivos" />
                    </i>
                    <small>Objetivos</small>
                </router-link>

                <router-link v-if="!$variables.hideScreenTasks" data-cy="link-tasks" to="/tarefas">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/tarefas.svg" alt="tarefas" />
                    </i>
                    <small>Tarefas</small>
                </router-link>

                <router-link data-cy="link-relatorio" to="/relatorio">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/especialistas.svg" alt="relatório" />
                    </i>
                    <small>Relatório</small>
                </router-link>

                <router-link
                    v-if="!$variables.hideScreenEducational && !isPlanner"
                    data-cy="link-educational"
                    to="/educacional"
                >
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/educacional.svg" alt="educacional" />
                    </i>
                    <small>Educacional</small>
                </router-link>

                <router-link v-if="!$variables.hideScreenSpecialists" data-cy="especialistas" to="/especialistas">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/relatorio.svg" alt="especialistas" />
                    </i>
                    <small>Especialistas</small>
                </router-link>

                <router-link
                    data-cy="link-config"
                    to="/configuracoes"
                    active-class="active"
                    :class="[{ alert: getBelvoRelatedPluggyConnectors.length > 0 }]"
                >
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/configuracoes.svg" alt="configuracoes" />
                    </i>
                    <small>Configurações</small>
                </router-link>

                <a data-cy="link-logout" @click="modal = true">
                    <i class="icon">
                        <img src="../assets/icons/sidebar-icons/logout.svg" alt="logout" />
                    </i>
                    <small>Logout</small>
                </a>
            </div>

            <div></div>
        </div>

        <div v-show="modal" class="overlay">
            <v-scale-transition origin="center center 0">
                <confirmation-modal v-if="modal" isGeneric text="Deseja mesmo sair" @closeModalEvent="closeModal">
                    <template #generic-btn>
                        <basic-button
                            :style="{ marginBottom: '8px' }"
                            label="Desconectar"
                            width="100%"
                            @click="closeModal('logout')"
                            data-cy="comfirmation-modal-logout"
                        />
                    </template>
                </confirmation-modal>
            </v-scale-transition>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import BasicButton from './buttons/BasicButton.vue'
import ConfirmationModal from './modals/confirmation-modals/ConfirmationModal.vue'

import auth from '../services/auth.service'

export default {
    components: { ConfirmationModal, BasicButton },

    name: 'Sidebar',

    data() {
        return {
            modal: false,
            show: false,
        }
    },

    computed: {
        ...mapState(mainStore, ['waiting', 'showSidebar', 'getBelvoRelatedPluggyConnectors', 'isPlanner']),
    },

    methods: {
        ...mapActions(mainStore, ['toggleSidebar']),

        logout() {
            auth.logout()
        },

        closeModal(action) {
            if (action === 'cancel') {
                this.modal = false
            } else {
                this.logout()
            }
        },

        toggleMenu() {
            this.toggleSidebar()
        },
    },
}
</script>

<style lang="scss" scoped>
.sidebar {
    background-color: $sidebar-color;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80px;
    z-index: 998;
}
.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &.disabled {
        pointer-events: none;
    }
}
.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

#otto-action {
    opacity: 0.55;
    width: 20px;
    padding: 10px 0;
}

#otto-action.active {
    opacity: 1;
}

.active,
.router-link-exact-active,
a:hover {
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.05);
    opacity: 1;
}

.icon,
.fa-arrow-up {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 0;
}

.logo {
    display: block;
    padding-top: 10px;
    width: 50px;
    height: 65px;
    margin: 10px auto;
}
.mobile-logo {
    display: none;
}

a {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

small {
    @include easy-tooltip();

    &:hover {
        transform: translateY(15px);
    }
}
.sidebar .content .actions a.alert:before {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: 12px;
    right: 33%;
    background-color: red;
    border-radius: 100%;
    z-index: 1000;
}

.mobile-menu {
    display: none;
}

@media screen and (max-width: 700px) {
    .sidebar {
        width: 100% !important;
        height: 80px !important;
        position: fixed !important;

        .content {
            align-items: unset !important;
        }
    }

    .brand-menu {
        position: relative;
    }

    .mobile-menu {
        position: absolute;
        top: 20px;
        display: block;
        width: 44px;
        height: 44px;
        margin-left: 16px;
        padding: 10px;
    }

    .hamburger {
        width: 24px;
        height: 3px;
        background-color: #7e7e7e;
        margin-bottom: 6px;
    }

    .logo {
        width: 130px;
        margin-top: 18px;
        padding: 0;
    }

    .mobile-logo {
        display: inline;
    }
    .desktop-logo {
        display: none;
    }

    .actions {
        width: 80px !important;
        position: absolute;
        background-color: #eef1f6;
        top: 80px;
        left: 0;
        transform: translateX(-80px);
        transition: all 0.5s ease;

        &.is-showing {
            transform: translateX(0px);
        }
    }
}
</style>
