<template>
    <fieldset :disabled="loading" class="bank-integration-modal not-default-fieldset">
        <header>
            <span>CONECTAR NOVA CONTA</span>

            <button
                data-cy="close-bank-integration-modal"
                class="close"
                type="button"
                @click.stop="() => $emit('modalClosedEvent')"
            >
                <ion-icon name="close"></ion-icon>
            </button>
        </header>

        <div class="button-group">
            <div
                class="button"
                :class="!op.active && actualOption !== index ? 'gray-button' : ''"
                :style="{ cursor: !op.active ? 'pointer' : 'default' }"
                v-for="(op, index) in options"
                :key="index"
                @click="!op.active ? openBelvoWidget(op.belvoAlias, index) : null"
            >
                <div class="img-container" :style="{ backgroundColor: op.bgColor }">
                    <img :style="op.styles" :src="require(`../../assets/img/bank-logos/${op.logo}`)" :alt="op.title" />
                </div>

                <span>{{ op.title }}</span>

                <div v-if="actualOption !== index" class="action-container">
                    <button v-if="op.active" @click="synchronizeAccount(op.belvoAlias, index)">
                        <img src="../../assets/img/bank-logos/sync.svg" alt="" />
                    </button>
                    <button v-if="op.active" @click="removeAccount(op.belvoAlias, index)">
                        <img src="../../assets/img/bank-logos/remove.svg" alt="" />
                    </button>
                    <button
                        v-else
                        style="border: 2px solid gray; width: 18px; height: 18px"
                        @click="openBelvoWidget(op.belvoAlias, index)"
                    >
                        <img style="width: 10px; height: 10px" src="../../assets/img/bank-logos/add.svg" alt="" />
                    </button>
                </div>

                <div v-else>
                    <div class="loader" :style="{ color: op.bgColor }"></div>
                </div>
            </div>
        </div>

        <div v-show="showSuccessDialog" class="overlay">
            <v-slide-y-reverse-transition>
                <confirmation-create-modal
                    v-bind="confirmation"
                    v-if="showSuccessDialog"
                    @confirmEvent="() => (showSuccessDialog = false)"
                >
                    <basic-button
                        :bgColor="confirmation.error ? '#ff113a' : '#028FFF'"
                        width="100%"
                        label="Fechar"
                        @click="() => (showSuccessDialog = false)"
                    />
                </confirmation-create-modal>
            </v-slide-y-reverse-transition>
        </div>

        <div v-show="syncInformation.showSync" class="overlay">
            <v-slide-y-reverse-transition>
                <confirmation-create-modal
                    v-bind="syncInformation"
                    v-if="syncInformation.showSync"
                    @confirmEvent="() => (syncInformation.showSync = false)"
                >
                    <basic-button width="100%" label="Informar token" @click="syncLinkByToken()" />
                    <basic-button
                        :reverse="true"
                        width="100%"
                        label="Cancelar"
                        @click="() => (syncInformation.showSync = false)"
                    />
                </confirmation-create-modal>
            </v-slide-y-reverse-transition>
        </div>
    </fieldset>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import BasicButton from '../buttons/BasicButton.vue'
import ModalTransition from '../transitions/ModalTransition.vue'
import ConfirmationCreateModal from './confirmation-modals/ConfirmationCreateModal.vue'

import { environment } from '../../common/environment'
import request from '../../common/request'
import apiService from '../../services/api.service'

export default {
    components: { ModalTransition, ConfirmationCreateModal, BasicButton },

    name: 'BankIntegrationModal',

    data() {
        return {
            options: [
                {
                    title: 'Caixa',
                    logo: 'caixa.svg',
                    belvoAlias: 'caixa_br_retail',
                    bgColor: '#1c60ab',
                    active: false,
                },
                {
                    title: 'NuBank',
                    logo: 'nubank.svg',
                    belvoAlias: 'nubank_br_retail',
                    bgColor: '#612F74',
                    active: false,
                },
                {
                    title: 'Banco do Brasil',
                    logo: 'banco-do-brasil.svg',
                    belvoAlias: 'bancodobrasil_br_retail',
                    bgColor: '#F9DD16',
                    styles: { width: '130%' },
                    active: false,
                },
                {
                    title: 'Itaú',
                    logo: 'itau.svg',
                    belvoAlias: 'itau_br_retail',
                    bgColor: '#1c60ab',
                    active: false,
                },
                {
                    title: 'Santander',
                    logo: 'santander.svg',
                    belvoAlias: 'santander_br_retail',
                    bgColor: '#EC0000',
                    active: false,
                },
                {
                    title: 'Bradesco',
                    logo: 'bradesco.svg',
                    belvoAlias: 'bradesco_br_retail',
                    bgColor: '#EC0000',
                    active: false,
                },
                {
                    title: 'Inter',
                    logo: 'inter.svg',
                    belvoAlias: 'bancointer_br_retail',
                    bgColor: '#EC0000',
                    active: false,
                },
            ],

            showSuccessDialog: false,
            loading: false,
            actualOption: '',
            syncInformation: {
                showSync: false,
                title: 'Autenticação',
                error: true,
                text: 'Informe a senha numérica (token) gerada pelo aplicativo do seu banco',
                linkAccount: {
                    linkId: '',
                    institution: '',
                    index: '',
                },
            },
            confirmation: {
                title: '',
                error: false,
                text: '',
            },
        }
    },

    computed: {
        ...mapState(mainStore, ['bankaccesses', '_date_selected']),
    },

    methods: {
        async getBelvoAccessToken(linkId) {
            const responseData = await apiService.post(environment.BELVO_ACCESS_TOKEN, { linkId })
            return responseData.data.access
        },

        async openBelvoWidget(bankInstitution, index, linkId) {
            this.loading = true
            this.actualOption = index

            try {
                const access_token = await this.getBelvoAccessToken(linkId)

                belvoSDK
                    .createWidget(access_token, {
                        locale: 'pt',
                        company_name: 'Otto Finanças',
                        access_mode: 'recurrent',
                        country_codes: ['BR'],
                        institution_types: ['retail', 'business'],
                        institution: bankInstitution,
                        callback: linkId
                            ? (link, institution) => this.belvoTokenSuccess(link, institution)
                            : (link, institution) => this.belvoSuccessCallbackFunction(link, institution),
                    })
                    .build()
            } catch (error) {
                console.error(error)
                this.confirmation.text = `Não foi possível conectar com o banco no momento por favor tente novamente mais tarde.`
                this.confirmation.error = true
                this.showSuccessDialog = true
            } finally {
                this.loading = false
                this.actualOption = ''
            }
        },

        belvoSuccessCallbackFunction(link, institution) {
            this.saveBankAccess(link, institution)
        },

        async belvoTokenSuccess(link, institution) {
            await this.bankAccessRequest(institution, index)
            this.syncInformation.linkAccount = {}
        },

        async saveBankAccess(link, institution) {
            this.loading = true
            this.confirmation.title = 'Nova Conexão'

            try {
                await request.post({
                    collection: 'bankaccesses',
                    data: {
                        linkId: link,
                        institution,
                    },
                })

                this.confirmation.text = `Sua conta foi adicionada com sucesso.\n
                        Agora iremos dar inicio ao processo de sincronização dos dados.\n
                        Esse é um processo que pode demorar alguns minutos.`
                this.confirmation.error = false
                this.showSuccessDialog = true
            } catch (error) {
                console.error(error)
                this.confirmation.text = `Não foi possível salvar seus dados no momento. Por favor entre em contato com nosso suporte técnico.`
                this.confirmation.error = true
            } finally {
                this.loading = false
                this.actualOption = ''
            }
        },

        async synchronizeAccount(institution, index) {
            this.loading = true
            this.actualOption = index
            this.confirmation.title = 'Sincronização de Conta'

            this.bankAccessRequest(institution, index)
        },

        async bankAccessRequest(institution, index) {
            const bankAccessId = this.bankaccesses.find((ba) => ba.institution === institution)._id
            try {
                await apiService.post(`${environment.BANK_ACCESSS_URL}/${bankAccessId}/sync`, {})
                await request.fetch([
                    { collection: 'invoices', queryParams: this._date_selected },
                    { collection: 'transactions', queryParams: this._date_selected },
                    { collection: 'payments' },
                ])

                this.confirmation.text = `Sua conta foi sincronizada com sucesso`
                this.confirmation.error = false
                this.showSuccessDialog = true
            } catch (error) {
                if ((error.response.status = 428)) {
                    this.syncInformation.linkAccount.linkId = error.response.data.message
                    this.syncInformation.linkAccount.institution = institution
                    if (!index) this.syncInformation.linkAccount.index = index
                    this.syncInformation.showSync = true
                } else {
                    this.confirmation.text = `Não foi possível sincronizar sua conta automática`
                    this.confirmation.error = true
                    this.showSuccessDialog = true
                }
            } finally {
                this.loading = false
                this.actualOption = ''
            }
        },

        async syncLinkByToken() {
            const { institution, index, linkId } = this.syncInformation.linkAccount
            await this.openBelvoWidget(institution, index, linkId)
            this.syncInformation.showSync = false
        },

        removeAccount(institution) {
            const _id = this.bankaccesses.find((ba) => ba.institution === institution)._id

            this.$emit('delete', {
                data: {
                    _id,
                    isBankAccess: true,
                    collection: 'bankaccesses',
                },
                text: `Caso continue será removido alem do acesso ao banco todo o histórico transacional relacionado ao acesso.
                    \n Deseja mesmo assim fazer a remoção do acesso aos dados`,
            })
        },
    },

    watch: {
        bankaccesses: {
            deep: true,
            immediate: true,
            handler(newValue) {
                newValue.forEach((ba) => {
                    this.options.find((o) => o.belvoAlias === ba.institution).active = true
                })
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.bank-integration-modal {
    width: 620px;
    min-height: 240px;
    border-radius: 8px;
    background: white;

    &:disabled {
        cursor: wait;
    }

    header {
        position: relative;
        padding: 20px 0 30px;
        width: 100%;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        font-weight: 600;
        color: $hard-grey;

        & > .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 18px 16px;
            font-size: 22px;
            color: $medium-grey;
        }
    }

    .button-group {
        display: flex;
        flex-wrap: wrap;
        padding: 0 35px 35px;

        .button {
            width: 125px;
            height: 125px;
            box-shadow: 0px 2px 6px #0000004d;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 15px 15px 0;
            border-radius: 4px;
            font-size: 14px;
            color: black;
            padding: 12px 8px;

            &:nth-child(4n) {
                margin-right: 0;
            }

            &.gray-button {
                opacity: 0.3;
            }

            &:hover {
                opacity: 1;
            }

            .img-container {
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    object-fit: contain;
                    width: 33px;
                    height: 33px;
                }
            }

            .action-container {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 70%;

                button {
                    width: 20px;
                    height: 20px;
                    box-shadow: none;
                    padding: unset;
                    padding-top: 1px;
                    border-radius: 50%;
                    text-align: center;

                    img {
                        width: 15px;
                        height: 15px;
                        object-fit: contain;
                    }

                    &:hover {
                        filter: brightness(0.5);
                    }
                }
            }
        }
    }
}

.loader {
    font-size: 14px;
    margin: 0;
}
</style>
