<template>
    <div class="step-messages" :class="{ end: step === 6 }">
        <p>
            {{ messages[step - 1] }}
            <a
                v-show="step === 1"
                @click="$emit('show-tutorial')"
                style="color: #028fff; text-decoration: underline; font-weight: 400"
                target="_blank"
            >
                este vídeo</a
            >
        </p>
    </div>
</template>

<script>
export default {
    name: 'StepMessages',

    emits: ['show-tutorial'],

    props: {
        step: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            messages: [
                'Cadastre todas as suas receitas... Qualquer dúvida, veja',
                'Agora, cadastre apenas as despesas cujo valor não varia mensalmente.',
                'Agora, cadastre apenas as despesas cujo valor sofre variação mensal ou são extras.',
                'Cadastre todas as suas dívidas, sejam parcelas de compras no cartão, empréstimos, crediário, etc.',
                'Por fim, informe cada investimento que você realiza, informando a recorrência deles.',
                'Ótimo! Você está pronto para começar sua jornada de transformação financeira.',
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
.step-messages {
    &.end {
        margin: 0px 30%;
        line-height: 20px;
        text-align: center;
    }

    p {
        margin: 24px 0 12px;
    }
}
</style>
