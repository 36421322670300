<template>
    <div class="card-title">
        <h3>{{ title }}</h3>
    </div>
</template>

<script>
export default {
    name: 'CardTitle',

    props: {
        plan: {
            type: String,
            default: 'no_plan',
        },
    },

    computed: {
        title() {
            let string = this.$variables.subscriptionPrefix

            if (this.plan.includes('family')) return string.concat(' Family')
            if (this.plan.includes('basic')) return string.concat(' Basic')
            if (this.plan.includes('company')) return string.concat(' Company')
            if (this.plan === 'free' || this.plan === 'no_plan') return 'Sem plano'
            
            return string.concat(' Premium')
        },
    },
}
</script>

<style lang="scss" scoped>
h3 {
    color: $darker-blue;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
}
</style>
