<template>
    <div class="categories flex colunm modal">
        <header class="flex colunm default">
            <div class="flex grow-1 center title-container" :class="[categoryStyle, { morePadd: !showNav }]">
                <h1 class="grow-1">
                    {{ title }}
                </h1>

                <button class="close grow-1" type="button" :class="{ morePadd: !showNav }" @click.stop="closeModal">
                    <ion-icon name="close"></ion-icon>
                </button>
            </div>

            <div v-if="showNav" class="flex grow-1 center flex-center label-container" :class="categoryStyle">
                <month-selector @change-month="changeMonth" color="white" />
            </div>
        </header>

        <div class="content flex colunm">
            <column-chart
                v-if="isPlanning"
                style="margin: 0 auto"
                :labels="chartCategories"
                :series="chartSeries"
                :colors="chartColors"
                :loading="loadingChart"
                :width="550"
            />

            <bars-chart v-else :categories="chartCategories" :series="[chartSeries[1]]" :loading="loadingChart" />

            <transactions-list
                class="categoryList"
                :transactions="filterTransactionsPerCategory(type, category.id, false, userId, paymentId)"
                :isTransactions="false"
                :show-observation="isPlanning"
                :categories-modal="true"
                :show-edit-icon="isPlanning"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import TransactionsList from '../lists/TransactionsList.vue'
import BarsChart from '../charts/BarsChart.vue'
import MonthSelector from '../headers/MonthSelector.vue'
import ColumnChart from '../charts/ColumnChart.vue'

import formatDate from '../../common/formatDate'
import apiService from '../../services/api.service'
import dateFunctions from '@/common/formatDate'
import { environment } from '../../common/environment'
import util from '../../common/util'

export default {
    name: 'CategoriesDetails',

    components: {
        TransactionsList,
        BarsChart,
        MonthSelector,
        ColumnChart,
    },

    props: {
        category: {
            type: Object,
            required: true,
        },
        type: String,
        isPlanning: Boolean,
        userId: String,
        paymentId: String,
        showNav: {
            type: Boolean,
            default: true,
        }, // Esconde o seletor de mês
    },

    data() {
        return {
            categoryStyle: null,
            datePayload: {
                start: '',
                end: '',
            },
            chartSeries: [
                {
                    name: 'Planejado',
                    data: [0, 0, 0, 0, 0, 0],
                },
                {
                    name: 'Realizado',
                    data: [0, 0, 0, 0, 0, 0],
                },
            ],
            chartColors: function ({ value, seriesIndex, w }) {
                const add = !seriesIndex ? 0 : 12
                const index = w.config.series[seriesIndex].data.findIndex((s) => s === value)

                const count = index + add

                if (count < 12) return '#BAC0CC'
                else return value < 0 ? process.env.VUE_APP_EXPENSES : '#028FFF'
            },

            loadingChart: false,
        }
    },

    methods: {
        closeModal() {
            this.$emit('closeCategoryModalEvent')
        },

        changeMonth(value) {
            this.$emit('updateView', value)
            this.getCategoryBalances()
        },

        async getCategoryBalances() {
            let response
            this.loadingChart = true
            const startDate = this.$moment(this._date_selected.inicio).add(-5, 'M').format('YYYY-MM-DD')

            const categories = this.category._id?.length ? this.category._id.toLocaleString() : this.category.id

            try {
                if (this.isPlanning) {
                    response = await apiService.get(
                        `${environment.BALANCES_URL}?type=chart&chartType=budgets&startDate=${startDate}&endDate=${this._date_selected.fim}&categories=${categories}${this.balanceQuery}`,
                    )
                    this.chartSeries[0].data = response.data.map((d) => Math.abs(d.planned))
                } else this.chartSeries[1].name = 'Total'

                response = await apiService.get(
                    `${environment.BALANCES_URL}?type=chart&startDate=${startDate}&endDate=${this._date_selected.fim}&categoryId=${categories}${this.balanceQuery}`,
                )
                this.chartSeries[1].data = response.data.map((d) => Math.abs(d))
            } catch (error) {
                throw error
            } finally {
                this.loadingChart = false
            }
        },
    },

    computed: {
        ...mapState(mainStore, [
            '_date_selected',
            'filterTransactionsPerCategory',
            'getDefaultCategoryById',
            'isOttoFamily',
        ]),

        date() {
            return (
                formatDate.getMonthName(this._date_selected.inicio.slice(5, 7)) +
                ' ' +
                this._date_selected.inicio.slice(0, 4)
            )
        },

        chartCategories() {
            return dateFunctions.getMonths(this._date_selected.inicio)
        },

        balanceQuery() {
            let query = ''

            if (this.isOttoFamily) {
                query = query.concat('&family=true')

                if (this.userId) {
                    query = query.concat(`&userId=${this.userId}`)
                }
            }

            if (this.paymentId) query = query.concat(`&paymentId=${this.paymentId}`)

            if (this.isPlanning) query = query.concat(`&paid=true`)

            return query
        },

        title() {
            if (this.category.group) return this.category.name
            return `${this.type === 'revenues' ? 'Receitas com' : 'Despesas com'} ${this.category.name}`
        },

        categoryStyle() {
            if (this.category.group) return this.category.class

            const category = this.isPlanning ? this.category.id : this.category.id[0]
            return util.sanitize(this.getDefaultCategoryById(category).name)
        },
    },

    mounted() {
        this.getCategoryBalances().catch(console.error)
    },
}
</script>

<style lang="scss" scoped>
@include custom-scroll();

.modal {
    height: 90vh;
    width: 70vw;
    border-radius: 8px;
    background-color: #ffffff;
    font-weight: 500;
}

.default {
    background-color: $brand-blue;
}

header {
    color: #ffffff;
    font-size: 18px;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    h1 {
        text-transform: uppercase;
        text-align: center;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 14px 16px;
        font-size: 24px;
        color: white;

        &.morePadd {
            top: 5px;
        }
    }

    .title-container {
        padding: 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &.morePadd {
            padding: 20px;
        }
    }

    .icon {
        height: 18.57px;
        max-width: 18.57px;
        color: white;
    }

    .label-container {
        padding: 12px 0 24px;
    }

    .label-header {
        width: 170px;
        text-align: center;
        padding: 0 20px 0 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
    }
}

.content {
    padding-bottom: 32px;
    overflow-y: auto;
    overflow-x: hidden;

    .bars-chart {
        margin: auto;
    }

    .categoryList {
        margin: 24px;
        max-width: 100%;
    }
}

.flex {
    display: flex;
}

.grow-1 {
    flex-grow: 1;
}

.flex-end {
    justify-content: flex-end;
}

.flex-center {
    justify-content: center;
}

.center {
    align-content: center;
}

.colunm {
    flex-direction: column;
}

.essential {
    background-color: #f66668;
}

.noessential {
    background-color: #f23e43;
}

.debts {
    background-color: #8c0b11;
}

.investiments {
    background-color: #63b9f2;
}

.revenues {
    background-color: $revenue-green;
}
</style>
