<template>
    <div class="paid-icon" @click="$emit('click')">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width="20"
            height="20"
            viewBox="0 0 25 25"
            xml:space="preserve"
        >
            <g transform="matrix(1 0 0 1 12.36 12.51)" id="Caminho_3139" class="line" :class="[transactionType]">
                <path
                    style="
                        stroke: none;
                        stroke-width: 1;
                        stroke-dasharray: none;
                        stroke-linecap: butt;
                        stroke-dashoffset: 0;
                        stroke-linejoin: miter;
                        stroke-miterlimit: 4;
                        fill-rule: nonzero;
                    "
                    transform=" translate(-1405.36, -1805.01)"
                    d="M 1408.959 1817 c -3.238 0 -8.322 -1.072 -9.58 -1.964 a 1.042 1.042 0 0 1 -0.44 -0.85 v -10.2 a 1.042 1.042 0 0 1 0.4 -0.819 a 31.979 31.979 0 0 1 3.82 -2.545 c 2.374 -1.3 4.011 -3.14 4.4 -4.28 c 0.481 -1.41 1.141 -3.341 3.28 -3.341 a 2.446 2.446 0 0 1 2.287 1.572 c 0.888 2.026 -0.047 4.841 -1.607 7.065 c 1.05 0.246 2.354 0.566 3.119 0.806 a 3.48 3.48 0 0 1 2.571 2.754 a 3.922 3.922 0 0 1 -1.85 3.647 c -1 2.736 -2.763 7.074 -4.039 7.784 A 5.391 5.391 0 0 1 1408.959 1817 Z m -7.922 -3.434 c 1.991 0.836 8.083 1.706 9.239 1.246 c 0.612 -0.553 2.148 -4.031 3.22 -6.985 a 1.044 1.044 0 0 1 0.4 -0.512 c 0.867 -0.579 1.316 -1.243 1.233 -1.82 a 1.459 1.459 0 0 0 -1.124 -1.061 c -1.317 -0.412 -4.548 -1.125 -4.581 -1.132 a 1.042 1.042 0 0 1 -0.542 -1.729 c 2.356 -2.525 2.774 -5.126 2.318 -6.166 c -0.14 -0.319 -0.285 -0.319 -0.364 -0.319 c -0.517 0 -0.759 0.359 -1.293 1.925 c -0.626 1.831 -2.785 4.016 -5.374 5.437 a 29.079 29.079 0 0 0 -3.134 2.052 Z m -4.127 0.709 v -10.412 a 1.705 1.705 0 0 0 -3.41 0 v 10.412 a 1.705 1.705 0 0 0 3.41 0 Z"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'PaidIcon',

    emits: ['click'],

    props: {
        transactionType: {
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
.paid-icon {
    cursor: pointer;
    .line {
        fill: #b7becc;
        &.expenses {
            fill: $expense-red;

            & > path {
                fill: $expense-red;
            }
        }

        &.revenues {
            fill: $revenue-green;

            & > path {
                fill: $revenue-green;
            }
        }
    }
}
</style>
