<template>
    <div v-if="showOnboard" class="onboarding" :style="styles">
        <div class="backdrop">
            <div v-if="step === 'start'" class="starting-point">
                <header>
                    <button class="close" type="button" @click.stop="showOnboard = false">
                        <ion-icon name="close"></ion-icon>
                    </button>
                </header>
                <p class="body">
                    Seja bem-vindo ao Painel Web do {{ $variables.brand }}! Vou te mostrar rapidamente como as coisas
                    funcionam por aqui, ok?
                </p>
                <footer class="start-end-footer">
                    <basic-button label="Próxima" :height="45" :width="120" @click="step = '1'" />
                </footer>
            </div>

            <div
                v-for="(s, index) in steps"
                :id="'step-' + s.id"
                :key="index"
                :class="['indicator', 'point-' + s.arrow, { show: step === s.id }]"
            >
                <header>
                    <button class="close" type="button" @click.stop="showOnboard = false">
                        <ion-icon name="close"></ion-icon>
                    </button>
                </header>

                <p>{{ s.text }}</p>

                <footer class="indicator-footer">
                    <div class="dots">
                        <div class="a-dot" :class="{ active: step === '1' }" @click="step = '1'"></div>
                        <div class="a-dot" :class="{ active: step === '2' }" @click="step = '2'"></div>
                        <div class="a-dot" :class="{ active: step === '3' }" @click="step = '3'"></div>
                        <div class="a-dot" :class="{ active: step === '4' }" @click="step = '4'"></div>
                        <div class="a-dot" :class="{ active: step === '5' }" @click="step = '5'"></div>
                        <div class="a-dot" :class="{ active: step === '6' }" @click="step = '6'"></div>
                    </div>

                    <basic-button
                        v-if="step < 6"
                        label="Próxima"
                        :width="120"
                        @click="step = String(Number(s.id) + 1)"
                        :height="45"
                    />

                    <basic-button v-else label="Próxima" :height="45" :width="120" @click="step = 'end'" />
                </footer>
            </div>

            <div v-if="step === 'end'" class="starting-point">
                <p>Espero te ajudar a ser próspero. Até já!</p>
                <footer class="start-end-footer">
                    <basic-button label="Finalizar" :height="45" :width="120" @click="closeOnboard" />
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../store'

import BasicButton from './buttons/BasicButton.vue'

import _request from '@/common/request'

export default {
    components: { BasicButton },

    name: 'Onboarding',

    data() {
        return {
            step: 'start',
            steps: [
                {
                    id: '1',
                    text: 'Aqui nesta janela vou conversar contigo, te dando insights sobre suas finanças. Precisando, você pode minimizá-la.',
                    arrow: 'down',
                },
                {
                    id: '2',
                    text: 'Quando quiser registrar receitas, despesas ou cadastrar uma nova conta (manual ou automática), é aqui que você fará isso.',
                    arrow: 'down',
                },
                {
                    id: '3',
                    text: 'No card de saldo você acompanha seu orçamento, se tudo está correndo como o planejado.',
                    arrow: 'up',
                },
                {
                    id: '4',
                    text: 'Nos cards de movimentação, você vê detalhadamente quanto já executou e quanto falta executar em seu orçamento.',
                    arrow: 'up',
                },
                {
                    id: '5',
                    text: 'Cada um dos cards abaixo te dá uma visão sobre como andam as coisas, desde a evolução do seu orçamento até as contas a pagar amanhã.',
                    arrow: 'down',
                },
                {
                    id: '6',
                    text: 'Acesse as telas contendo suas movimentações, orçamento e formas de pagamento por aqui.',
                    arrow: 'left',
                },
                {
                    id: '7',
                    text: 'E se precisar configurar sua conta ou acessar as opções do orçamento conjunto, vá na tela de configurações.',
                    arrow: 'left',
                },
            ],
            showOnboard: true,
        }
    },

    methods: {
        ...mapActions(mainStore, ['DISABLED']),

        closeOnboard() {
            this.DISABLED()

            _request
                .patch({ collection: 'users', data: { skipTutorial: true }, _id: this.user._id })
                .catch((err) => console.log(err.response.data.message))
                .finally(() => {
                    this.DISABLED()
                    this.showOnboard = false
                })
        },
    },

    computed: {
        ...mapState(mainStore, ['user']),

        styles() {
            return {
                '--chatIcon': `url(${this.$variables.chatIcon})`,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.onboarding {
    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 14px 16px;
        font-size: 22px;
        color: lightgrey;
    }

    p {
        line-height: 1.3;
        margin-bottom: 8px;
    }

    footer button {
        padding: 10px;
        background-color: $action-btn-color;
        border-radius: 4px;
        color: white;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1.5px;
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 1002;
    }

    .starting-point,
    .end-point {
        position: absolute;
        top: 40%;
        left: calc(50% - 180px);
        padding: 40px 24px 24px;
        width: 360px;
        background-color: white;
        border-radius: 8px;
        text-align: center;
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            top: -35px;
            left: -35px;
            width: 70px;
            height: 70px;
            background: white var(--chatIcon) no-repeat;
            background-position-x: center;
            background-position-y: center;
            background-size: 75%;
            border-radius: 50%;
            padding: 12px;
        }
    }

    .start-end-footer {
        @include flex-center();
    }
    .indicator-footer {
        text-align: center;
        @include flex-around();
        align-items: center;

        .dots {
            position: relative;
            top: 4px;
        }
    }

    .indicator {
        position: absolute;
        display: none;
        width: 320px;
        padding: 40px 24px 24px;
        background-color: white;
        border-radius: 8px;
        text-align: center;
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
        z-index: 2;
        color: $dark-blue;

        &:before {
            content: '';
            position: absolute;
            top: -35px;
            left: -35px;
            width: 70px;
            height: 70px;
            background: white var(--chatIcon) no-repeat;
            background-position-x: center;
            background-position-y: center;
            background-size: 75%;
            border-radius: 50%;
            padding: 12px;
            border: 1px solid $btn-blue;
        }

        &.point-down:after {
            content: '';
            position: absolute;
            bottom: -14px;
            left: calc(50% - 10px);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 15px solid white;
        }

        &.point-up:after {
            content: '';
            position: absolute;
            top: -14px;
            left: calc(50% - 10px);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid white;
        }

        &.point-left:after {
            content: '';
            position: absolute;
            top: calc(50% - 10px);
            left: -14px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 15px solid white;
        }

        &.show {
            display: block;
        }

        .a-dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: 0 4px;
            background-color: lightgrey;
            border-radius: 50%;
            cursor: pointer;

            &.active {
                background-color: #028fff94;
                transform: scale(1.5);
            }
        }
    }

    #step-1 {
        /* chat */
        bottom: 500px;
        right: 50px;
    }

    #step-2 {
        /* registrar dados */
        bottom: 150px;
        left: calc(50% - 160px);
    }

    #step-3 {
        /* orçamento */
        top: 170px;
        left: 100px;
    }

    #step-4 {
        /* movimentações */
        top: 170px;
        left: 410px;
    }

    #step-5 {
        /* cards na tela */
        top: 50px;
        left: calc(50% - 150px);
    }

    #step-6 {
        /* menu */
        top: 350px;
        left: 120px;
    }
}
</style>
