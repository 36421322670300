<template>
    <div class="credit-card-summary">
        <div>
            <div>
                <h3 class="title-progress-bar">Usado</h3>

                <v-skeleton-loader style="margin-bottom: 8px" :height="12" :width="100" v-if="waiting" type="text" />
                <span v-else class="valor-box red">{{ formatNum(Math.abs(expensesValue)) }}</span>
            </div>

            <div class="title-progress-bar">
                <h3 class="title-progress-bar">Disponível</h3>

                <v-skeleton-loader style="margin-bottom: 8px" :height="12" :width="100" v-if="waiting" type="text" />
                <span v-else class="valor-box blue">{{ formatNum(Math.abs(creditCard.limit + expensesValue)) }}</span>
            </div>
        </div>

        <v-skeleton-loader v-if="waiting" :height="8" type="text" class="progress" />
        <div v-else class="progress-bar-border">
            <div
                class="progress-bar"
                :style="{ width: percentage(Math.abs(expensesValue), creditCard.limit) + '%' }"
            ></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { mainStore } from '../../store'

import util from '../../common/util'

export default {
    name: 'CreditCardSummary',

    props: {
        creditCard: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState(mainStore, ['waiting', 'getRecurrencyInvoiceSum']),

        expensesValue() {
            const value = this.creditCard.value + this.getRecurrencyInvoiceSum(this.creditCard._id)

            return value > 0 ? 0 : value
        },
    },

    methods: {
        formatNum(number) {
            return util.formatNumber(number)
        },

        percentage(n, d) {
            return util.percentage(n, d)
        },
    },
}
</script>

<style lang="scss">
@import '../../assets/scss/summary-card.scss';

.credit-card-summary {
    margin-top: 10px;

    & > div:first-child {
        display: flex;
        justify-content: space-between;
    }

    .title-progress-bar {
        font-size: 14px !important;
        color: $blueish-gray !important;
    }

    .valor-box {
        font-size: 14px !important;
    }

    .progress-bar-border {
        background-color: $revenue-green !important;

        &.gray {
            background: #e6e6e6 !important;
        }

        .progress-bar {
            background-color: $expense-red !important;

            &.green {
                background: $revenue-green !important;
            }
        }
    }
}
</style>
